/* eslint-disable */

const CameraIcon = ({ color="#FFA647", custom }) => {
    return (
    <svg className={`${custom && custom} custom`} width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0)">
            <path d="M15.8334 13.1667C15.8334 13.5203 15.6929 13.8594 15.4428 14.1095C15.1928 14.3595 14.8536 14.5 14.5 14.5H2.50002C2.1464 14.5 1.80726 14.3595 1.55721 14.1095C1.30716 13.8594 1.16669 13.5203 1.16669 13.1667V5.83333C1.16669 5.47971 1.30716 5.14057 1.55721 4.89052C1.80726 4.64048 2.1464 4.5 2.50002 4.5H5.16669L6.50002 2.5H10.5L11.8334 4.5H14.5C14.8536 4.5 15.1928 4.64048 15.4428 4.89052C15.6929 5.14057 15.8334 5.47971 15.8334 5.83333V13.1667Z" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M8.49998 11.8333C9.97274 11.8333 11.1666 10.6394 11.1666 9.16667C11.1666 7.69391 9.97274 6.5 8.49998 6.5C7.02722 6.5 5.83331 7.69391 5.83331 9.16667C5.83331 10.6394 7.02722 11.8333 8.49998 11.8333Z" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        </g>
        <defs>
            <clipPath id="clip0">
            <rect width="16" height="16" fill="white" transform="translate(0.5 0.5)"/>
            </clipPath>
        </defs>
    </svg>
    )
}

export default CameraIcon;