/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { Col, Row } from 'reactstrap';
import { useHistory } from "react-router-dom";
import HomeIcon from '@/images/homeIcon'
import ArrowUpRightIcon from 'mdi-react/ArrowUpRightIcon'
import ApiServices from "@/utils/ApiServices";
import { Link } from 'react-router-dom';
import { USERLEVELS } from '@/utils/constant'

const ActivePropertyDetails = ({activeProperty, activePropertyYear}) => {
    const userData = JSON.parse(localStorage.getItem('userData'));
    const history = useHistory();
    let year = new Date().getFullYear();
    const property_details = (type) => {
        history.push({
            pathname: `/dashboard-details/${type}`,
        });
    }

    return (
    <>
        <Row>
            <Col md={12} className="mb-4"> 
                <div className="dashboard-card-title">
                    <p><HomeIcon color="#009ADC" custom="controlled" /> Properties</p>
                </div>
            </Col>
            <Col md={6} onClick={() => userData.userlevel === USERLEVELS.ADMIN ? property_details('active-property') : '' } className={userData.userlevel === USERLEVELS.ADMIN ? 'cursor-pointer' : '' } title={ userData.userlevel === USERLEVELS.ADMIN ? 'View Active Properties' : '' }>
                <div className="mobile-app-widget">
                    <div className="mobile-app-widget__title">
                        <h5>{ userData.userlevel === USERLEVELS.ADMIN && 'Active ' }Properties</h5>
                    </div>
                    <div className="mobile-app-widget__top-line mobile-app-widget__top-line--turquoise">
                        <p className="mobile-app-widget__total-stat normal">{activeProperty}</p>
                    </div>
                </div>
            </Col>
            { userData.userlevel === USERLEVELS.ADMIN && <> 
            <Col md={6}  onClick={()=> property_details('new-property')} className="cursor-pointer" title={`View New Properties  in ${year}`} >
                <div className="mobile-app-widget">
                    <div className="mobile-app-widget__title">
                        <h5>New Properties in {year}</h5>
                    </div>
                    <div className="mobile-app-widget__top-line mobile-app-widget__top-line--turquoise">
                        <p className="mobile-app-widget__total-stat success-color">{activePropertyYear} <ArrowUpRightIcon /></p>
                    </div>
                </div>
            </Col>
            </>}
            { userData.userlevel === USERLEVELS.CLIENT && <> 
            <Col md={6} className="text-right">
                <Link className="btn btn-primary" to={`/property/create/${userData.id}/${userData.client_first_name} ${userData.client_last_name}`}>Create Property</Link>
            </Col>
            </>}
        </Row>
    </>
    );
}

export default ActivePropertyDetails;