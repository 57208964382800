/* eslint-disable */
import React, { useState } from "react";
import { useCookies } from 'react-cookie';
import { Field, reduxForm, Form } from "redux-form";
import { connect } from "react-redux";
import EyeIcon from "mdi-react/EyeIcon";
import { NavLink, useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import { Alert, Button, Spinner } from "reactstrap";
import renderCheckBoxField from "../form/CheckBox";
import ApiServices from "@/utils/ApiServices"; 
import { COOKIEMAXAGE, USERLEVELS } from "@/utils/constant";
import { successMsg, errorMsgCustom } from "@/utils/message_herlper"

const LogInForm = ({
    handleSubmit,
    typeFieldUser,
    form,
}) => {
    const [showPassword, setShowPassword] = useState(false);
    const [isSubmit, setSubmit] = useState(false)
    const [cookies, setCookie] = useCookies(['name']);
    const history = useHistory();
    const [state, setState] = useState({
        username: "",
        password: "",
    });
    const [error, setError] = useState(false);
    const handleChange = (e) => {
        const { name, value } = e.target;

        setState((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    const signInProcess = (e) => {  
        e.preventDefault();
        if (state.username === "" || state.password === "") {
            errorMsgCustom("Please input email and password.")
        } else {
            setError(false);
            setSubmit(true)
            let params = {
                email: state.username,
                password: state.password,
            };
            ApiServices.login(params)
            .then((result) => {
                setTimeout(() =>{
                    setSubmit(false)
                    let data = result.data;
                    //&& data.user.userlevel !== USERLEVELS.ADMIN 
                    if (data.user.userlevel !== USERLEVELS.ADMIN && data.user.userlevel !== USERLEVELS.STAFF) {
                        // successMsg('LOGIN_SUCCESS');
                        localStorage.setItem('userData', JSON.stringify(data.user));
                        setCookie('userDetails', JSON.stringify(data?.user), COOKIEMAXAGE);
                        setCookie('userID', data?.user?.id, COOKIEMAXAGE);
                        setCookie('token', data.access_token, COOKIEMAXAGE);
                        localStorage.setItem("loggedin_id", data.user.id);
                        
                        localStorage.setItem(
                            "loggedin_token",
                            data.access_token
                        );
                        localStorage.setItem(
                            "first_name",
                            data.user.client_first_name
                        );
                        localStorage.setItem(
                            "last_name",
                            data.user.client_last_name
                        );
                        setError(false);
                        successMsg('LOGIN_SUCCESS');
                        window.location = '/dashboard'
                    }else{
                        setSubmit(false)
                        // setError(true);
                        errorMsgCustom("Your credentials are incorrect or your account is inactive.")
                    }
                    
                   
                },1500)
                
            })
            .catch(function (error) {
                setTimeout(() =>{
                    setSubmit(false)
                    // setError(true);
                    errorMsgCustom("Your credentials are incorrect or your account is inactive.")
                },1000)
            });
        }
    };

    const showPasswordToggle = () => {
        setShowPassword(!showPassword);
    };
    return (
        <Form className="form login-form" onSubmit={handleSubmit}>
            {error && <Alert color="danger" isOpen={true}> 
                    Your credentials are incorrect or your account is inactive..
                </Alert>}

            <div className="form__form-group">
                <span className="form__form-group-label">Email</span>
                <div className="form__form-group-field">
                    {/* <div className="form__form-group-icon">
                        <AccountOutlineIcon />
                    </div> */}
                    <Field
                        name="username"
                        component="input"
                        type={typeFieldUser}
                        placeholder="Email"
                        className="input-without-border-radius"
                        value={state.username}
                        onChange={handleChange}
                    />
                </div>
            </div>
            <div className="form__form-group">
                <span className="form__form-group-label">Password</span>
                <div className="form__form-group-field">
                    {/* <div className="form__form-group-icon">
                        <KeyVariantIcon />
                    </div> */}
                    <Field
                        name="password"
                        component="input"
                        type={showPassword ? "text" : "password"}
                        placeholder="Password"
                        className="input-without-border-radius"
                        value={state.password}
                        onChange={handleChange}
                    />
                    <button
                        type="button"
                        className={`form__form-group-button${
                            showPassword ? " active" : ""
                        }`}
                        onClick={showPasswordToggle}
                    >
                        <EyeIcon />
                    </button>
                    <div className="account__forgot-password">
                        <NavLink to="/reset_password">
                            Forgot a password?
                        </NavLink>
                    </div>
                </div>
            </div>
            <div className="form__form-group">
                <span className="form__form-group-label remember-me-label"></span>
                <div className="form__form-group form__form-group-field">
                    <Field
                        name={`remember_me-${form}`}
                        component={renderCheckBoxField}
                        label="Remember me"
                    />
                </div>
            </div>
            <div className="form__form-group">
                <span className="form__form-group-label"></span>
                <div className="form__form-group form__form-group-field">
                    <div className="account__btns">
                        <Button
                            className="account__btn mb-0 button-with-spinner"
                            type="submit"
                            color="primary"
                            onClick={signInProcess}
                            disabled={isSubmit}
                        >
                            {isSubmit ? (
                                <Spinner size="sm" color="secondary" className="mr-2" />
                            ) : (
                                ""
                            )}
                            <span>Login</span>
                        </Button>
                        <NavLink
                            className="btn btn-secondary account__btn create-account-btn  mb-0"
                            to="/register"
                        >
                            Create Account
                        </NavLink>
                    </div>
                </div>
            </div>
        </Form>
    );
};

LogInForm.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    errorMessage: PropTypes.string,
    errorMsg: PropTypes.string,
    fieldUser: PropTypes.string,
    typeFieldUser: PropTypes.string,
    form: PropTypes.string.isRequired,
};

LogInForm.defaultProps = {
    errorMessage: "",
    errorMsg: "",
    fieldUser: "Username",
    typeFieldUser: "text",
};

export default connect((state) => ({
    errorMsg: state.user.error,
}))(reduxForm()(LogInForm));
