/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { 
    Card, CardBody, 
  } from 'reactstrap';
  import Form from 'react-bootstrap/Form'
import PaperIcon from '@/images/paperIcon'
import TrashIcon from '@/images/trashIcon'
import ToggleSwitch from './ToggleSwitch'

const DocumentCardView = ({ index, docuType, id, file_type, name, status, onDelete , onStatus, is_view, agentFile}) => { 
    const userData = JSON.parse(localStorage.getItem('userData'));
    const onChangeStatus = (status) => {
        onStatus({id: id, status: status ? 1 : 0, type: parseInt(docuType) })
    }
    return(
       
        <>
           <div className="general-files-list" onClick={() => is_view ? agentFile() : () => false }  style={{ cursor: is_view? 'pointer' : 'default'  }} >
                <div>
                    <span className="filetype">{file_type}</span>
                    <span className="filename">{name}</span>
                    <div className="fileicon">
                       <PaperIcon color="#98a6ad" />
                    </div>
                </div>
            </div>
        </>
    )
}

export default DocumentCardView;