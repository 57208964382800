/* eslint-disable */
import React from "react";
import PropTypes from "prop-types";
import { Field, reduxForm, Form } from "redux-form";
import AlternateEmailIcon from "mdi-react/AlternateEmailIcon";
import { Button, Spinner } from "reactstrap";
import "./style.css";

const ResetPasswordForm = ({ handleSubmit, fieldUser, isSubmit }) => (

    
    <Form className="form reset-password-form" onSubmit={handleSubmit}>
        <div className="form__form-group">
            <div>
                <span className="form__form-group-label">{fieldUser}</span>
            </div>
            <div className="form__form-group-field">
                <div className="form__form-group-icon">
                    <AlternateEmailIcon />
                </div>
                <Field
                    name="email"
                    id="email"
                    component="input"
                    type="email"
                    placeholder="example@mail.com"
                    className="input-without-border-radius"
                />
            </div>
        </div>
        <Button className="account__btn" color="primary" disabled={isSubmit}>
           {isSubmit ? <Spinner size="sm" color="secondary" className="mr-2" /> : ''}
            Reset Password
        </Button>
    </Form>
);

ResetPasswordForm.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    fieldUser: PropTypes.shape(),
};

ResetPasswordForm.defaultProps = {
    fieldUser: null,
};

export default reduxForm()(ResetPasswordForm);
