/* eslint-disable */
import React, { useState, useEffect } from "react";
import {
    Card,
    CardBody,
    Col,
    Container,
    Row,
    Spinner,
    Button,
} from "reactstrap";
import _ from "lodash";
import DocumentCard from "@/components/DocumentCard";
import DocumentCardView from "@/components/DocumentCardView";
import { toast } from "react-toastify";
import { TOAST_SETTINGS, API, HOME_API, TIMEZONE } from "@/utils/constant";
import ModalUpload from "@/components/ModalDocuUpload";
import moment from "moment";
import "moment-timezone";
import Empty from "@/components/Empty";
import { USERLEVELS } from "@/utils/constant";
const userData = JSON.parse(localStorage.getItem("userData"));

let REQUIRED_FILE = "Please select a file to upload.";
let MAX_FILE = "The file you are trying to upload is too large (max 5MB)";

const PropertyDocument = ({
    id,
    onSubmitDocument,
    generalDocoments,
    resultDocoments,
    onDelete,
    onStatus,
    submit1,
    submit2,
    submitDocument,
    aoaReady,
    multipleUploads,
}) => {
    const [show, setShow] = useState(false);
    const [type, setType] = useState(1);
    const [gfile, setGfile] = useState(null);
    const [gfile2, setGfile2] = useState(null);

    const handleClose = () => {
        setShow(false);
    };
    /*Maximum allowed size in bytes
    5MB Example
    Change first operand(multiplier) for your needs*/
    const maxAllowedSize = 5 * 1024 * 1024;

    const handleChange = (file) => {
        if (file[0].size > maxAllowedSize) {
            document.getElementById("generalFile").value = null;
            toast.error(MAX_FILE, {
                TOAST_SETTINGS,
            });
            return;
        } else {
            // setGfile(file[0])
            uploadGeneral(file[0]);
        }
    };

    const handleChange2 = (file) => {
        if (file[0].size > maxAllowedSize) {
            document.getElementById("generalFile2").value = null;
            toast.error(MAX_FILE, {
                TOAST_SETTINGS,
            });
            return;
        } else {
            // setGfile2(file[0])
            uploadGeneral2(file[0]);
        }
    };

    const uploadGeneral = (image) => {
        if (!image) {
            toast.error(REQUIRED_FILE, {
                TOAST_SETTINGS,
            });
            return;
        } else {
            onSubmitDocument({ type: 1, file: image });
            document.getElementById("generalFile").value = null;
            // setTimeout(()=>{
            //     setGfile(null)
            // },500)
        }
    };

    const uploadGeneral2 = (image) => {
        if (!image) {
            toast.error(REQUIRED_FILE, {
                TOAST_SETTINGS,
            });
            return;
        } else {
            onSubmitDocument({ type: 2, file: image });
            document.getElementById("generalFile2").value = null;
            // setTimeout(()=>{
            //     setGfile2(null)
            // },500)
        }
    };

    const agentFile = () => {
        window.open(
            `${API}property/removal-of-agent/${btoa(id)}/${btoa(
                moment()
                    .tz(TIMEZONE)
                    .add(1, "hours")
                    .format("YYYY-MM-DD HH:mm:ss")
            )}`,
            "_blank"
        );
    };

    const bindingArbitrationFile = () => {
        window.open(
            `${API}property/binding-arbitration/${btoa(id)}/${btoa(
                moment()
                    .tz(TIMEZONE)
                    .add(1, "hours")
                    .format("YYYY-MM-DD HH:mm:ss")
            )}`,
            "_blank"
        );
    };

    const propertyAOA = () => {
        window.open(
            `${API}property/property-aoa/${btoa(id)}/${btoa(
                moment()
                    .tz(TIMEZONE)
                    .add(1, "hours")
                    .format("YYYY-MM-DD HH:mm:ss")
            )}/1`,
            "_blank"
        );
    };

    const propertyAOA2 = () => {
        window.open(
            `${API}property/property-aoa/${btoa(id)}/${btoa(
                moment()
                    .tz(TIMEZONE)
                    .add(1, "hours")
                    .format("YYYY-MM-DD HH:mm:ss")
            )}/2`,
            "_blank"
        );
    };

    const viewFile = (files) => {
        window.open(files?.file);
        // var a = document.createElement("a"); //Create <a>
        // a.href = "data:image/png;base64," + files.file; //Image Base64 Goes here
        // a.download = files.name; //File name Here
        // a.click();
    };

    const viewFileMultiple = (file) =>  {

        window.open(
            `${HOME_API}uploaded-files/${file}`,
            "_blank"
        );

    }

    return (
        <>
            <ModalUpload
                open={show}
                close={handleClose}
                type={type}
                submitDocment={onSubmitDocument}
                is_submitDocument={submitDocument}
            />
            <p className="tab-content-title pt-4 mb-3">Documents</p>
            <Container>
                <Row className="controlled-row">
                    {userData.userlevel !== USERLEVELS.CLIENT && (
                        <Col md={6} className="property-account-files">
                            <h3>Property Account Files</h3>
                            <DocumentCardView
                                name="Removal of Agent"
                                file_type="pdf"
                                is_view={true}
                                agentFile={() => agentFile()}
                            />
                            <DocumentCardView
                                name="Binding Arbitration AoA"
                                file_type="pdf"
                                is_view={true}
                                agentFile={() => bindingArbitrationFile()}
                            />
                            {aoaReady && (
                                <>
                                    <DocumentCardView
                                        name="Property AoA"
                                        file_type="pdf"
                                        is_view={true}
                                        agentFile={() => propertyAOA()}
                                    />
                                    <DocumentCardView
                                        name="Manager AoA"
                                        file_type="pdf"
                                        is_view={true}
                                        agentFile={() => propertyAOA2()}
                                    />
                                </>
                            )}
                            <h3 className="mt-4">Multi-upload Documents</h3>
                            {multipleUploads.map((obj, i) => {
                                return (
                                    <React.Fragment key={i}>
                                        <DocumentCardView
                                            name={obj?.filename}
                                            file_type={obj?.filetype}
                                            is_view={true}
                                            agentFile={() => viewFileMultiple(obj?.filelink)}
                                        />
                                    </React.Fragment>
                                );
                            })}
                        </Col>
                    )}
                    <Col md={6} className="general-results-files">
                        <div className="mb-3">
                            <p
                                style={{
                                    border: "1px solid #FFA647",
                                    color: "#000",
                                    padding: "8px 10px",
                                    marginBottom: "10px",
                                }}
                            >
                                <b>IMPORTANT:</b> Please do not upload any
                                hearing notices you receive from the county on
                                RPT email them to info@republicpropertytax.com.
                            </p>
                            <div className="heading-fileuploader">
                                <h3>General Files</h3>
                                <div className="general-file-uploader text-right">
                                    <Button
                                        color="primary"
                                        onClick={() => {
                                            setShow(true);
                                            setType(1);
                                        }}
                                        className="primary-2 mb-0"
                                    >
                                        Choose files for upload
                                    </Button>
                                    {/* <button onClick={()=> uploadGeneral()}>{submit1 ? <Spinner size="sm" color="secondary" className="mr-2" /> : ''}Upload File</button> */}
                                </div>
                            </div>
                            {generalDocoments.map((obj, i) => {
                                return (
                                    <React.Fragment key={i}>
                                        {" "}
                                        <DocumentCard
                                            index={i}
                                            docuType="1"
                                            id={obj?.id}
                                            status={obj?.status}
                                            name={obj?.name}
                                            file_type={obj?.file_type}
                                            onDelete={(id) =>
                                                onDelete({ id: id, type: 1 })
                                            }
                                            onStatus={(data) => onStatus(data)}
                                            agentFile={(file) => viewFile(file)}
                                            is_view={true}
                                            file={obj?.file}
                                            created_at={moment
                                                .utc(obj?.created_at)
                                                .local()
                                                .format("MMM DD YYYY, hh:mm A")}
                                        />
                                    </React.Fragment>
                                );
                            })}
                            {_.isArray(generalDocoments) &&
                            generalDocoments.length < 1 ? (
                                <Empty />
                            ) : (
                                ""
                            )}
                        </div>
                        <div className="pt-3">
                            <div className="heading-fileuploader">
                                <h3>Result Files</h3>
                                <div className="general-file-uploader text-right">
                                    {userData.userlevel !==
                                        USERLEVELS.CLIENT && (
                                        <Button
                                            onClick={() => {
                                                setShow(true);
                                                setType(2);
                                            }}
                                            color="primary"
                                            className="primary-2 mb-0"
                                        >
                                            Choose files for upload
                                        </Button>
                                    )}
                                    {/* <button onClick={()=> uploadGeneral2()}>{submit2 ? <Spinner size="sm" color="secondary" className="mr-2" /> : ''}Upload File</button> */}
                                </div>
                            </div>
                            {resultDocoments.map((obj, i) => {
                                return (
                                    <React.Fragment key={i}>
                                        {" "}
                                        <DocumentCard
                                            // is_view={false}
                                            index={i}
                                            docuType="1"
                                            id={obj?.id}
                                            status={obj?.status}
                                            name={obj?.name}
                                            file_type={obj?.file_type}
                                            onDelete={(id) =>
                                                onDelete({ id: id, type: 2 })
                                            }
                                            onStatus={(data) => onStatus(data)}
                                            agentFile={(file) => viewFile(file)}
                                            is_view={true}
                                            file={obj?.file}
                                            created_at={moment
                                                .utc(obj?.created_at)
                                                .local()
                                                .format("MMM DD YYYY, hh:mm A")}
                                        />
                                    </React.Fragment>
                                );
                            })}
                            {_.isArray(resultDocoments) &&
                            resultDocoments.length < 1 ? (
                                <Empty />
                            ) : (
                                ""
                            )}
                        </div>
                    </Col>
                </Row>
            </Container>
        </>
    );
};

export default PropertyDocument;
