/* eslint-disable */
import React, { useEffect, useMemo, useState, useRef } from "react";
import queryString from "query-string";
import {
    Card,
    CardBody,
    Col,
    Container,
    Row,
    Button,
    Spinner,
} from "reactstrap";
import moment from "moment";
import { Link, useHistory, useLocation } from "react-router-dom";
import DataTable from "react-data-table-component";
import TitleBreadCrumbs from "@/components/TitleBreadCrumbs";
import TableLoading from "@/components/TableLoading";
import ApiServices from "@/utils/ApiServices";
import { TABLE_SETTINGS, SELECT_STYLES } from "@/utils/constant";
import { globalError } from "@/utils/message_herlper";
import Empty from "@/components/Empty";
import Select from "react-select";

const CommissionReportDetails = (props) => {
    const history = useHistory();
    const location = useLocation();
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(TABLE_SETTINGS.PAGINATION_PER_PAGE);
    const [currentPage, setCurrentPage] = useState(1);
    const [county, setCounty] = useState([]);
    const [totalAmount, setTotal] = useState(0);
    const [totalPercent, setPercent] = useState(0);

    const yearRef = useRef(null);
    const nameRef = useRef(null);
    const agentRef = useRef(null);
    const propertyRef = useRef(null);
    const countyRef = useRef(null);
    const billingRef = useRef(null);
    const exemptionRef = useRef(null);

    const dataTable = [];
    const list = data;

    useEffect(() => {
        getCounty();
        let { key } = props?.match?.params;
        if (key) {
            let url_params = atob(key);
            let { agent_name, agent_id, year, property, billing, exemption } =
                queryString.parse(url_params);
            nameRef.current = agent_name;
            agentRef.current = agent_id;
            yearRef.current = year;
            propertyRef.current = property;
            billingRef.current = billing;
            exemptionRef.current = exemption;
            fetchData(1);
        }
    }, [location]);

    const fetchData = async (
        page,
        size = perPage,
        year = yearRef.current,
        property = propertyRef.current,
        billing = billingRef.current,
        exemption = exemptionRef.current,
        agent_id = agentRef.current,
        county_id = countyRef.current
    ) => {
        setData([]);
        ApiServices.CommissionInvoiceProperties({
            page: page,
            per_page: size,
            year: year,
            property: property,
            billing: billing,
            exemption: exemption,
            agent_id: agent_id,
            county_id: county_id,
        })
            .then((result) => {
                let details = result?.data;
                let amount = details?.total_query[0]?.total_reduction || 0;
                let total_noticed = details?.total_query[0]?.total_noticed || 0;
                let total_settled = details?.total_query[0]?.total_settled || 0;
                let percentageReduction =
                    ((total_noticed - total_settled) / total_noticed) * 100;
                setTotal(
                    `$${amount?.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,")}` || '0.00'
                );
                setPercent(parseInt(percentageReduction.toFixed(2), 10) || 0);
                setData(result.data.data);
                setTotalRows(result.data.total);
                setLoading(false);
            })
            .catch((error) => {
                globalError();
            });
    };

    const getCounty = () => {
        ApiServices.county()
            .then((result) => {
                let results = result?.data;
                let ConutyList = [];
                for (let index = 0; index < results.length; index++) {
                    ConutyList.push({
                        value: results[index].id,
                        label: results[index].name,
                    });
                }
                setCounty(ConutyList);
            })
            .catch(function (error) {});
    };

    const handlePageChange = (page) => {
        fetchData(page);
        setCurrentPage(page);
    };

    const handlePerRowsChange = async (newPerPage, page) => {
        fetchData(page, newPerPage);
        setPerPage(newPerPage);
    };

    const columns = useMemo(() => [
        {
            name: "Owner's Name",
            selector: "owner_name",
            sortable: false,
        },
        {
            name: "Address",
            selector: "property_address",
            sortable: false,
        },
        {
            name: "City",
            selector: "city",
            sortable: false,
        },
        {
            name: "State",
            selector: "state",
            sortable: false,
        },
        {
            name: "Zip",
            selector: "zip",
            sortable: false,
        },
        {
            name: "County",
            selector: "county",
            sortable: false,
        },
        {
            name: "Account No.",
            selector: "account_number",
            sortable: false,
        },
        {
            name: "Reduced Amount",
            selector: "reduction",
            sortable: false,
        },
    ]);

    if (Array.isArray(list)) {
        for (let index = 0; index < list.length; index++) {
            dataTable.push({
                owner_name: list[index].owner_name,
                property_address: list[index].property_address,
                city: list[index].property_city,
                state: list[index].property_state,
                zip: list[index].property_zip,
                county: list[index].county_name,
                account_number: list[index].account_number,
                reduction: `$${list[index].reduction
                    ?.toFixed(2)
                    .replace(/\d(?=(\d{3})+\.)/g, "$&,")}`,
            });
        }
    }

    const goBack = () => {
        history.push({ pathname: `/reports/not-invoiced` });
    };

    const setCountySelected = (countySelected) => {
        countyRef.current = countySelected?.value || null;
        fetchData(1);
    };

    const goback = () => {
        history.goBack();
    };

    return (
        <Container>
            <TitleBreadCrumbs title="Commission Properties">
                <div className="page-breadcrumbs">
                    <Link to="/dashboard">Dashboard</Link>
                    <Link to="#">Reports</Link>
                    <Link to="/reports/commission-report">
                        Commission Properties
                    </Link>
                    <span className="current_breadcrumbs">
                        {" "}
                        Commission Report Properties
                    </span>
                </div>
            </TitleBreadCrumbs>
            <Row>
                <Col md={12}>
                    <a href="javascript:void(0)" onClick={goback}>
                        Go Back
                    </a>
                    <Card>
                        <CardBody>
                            <Row>
                                <Col xs="12" md="8">
                                    {/* Content for the first column */}
                                    <h6>
                                        Agent:{" "}
                                        <strong>{nameRef.current}</strong>
                                    </h6>
                                    <h6 className="mt-1">
                                        Properties # Reduced:{" "}
                                        <strong>{totalAmount}</strong>
                                    </h6>
                                    <h6 className="mt-1">
                                        Reduction %:{" "}
                                        <strong>{totalPercent}</strong>
                                    </h6>
                                </Col>
                                <Col xs="12" md="4">
                                    <h6>Counties: </h6>
                                    <Select
                                        options={county}
                                        isMulti={false}
                                        styles={SELECT_STYLES}
                                        onChange={(value) =>
                                            setCountySelected(value)
                                        }
                                        style={{ width: 200 }}
                                        isClearable={true}
                                    />
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
                <Col md={12}>
                    <Card>
                        <CardBody>
                            <Row>
                                <Col md={12} className="edit-county-wrap">
                                    <DataTable
                                        columns={columns}
                                        data={dataTable}
                                        progressPending={loading}
                                        progressComponent={<TableLoading />}
                                        pagination
                                        paginationServer
                                        paginationTotalRows={totalRows}
                                        paginationDefaultPage={currentPage}
                                        striped={TABLE_SETTINGS.STRIPED}
                                        highlightOnHover={true}
                                        onChangeRowsPerPage={
                                            handlePerRowsChange
                                        }
                                        onChangePage={handlePageChange}
                                        paginationPerPage={
                                            TABLE_SETTINGS.PAGINATION_PER_PAGE
                                        }
                                        paginationRowsPerPageOptions={
                                            TABLE_SETTINGS.ROW_PER_PAGE
                                        }
                                        noDataComponent={<Empty />}
                                    />
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
};

export default CommissionReportDetails;
