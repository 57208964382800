/* eslint-disable */
import React, { useEffect, useState, useRef, useMemo } from "react";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import queryString from "query-string";
import { reduxForm } from "redux-form";
import { Link, useHistory, useLocation } from "react-router-dom";
import { Button } from "reactstrap";
import Select from "react-select";
import TitleBreadCrumbs from "@/components/TitleBreadCrumbs";
import ApiServices from "@/utils/ApiServices";
import { TABLE_SETTINGS, SELECT_STYLES, API } from "@/utils/constant";
import DataTable from "react-data-table-component";
import TableLoading from "@/components/TableLoading";
import Empty from "@/components/Empty";
import { globalError } from "@/utils/message_herlper";

const customStyles = {
    control: (base, state) => ({
        ...base,
        color: state.isSelected ? "red" : "#009adc",
        boxShadow: "none",
        "&:hover": {
            border: "1px solid #009adc",
        },
    }),
    option: (styles, state) => ({
        ...styles,
        color: state.isSelected ? "#009adc" : styles.color,
        backgroundColor: state.isSelected ? "#009adc" : styles.color,
        borderBottom: "1px solid rgba(0, 0, 0, 0.125)",
        "&:hover": {
            color: "#FFF",
            backgroundColor: "#009adc",
        },
    }),
};

const CommissionReport = (props) => {
    const history = useHistory();
    const location = useLocation();
    const currentYear = new Date().getFullYear();
    const range = (start, stop, step) =>
        Array.from(
            { length: (stop - start) / step + 1 },
            (_, i) => start + i * step
        );
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [totalRows, setTotalRows] = useState(0);
    const [show, setShow] = useState(false);
    const [perPage, setPerPage] = useState(TABLE_SETTINGS.PAGINATION_PER_PAGE);
    const [currentPage, setCurrentPage] = useState(1);
    const [years, setYears] = useState(
        range(currentYear, currentYear - 8, -1)?.reverse()
    );
    const [resultList, setResultList] = useState([]);
    const [agentList, setAgentList] = useState([]);
    const [propertyType, sePropertyType] = useState([]);
    const [exemptionSeledted, setExemptionSeledted] = useState([]);
    const [yearSeledted, setYearSeledted] = useState([]);
    const [porpertySeledted, setPropertySeledted] = useState([]);
    const [billingSeledted, setBillingSeledted] = useState([]);
    const [resultSeledted, setRsultSeledted] = useState([]);
    const [exemptions, setExemptions] = useState([]);
    const yearRef = useRef(null);
    const propertyRef = useRef(null);
    const billingRef = useRef(null);
    const resultRef = useRef(null);
    const keyRef = useRef(null);
    const exemptionRef = useRef(null);
    const dataTable = [];
    const list = data;
    const [selectedOptionsTY, setSelectedOptionsTY] = useState([]);
    const [selectedOptionsPT, setSelectedOptionsPT] = useState([]);
    const [selectedOptionsET, setSelectedOptionsET] = useState([]);
    const [selectedOptionsBT, setSelectedOptionsBT] = useState([]);
    const [selectedOptionsAL, setSelectedOptionsAL] = useState([]);
    const userData = JSON.parse(localStorage.getItem("userData"));
    useEffect(() => {
        resultsList();
        getagentList();
        getPropertyTypes();
        getExemptionsTypes();
    }, [props]);

    useEffect(() => {
        let { key } = props?.match?.params;
        if (key) {
            let url_params = atob(key);
            let { year, property, billing, result, exemption } =
                queryString.parse(url_params);
            yearRef.current = year;
            propertyRef.current = property;
            billingRef.current = billing;
            // resultRef.current = result
            exemptionRef.current = exemption;
            keyRef.current = url_params;
            setShow(true);
            fetchData();
        } else {
            setShow(false);
        }
    }, [location]);

    const getExemptionsTypes = () => {
        ApiServices.ExemptionTypes()
            .then((result) => {
                let results = result?.data;
                let ExemptionTypeList = [];
                ExemptionTypeList.push({ value: "all", label: "Select All" });
                ExemptionTypeList.push({ value: "none", label: "Select None" });
                for (let index = 0; index < results.length; index++) {
                    ExemptionTypeList.push({
                        value: results[index].id,
                        label: results[index].title,
                    });
                }
                setExemptions(ExemptionTypeList);
            })
            .catch(function (error) {});
    };

    const fetchData = async (
        year = yearRef.current,
        property = propertyRef.current,
        billing = billingRef.current,
        exemption = exemptionRef.current
    ) => {
        setData([]);
        ApiServices.commissionReport({
            year: year,
            property: property,
            billing: billing,
            // result: result,
            exemption: exemption,
        })
            .then((result) => {
                setData(result.data);
                setLoading(false);
            })
            .catch((error) => {
                globalError();
            });
    };

    const resultsList = () => {
        ApiServices.resultsList().then((result) => {
            let results = result?.data;
            let ResultAgentList = [];
            ResultAgentList.push({ value: "all", label: "Select All" });
            ResultAgentList.push({ value: "none", label: "Select None" });
            for (let index = 0; index < results.length; index++) {
                ResultAgentList.push({
                    value: results[index].id,
                    label: results[index].name,
                });
            }
            setResultList(ResultAgentList);
        });
    };

    const getPropertyTypes = () => {
        ApiServices.PropertyTypes().then((result) => {
            let results = result?.data;
            let PropertyTypeList = [];
            PropertyTypeList.push({ value: "all", label: "Select All" });
            PropertyTypeList.push({ value: "none", label: "Select None" });
            for (let index = 0; index < results.length; index++) {
                PropertyTypeList.push({
                    value: results[index].id,
                    label: results[index].type,
                });
            }
            sePropertyType(PropertyTypeList);
        });
    };

    const getagentList = () => {
        ApiServices.agentList().then((result) => {
            let results = result?.data;
            let BillingTypeList = [];
            BillingTypeList.push({ value: "all", label: "Select All" });
            BillingTypeList.push({ value: "none", label: "Select None" });
            for (let index = 0; index < results.length; index++) {
                BillingTypeList.push({
                    value: results[index].id,
                    label: results[index].name,
                });
            }
            setAgentList(BillingTypeList);
        });
    };

    const handleInputChange = (e) => {
        updateFormData({
            ...formData,
            [e.target.name]: e.target.value.trim(),
        });
    };

    const handleFormSubmit = (e) => {
        e.preventDefault();
        // let yearResult =
        //     Array.isArray(yearSeledted) &&
        //     yearSeledted.map(({ value }) => value);
        // let propertyResult =
        //     Array.isArray(porpertySeledted) &&
        //     porpertySeledted.map(({ value }) => value);
        // let params = btoa(
        //     `?year=${JSON.stringify(
        //         yearResult || []
        //     )}&property=${JSON.stringify(propertyResult || [])}`
        // );
        let yearResult =
            Array.isArray(yearSeledted) &&
            yearSeledted.map(({ value }) => value);
        let propertyResult =
            Array.isArray(porpertySeledted) &&
            porpertySeledted.map(({ value }) => value);
        let billingResult =
            Array.isArray(billingSeledted) &&
            billingSeledted.map(({ value }) => value);
        let resultResult =
            Array.isArray(resultSeledted) &&
            resultSeledted.map(({ value }) => value);
        let exemptionResult =
            Array.isArray(exemptionSeledted) &&
            exemptionSeledted.map(({ value }) => value);

        let params = btoa(
            `?year=${JSON.stringify(
                yearResult || []
            )}&property=${JSON.stringify(
                propertyResult || []
            )}&billing=${JSON.stringify(
                billingResult || []
            )}&result=${JSON.stringify(
                resultResult || []
            )}&exemption=${JSON.stringify(exemptionResult || [])}`
        );
        history.push({ pathname: `/reports/commission-report/${params}` });
    };

    const handlView = (id, name) => {
        let params = btoa(
            `?agent_name=${name}&agent_id=${id}&year=${yearRef.current}&property=${propertyRef.current}&billing=${billingRef.current?.toString()}&exemption=${exemptionRef.current?.toString()}`
        );
        history.push({ pathname: `/commission-report/details/${params}` });
    };

    const goBack = () => {
        setYearSeledted([]);
        setPropertySeledted([]);
        setBillingSeledted([]);
        setRsultSeledted([]);
        history.push({ pathname: `/reports/commission-report` });
    };

    const exportReport = (type) => {
        let { key } = props?.match?.params;
        window.open(`${API}report/commission-export/${key}/${type}`, "_blank");
    };

    let TaxYearList = [];
    TaxYearList.push({ value: "all", label: "Select All" });
    TaxYearList.push({ value: "none", label: "Select None" });
    for (let index = 0; index < years.length; index++) {
        TaxYearList.push({ value: years[index], label: years[index] });
    }

    const columns = useMemo(() => [
        {
            name: "County Name ",
            selector: "county_name",
            sortable: false,
            center: true,
        },
        {
            name: "County Reduction	",
            selector: "total_reduction",
            sortable: false,
            right: true,
        },
        {
            name: "County Saving",
            selector: "total_savings",
            sortable: false,
            right: true,
        },
        {
            name: "County Invoice",
            selector: "total_invoice",
            sortable: false,
            right: true,
        },
        {
            name: "Details",
            center: true,
            cell: (row) => (
                <Button
                    className="my-0 primary-2 white-nowrap"
                    color="primary"
                    onClick={() => handlView(row)}
                >
                    View Properties
                </Button>
            ),
        },
    ]);

    if (Array.isArray(list)) {
        list.sort((a, b) => a.name.localeCompare(b.name));
        for (let index = 0; index < list.length; index++) {
            if (list[index]?.details) {
                dataTable.push({
                    agent_id: list[index].id,
                    agent:
                        list[index].name.replace(/\s+/g, "") != ""
                            ? list[index].name
                            : list[index].email,
                    percentageReduction: parseInt(
                        list[index]?.details?.percentageReduction.toFixed(2),
                        10
                    ),
                    total_reduction: `$${list[index]?.details?.total_reduction
                        ?.toFixed(2)
                        .replace(/\d(?=(\d{3})+\.)/g, "$&,")}`,
                });
            } else {
                dataTable.push({
                    agent_id: list[index].id,
                    agent:
                        list[index].name.replace(/\s+/g, "") != ""
                            ? list[index].name
                            : list[index].email,
                    percentageReduction: 0,
                    total_reduction: `$${0
                        ?.toFixed(2)
                        .replace(/\d(?=(\d{3})+\.)/g, "$&,")}`,
                });
            }
        }
    }

    const setYearSeledtedAction = (value) => {
        const selectedValue =
            Array.isArray(selectedOptionsTY) &&
            selectedOptionsTY.length != 0 &&
            value
                ? value[value.length - 1]
                : value
                ? value[0]
                : [];
        let newDAta = [];
        if (selectedValue?.value == "none") {
            newDAta = [];
        } else if (selectedValue?.value == "all") {
            newDAta = TaxYearList.slice(2);
        } else {
            newDAta = value;
        }
        setSelectedOptionsTY(newDAta);
        setYearSeledted(newDAta);
    };

    const setPropertySeledtedAction = (value) => {
        const selectedValue =
            Array.isArray(selectedOptionsPT) &&
            selectedOptionsPT.length != 0 &&
            value
                ? value[value.length - 1]
                : value
                ? value[0]
                : [];
        let newDAta = [];
        if (selectedValue?.value == "none") {
            newDAta = [];
        } else if (selectedValue?.value == "all") {
            newDAta = propertyType.slice(2);
        } else {
            newDAta = value;
        }
        setSelectedOptionsPT(newDAta);
        setPropertySeledted(newDAta);
    };

    const setExemptionSeledtedAction = (value) => {
        const selectedValue =
            Array.isArray(selectedOptionsET) &&
            selectedOptionsET.length != 0 &&
            value
                ? value[value.length - 1]
                : value
                ? value[0]
                : [];
        let newDAta = [];

        if (selectedValue?.value == "none") {
            newDAta = [];
        } else if (selectedValue?.value == "all") {
            newDAta = exemptions.slice(2);
        } else {
            newDAta = value;
        }
        setSelectedOptionsET(newDAta);
        setExemptionSeledted(newDAta);
    };

    const setBillingSeledtedAction = (value) => {
        const selectedValue =
            Array.isArray(selectedOptionsBT) &&
            selectedOptionsBT.length != 0 &&
            value
                ? value[value.length - 1]
                : value
                ? value[0]
                : [];
        let newDAta = [];
        if (selectedValue?.value == "none") {
            newDAta = [];
        } else if (selectedValue?.value == "all") {
            newDAta = resultList.slice(2);
        } else {
            newDAta = value;
        }
        setSelectedOptionsBT(newDAta);
        setBillingSeledted(newDAta);
    };

    const setRsultSeledtedAction = (value) => {
        const selectedValue =
            Array.isArray(selectedOptionsAL) &&
            selectedOptionsAL.length != 0 &&
            value
                ? value[value.length - 1]
                : value
                ? value[0]
                : [];
        let newDAta = [];
        if (selectedValue?.value == "none") {
            newDAta = [];
        } else if (selectedValue?.value == "all") {
            newDAta = agentList.slice(2);
        } else {
            newDAta = value;
        }
        setSelectedOptionsAL(newDAta);
        setRsultSeledted(newDAta);
    };

    return (
        <Container>
            <TitleBreadCrumbs title="Commission Report">
                <div className="page-breadcrumbs">
                    <Link to="/dashboard">Home</Link>
                    <Link to="#">Reports</Link>
                    {!show && (
                        <>
                            <span className="current_breadcrumbs">
                                Commission Reports{" "}
                            </span>
                        </>
                    )}
                    {show && (
                        <>
                            <Link onClick={() => goBack()} to="#">
                                Commission Reports
                            </Link>
                            <span className="current_breadcrumbs">Result </span>
                        </>
                    )}
                </div>
            </TitleBreadCrumbs>
            <Row>
                <Col md={12}>
                    <Card>
                        <CardBody className="filter-invoice-report">
                            <Row>
                                {!show && (
                                    <Col md={12}>
                                        <form
                                            onSubmit={handleFormSubmit}
                                            className="filter-invoice-report-form"
                                        >
                                            <h3>Filter Commission Report</h3>
                                            <hr />
                                            <div className="form__form-group">
                                                <span className="form__form-group-label">
                                                    Tax Year:
                                                </span>
                                                <div>
                                                    <Select
                                                        options={TaxYearList}
                                                        value={
                                                            selectedOptionsTY
                                                        }
                                                        isMulti="true"
                                                        styles={SELECT_STYLES}
                                                        onChange={(value) =>
                                                            setYearSeledtedAction(
                                                                value
                                                            )
                                                        }
                                                    />
                                                </div>
                                            </div>
                                            <div className="form__form-group">
                                                <span className="form__form-group-label">
                                                    Property Type:
                                                </span>
                                                <div>
                                                    <Select
                                                        options={propertyType}
                                                        value={
                                                            selectedOptionsPT
                                                        }
                                                        isMulti="true"
                                                        styles={SELECT_STYLES}
                                                        onChange={(value) =>
                                                            setPropertySeledtedAction(
                                                                value
                                                            )
                                                        }
                                                    />
                                                </div>
                                            </div>
                                            <div className="form__form-group">
                                                <span className="form__form-group-label">
                                                    Exemption Type:
                                                </span>
                                                <div>
                                                    <Select
                                                        options={exemptions}
                                                        value={
                                                            selectedOptionsET
                                                        }
                                                        isMulti="true"
                                                        styles={SELECT_STYLES}
                                                        onChange={(value) =>
                                                            setExemptionSeledtedAction(
                                                                value
                                                            )
                                                        }
                                                    />
                                                </div>
                                            </div>

                                            <div className="form__form-group">
                                                <span className="form__form-group-label">
                                                    Billing Type:
                                                </span>
                                                <div>
                                                    <Select
                                                        options={resultList}
                                                        isMulti="true"
                                                        value={
                                                            selectedOptionsBT
                                                        }
                                                        styles={SELECT_STYLES}
                                                        onChange={(value) =>
                                                            setBillingSeledtedAction(
                                                                value
                                                            )
                                                        }
                                                    />
                                                </div>
                                            </div>
                                            {/* <div className="form__form-group">
                                                <span className="form__form-group-label">
                                                    Result Agent:
                                                </span>
                                                <div>
                                                    <Select
                                                        options={agentList}
                                                        value={
                                                            selectedOptionsAL
                                                        }
                                                        isMulti="true"
                                                        styles={SELECT_STYLES}
                                                        onChange={(value) =>
                                                            setRsultSeledtedAction(
                                                                value
                                                            )
                                                        }
                                                    />
                                                </div>
                                            </div> */}
                                            <div className="text-right">
                                                <Button
                                                    type="submit"
                                                    color="primary"
                                                >
                                                    Submit
                                                </Button>
                                            </div>
                                        </form>
                                    </Col>
                                )}

                                {show && (
                                    <Col
                                        md={12}
                                        className="invoiced-report-result"
                                    >
                                        <table style={{ width: "100%" }}>
                                            <thead>
                                                <tr>
                                                    <td>Agent</td>
                                                    <td>Properties # Reduced</td>
                                                    <td>Reduction %</td>
                                                    <td> </td>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {dataTable.map((i, k) => (
                                                    <tr>
                                                        <td>{i.agent}</td>
                                                        <td>
                                                            {i.total_reduction}
                                                        </td>
                                                        <td>
                                                            {
                                                                i.percentageReduction
                                                            }
                                                            %
                                                        </td>
                                                        <td>
                                                            {i.percentageReduction >
                                                                0 && (
                                                                <Button
                                                                    className="my-0 primary-2 white-nowrap"
                                                                    color="primary"
                                                                    onClick={() =>
                                                                        handlView(
                                                                            i.agent_id,
                                                                            i.agent
                                                                        )
                                                                    }
                                                                >
                                                                    View
                                                                    Properties
                                                                </Button>
                                                            )}
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>{" "}
                                        {userData?.email ==
                                            "admin@gmail.com" && (
                                            <div
                                                style={{
                                                    textAlign: "center",
                                                    marginTop: 20,
                                                }}
                                            >
                                                <Button
                                                    onClick={() =>
                                                        exportReport("excel")
                                                    }
                                                >
                                                    Export to Excel
                                                </Button>
                                                {/* <Button
                                                    onClick={() =>
                                                        exportReport("pdf")
                                                    }
                                                >
                                                    Export to PDF
                                                </Button> */}
                                            </div>
                                        )}
                                    </Col>
                                )}
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
};

export default reduxForm({
    form: "filter_invoice_report",
})(CommissionReport);
