/* eslint-disable */
import React from 'react'
import { Redirect, Route } from "react-router-dom";
import { useCookies } from "react-cookie";
import { USERLEVELS  } from '@/utils/constant'

const AdminRoutes = ({ component: Component, ...rest }) => {
    const [cookies, removeCookie] = useCookies(["name"]);
    const userData = JSON.parse(localStorage.getItem('userData'));
    

    return (
        <>
        <Route
            {...rest}
            render={props =>
                userData?.userlevel === USERLEVELS?.ADMIN || userData?.userlevel === USERLEVELS?.STAFF ||  userData?.userlevel === USERLEVELS?.STAFF_ROLE  ? (
                    <Component {...props} />
                ) : (
                    <Redirect to="/login" />
                )
            }
        />
    </>
    )
}

export default AdminRoutes;