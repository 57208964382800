/* eslint-disable */
import React, { useState } from 'react';
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import { 
    Card, CardBody,  Container, Button
  } from 'reactstrap';
import { Link, useHistory } from 'react-router-dom';
import TitleBreadCrumbs from '@/components/TitleBreadCrumbs'
import StaffTable from './components/StaffTable'
import ChangePassword from '@/components/ChangePassword';
import ApiServices from "@/utils/ApiServices";
import { successMsg, globalError } from "@/utils/message_herlper"
import {  USERLEVELS  } from '@/utils/constant'

const StaffList = () => {

    const userData = JSON.parse(localStorage.getItem('userData'));
    if(userData?.userlevel !== USERLEVELS.ADMIN){
        window.location.href = '/404';
    }
    const history = useHistory();

    const columns = [
        {
            name: 'ID',
            selector: 'id',
            sortable: true,
            width: '100px'
        },
        {
            name: 'Username',
            selector: 'username',
            sortable: true,
        },
        {
            name: 'Full Name',
            selector: 'fname',
            sortable: true,
        },
        {
            name: 'Company Name',
            selector: 'company',
            sortable: true,
        },
        {
            name: 'Phone No. 1',
            selector: 'phone',
            sortable: true,
        },
        {
            name: 'Phone No. 2',
            selector: 'phone2',
            sortable: true,
        },
        {
            name: 'Email Address',
            selector: 'email',
            sortable: true,
        },
        {
            name: 'isActive',
            selector: 'isactive',
            sortable: true,
            width: '100px'
        },
        {
            name: '',
            selector: 'actions',
            width: '200px'
        }
    ];

    const [isSubmit, setSubmit] = useState(false);
    const [id, setID] = useState('');
    const [changePass, setChangePass] = useState(false);
    const [activeTab, setActiveTab] = useState('active')

    const openChangePass  = (id) => {
        setID(id)
        setChangePass(true)
    }
    
    const onChangePassword  = (form) => {
        setSubmit(true)
        form.id = id
        ApiServices.updatePassword(form).then((result) => {  
           setTimeout(() =>{
               setChangePass(false)
               setSubmit(false)
               successMsg('PASSWORD_SUCCESS');
           },1000)
        })
        .catch((error) => {
            setSubmit(false)
            globalError()
        })
    } 

    return (
        <>
        <ChangePassword isSubmit={isSubmit}   open={changePass}  onSubmit={(form) => onChangePassword(form)}  close={() => setChangePass(false)}  />
        <Container>
            <TitleBreadCrumbs title="Staff Members" >
                <div className="page-breadcrumbs">
                    <Link to="/dashboard">Dashboard</Link>
                    <Link to="/settings">Settings</Link>
                    <span className="current_breadcrumbs">
                        Staff members
                    </span>
                </div>
            </TitleBreadCrumbs>
            <Card class="card-controlled">
                <CardBody class="cardbody-controlled">
                    <Link to="/account-page/create">
                       <Button color="primary">Create new staff member</Button>
                    </Link>
                    <div className="cardbody-controlled">
                        <Tabs activeKey={activeTab} onSelect={(k) => setActiveTab(k)}>
                            <Tab eventKey="active" title="Active">
                                <div className="mt-4">
                                     <StaffTable  onChangePassword={(id)=> openChangePass(id)} status="active" />
                                </div>
                            </Tab>
                            <Tab eventKey="all" title="All Staff">
                                <div className="mt-4">
                                     <StaffTable  onChangePassword={(id)=> openChangePass(id)} />
                                </div>
                            </Tab>
                        </Tabs>
                        
                        
                    </div>
                </CardBody>
            </Card>
        </Container>
        </>
    );
}

export default StaffList;