/* eslint-disable */
import React, { useEffect, useState} from 'react';
import { Controller } from 'react-hook-form';
import PropTypes from 'prop-types';
import { register } from 'react-scroll/modules/mixins/scroller';

//const[dvalue, setValue] = useState('FORT BEND');
export const FormField = ({
    name,
    errors,
    control,
    component,
    isAboveError,
    ...other
  }) => (
    <div className={`form__form-group-input-wrap ${isAboveError ? 'form__form-group-input-wrap--error-above' : ''}`}>
      <Controller
        name={name}
        as={component}
        control={control}
        {...other}
      />
      <span></span>
      {/* {errors?.[name] && <span className="form__form-group-error">{errors[name].message}</span>} */}
    </div>
);

FormField.propTypes = {
  name: PropTypes.string.isRequired,
  errors: PropTypes.shape({}),
  control: PropTypes.shape({}).isRequired,
  isAboveError: PropTypes.bool,
  component: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
};

FormField.defaultProps = {
  errors: null,
  isAboveError: false,
  component: 'input',
};

export const FormSelect = ({
  name,
  errors,
  control,
  component,
  isAboveError,
  options,
  onChange,
  isOject,
  objectName,
  placeholder,
  value,
  defaultValue,
  ...other
}) => (  
  <div className={`form__form-group-input-wrap ${isAboveError ? 'form__form-group-input-wrap--error-above' : ''}`}>
    <select   name={name} control={control} {...other} onChange={(e)=> onChange(e.target.value) }    >
      <option value="DEFAULT" disabled selected={defaultValue === 'DEFAULT'  ? true : false}   >{ placeholder ?  placeholder : 'Select your option' }</option>
      {!isOject && options.map((value, i) => (
        <option selected={value === defaultValue ? true : false}  key={i} value={value}>{value}</option>
      ))}
      {isOject && options.map((value, i) => (
        <option key={i} value={value.id} selected={value.id === defaultValue ? true : false} >{value[objectName]}</option>
      ))}
    </select>
    {/* {errors?.[name] && <span className="form__form-group-error">{errors[name].message}</span>} */}
  </div>
);

FormSelect.propTypes = {
name: PropTypes.string.isRequired,
errors: PropTypes.shape({}),
control: PropTypes.shape({}).isRequired,
isAboveError: PropTypes.bool,
component: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
};

FormSelect.defaultProps = {
errors: null,
isAboveError: false,
component: 'input',
};







