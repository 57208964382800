/* eslint-disable */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
    Col,
    Container,
    Card,
    CardBody,
    Button,
    Spinner,
} from "reactstrap";
import { useTranslation } from "react-i18next";
import { reduxForm } from "redux-form";
import { useForm } from "react-hook-form";
import { FormField, FormSelect } from "@/shared/components/FormField";
import {  zipPattern } from '@/shared/helpers';
import AccountOutlineIcon from "mdi-react/AccountIcon";
import MapMarkerIcon from "mdi-react/MapMarkerIcon";
import CancelIcon from "mdi-react/CancelIcon";

const EditForm = ({ onSubmit,onCancel,  isSubmit, errorList, propertyType, county, details }) => {   
    const { t } = useTranslation("common");
    const { handleSubmit, reset, control, errors, register, setValue, clearErrors } = useForm();
    const[emailError, setEmailError] = useState([]);
    const[usernameError, setUsernameError] = useState([]);
    const [propertyTypes, sePropertyType] = useState([]);
    const [showPassword, setShowPassword] = useState(false);

    useEffect(() => { 
        setEmailError(errorList?.email ? errorList?.email : [])
        setUsernameError(errorList?.username ? errorList?.username  : [] )
    },[errorList]);

    useEffect(() => {
        setValue('owner_name', details?.owner_name)
        setValue('property_address', details?.property_address)
        setValue('property_address_2', details?.property_address_2)
        setValue('property_city', details?.property_city)
        setValue('legal_desc', details?.legal_desc)
        setValue('txtdescription', details?.txtdescription)
        setValue('county', details?.county_id)
        setValue('property_type', details?.property_type)
        setValue('property_state', details?.property_state)
        setValue('property_zip', details?.property_zip)
        setValue('account_number', details?.account_number)
    },[details])

    const toggleShowPassword = (e) => {
        e.preventDefault();
        setShowPassword(!showPassword);
    };

    const onchangeCounty = (value) => {
        setValue('county', value)
        clearErrors('county')
    }

    const onchangeType = (value) => {
        setValue('property_type', value)
        clearErrors('property_type')
    }


    return (
        <Container className="client-registration-container">
            <Col md={12}>
                <Card>
                    <CardBody className="client-registration-form-container">
                        <form
                            className="client-reg-form"
                            onSubmit={handleSubmit(onSubmit)}
                        >
                            <div className="form__form-group">
                                <span className="form__form-group-label">
                                    Owner Name
                                </span>
                                <div className="form__form-group-field">
                                    <div className="form__form-group-icon">
                                        <AccountOutlineIcon />
                                    </div>
                                    <FormField
                                        name="owner_name"
                                        component="input"
                                        control={control}
                                        errors={errors}
                                        rules={{
                                            required: "This is required field",
                                        }}
                                        placeholder="Owner Name"
                                    />
                                </div>
                                {errors?.owner_name && <span className="form__form-group-error">{errors?.owner_name?.message}</span>}
                            </div>
                            <div className="form_two_columns">
                                <div className="form__form-group">
                                   <span className="form__form-group-label">
                                       County:
                                    </span>
                                    <div className="form__form-group-field">
                                        <div className="form__form-group-icon">
                                            <MapMarkerIcon />
                                        </div>
                                        <FormSelect
                                            name="county"
                                            placeholder="Select County"
                                            control={control}
                                            options={county}
                                            isOject={true}
                                            objectName="name"
                                            defaultValue={details?.county_id ? details?.county_id : 'DEFAULT'}
                                            {...register("county", { required: "This is required field"})} 
                                            onChange={(value)=> onchangeCounty(value)}
                                        />
                                    </div>
                                    {errors?.county && <span className="form__form-group-error">{errors?.county?.message}</span>}
                                </div>
                                <div className="form__form-group">
                                   <span className="form__form-group-label">
                                      Property Type:
                                    </span>
                                    <div className="form__form-group-field">
                                        <div className="form__form-group-icon">
                                            <MapMarkerIcon />
                                        </div>
                                        <FormSelect
                                            name="property_type"
                                            placeholder="Select Property Type"
                                            rules={{
                                                required: "This is required field",
                                            }}
                                            control={control}
                                            options={propertyType}
                                            isOject={true}
                                            objectName="type"
                                            defaultValue={details?.property_type ? details?.property_type : 'DEFAULT'}
                                           {...register("property_type", { required: "This is required field" })} 
                                            onChange={(value)=> onchangeType(value)}
                                        />
                                    </div>
                                    {errors?.property_type && <span className="form__form-group-error">{errors?.property_type?.message}</span>}
                                </div>
                            </div>

                            <div className="form__form-group">
                                <span className="form__form-group-label">
                                    Property Address
                                </span>
                                <div className="form__form-group-field">
                                    <FormField
                                        name="property_address"
                                        component="input"
                                        control={control}
                                        errors={errors}
                                        placeholder="Property Address"
                                    />
                                </div>
                                {errors?.property_address && <span className="form__form-group-error">{errors?.property_address?.message}</span>}
                            </div>
                            <div className="form_two_columns">
                                <div className="form__form-group">
                                    <span className="form__form-group-label">
                                        Property Address 2
                                    </span>
                                    <div className="form__form-group-field">
                                        <FormField
                                            name="property_address_2"
                                            component="input"
                                            control={control}
                                            errors={errors}
                                            placeholder="Property Address"
                                        />
                                    </div>
                                    {errors?.address2 && <span className="form__form-group-error">{errors?.address2?.message}</span>}
                                </div>
                                <div className="form__form-group">
                                    <span className="form__form-group-label">
                                        Property City
                                    </span>
                                    <div className="form__form-group-field">
                                        <FormField
                                            name="property_city"
                                            component="input"
                                            control={control}
                                            errors={errors}
                                            placeholder="Property City"
                                        />
                                    </div>
                                    {errors?.property_city && <span className="form__form-group-error">{errors?.property_city?.message}</span>}
                                </div>
                            </div>
                            <div className="form_two_columns">
                                <div className="form__form-group">
                                    <span className="form__form-group-label">
                                       State
                                    </span>
                                    <div className="form__form-group-field">
                                        <FormField
                                            name="property_state"
                                            component="input"
                                            control={control}
                                            errors={errors}
                                            placeholder="State"
                                        />
                                    </div>
                                    {errors?.property_state && <span className="form__form-group-error">{errors?.property_state?.message}</span>}
                                </div>
                                <div className="form__form-group">
                                    <span className="form__form-group-label">
                                        Zip
                                    </span>
                                    <div className="form__form-group-field">
                                        <FormField
                                            name="property_zip"
                                            component="input"
                                            control={control}
                                            errors={errors}
                                            rules={{
                                                pattern: {
                                                    value: zipPattern,
                                                    message: 'Entered value does not match zip code format',
                                                },
                                            }}
                                            placeholder="Zip"
                                        />
                                    </div>
                                    {errors?.property_zip && <span className="form__form-group-error">{errors?.property_zip?.message}</span>}
                                </div>
                            </div>
                            <div className="form_two_columns">
                                <div className="form__form-group">
                                    <span className="form__form-group-label">
                                        Account Number
                                    </span>
                                    <div className="form__form-group-field">
                                        <FormField
                                            name="account_number"
                                            component="input"
                                            control={control}
                                            errors={errors}
                                            placeholder="Account Number"
                                        />
                                    </div>
                                    {errors?.account_number && <span className="form__form-group-error">{errors?.account_number?.message}</span>}
                                </div>
                                <div className="form__form-group">
                                   
                                </div>
                            </div>
                            <div className="form__form-group">
                                <span className="form__form-group-label">
                                    Property Legal Description
                                </span>
                                <div className="form__form-group-field">
                                    <FormField
                                        name="legal_desc"
                                        component="textarea"
                                        control={control}
                                        errors={errors}
                                        placeholder="Property Legal Description"
                                        rows={4}
                                    />
                                </div>
                                {errors?.legal_desc && <span className="form__form-group-error">{errors?.legal_desc?.message}</span>}
                            </div>
                            {/* <div className="form__form-group">
                                <span className="form__form-group-label">
                                    Property Description
                                </span>
                                <div className="form__form-group-field">
                                    <FormField
                                        name="txtdescription"
                                        component="textarea"
                                        control={control}
                                        errors={errors}
                                        placeholder="Property Description"
                                        rows={4}
                                    />
                                </div>
                                {errors?.txtdescription && <span className="form__form-group-error">{errors?.txtdescription?.message}</span>}
                            </div> */}

                            <div className="client-create-btns">
                                <Button
                                    type="button"
                                    color="secondary"
                                    className="cancel__btn"
                                    onClick={()=> onCancel()}
                                >
                                    <CancelIcon /> Cancel
                                </Button>
                                <Button
                                    type="submit"
                                    color="primary"
                                    disabled={isSubmit}
                                >
                                    {isSubmit ? <Spinner size="sm" color="secondary" className="mr-2" /> : ''}
                                     Submit
                                </Button>
                            </div>
                        </form>
                    </CardBody>
                </Card>
            </Col>
        </Container>
    );
};

EditForm.propTypes = {
    isHorizontal: PropTypes.bool,
};

EditForm.defaultProps = {
    isHorizontal: false,
};



export default reduxForm({
    form: "client_registration",
  })(EditForm);