/* eslint-disable */
import React, { useState, useEffect } from "react";
import AngleLeft from '../images/angleArrowLeft';
import AngleRight from '../images/angleArrowRight';


const CustomPagination = ({ rowCount, currentPage, totalRows, perPage, handlePageChange }) => {
    const [pagi, setPagi] = useState([])
    const [next, setNext] = useState(false);
    const [prev, setPrev] = useState(true)
    const [pagerLimit, setPagerLimit] = useState(5)

    const updatePagerLimit = () => {  console.log('pagerLimit', pagerLimit)
        setPagerLimit( pagerLimit + 5 );
    }

    const generatePagination = () => {
        var tempTotal = Math.ceil(totalRows / perPage);
        var tempData = []
        
        for(var x = 1; x <= tempTotal; x++ ){
            var temp = { x };
            tempData.push( temp )
        }

        setPagi(tempData)
    }

    const handlePageChecker = ( temp ) => {
        if( (temp - 1) === 0 ){
            setPrev( true )
        }else{
            setPrev( false )
        }

        if( temp === Math.ceil(totalRows / perPage) ){
            setNext( true )
        }else{
            setNext( false )
        }
    }

    const handlePrevChange = () => {
        if( currentPage !== 1 ){
            handlePageChecker( currentPage - 1 )
            handlePageChange( currentPage - 1 )
        }
    }

    const handleNextChange = () => {
        if( currentPage !== Math.ceil( totalRows / perPage ) ){
            handlePageChecker( currentPage + 1 )
            handlePageChange( currentPage + 1 )
        }
    }

    useEffect(() =>{
        generatePagination()
    },[perPage])

    useEffect(() =>{
        generatePagination()
    },[pagerLimit])

    useEffect(() =>{
        generatePagination()
    },[totalRows])
    
    return (
        <>
        { ( totalRows - rowCount > 0 ) && 
        <div className="custom-datatable-pagination">
            <div className="custom-datatable-pagination-wrapper">
            {totalRows &&
            <>
                <div className={`${ prev ? 'disabled ' : '' }custom-prev-arrow`} onClick={handlePrevChange}><AngleLeft color="#383938" /></div>
                {pagi.map((item, index) => (
                    <>
                    { ( index === pagerLimit && ( Math.ceil(totalRows / perPage) > pagerLimit ) )&& 
                        <span onClick={updatePagerLimit} key={index} className="pagination-filler">...</span>
                    }
                    { index < pagerLimit &&
                        <span key={index} onClick={() => { handlePageChecker( index + 1 ); handlePageChange( index + 1 ); }} className={`${ ( index + 1 ) == currentPage ? 'current-page' : '' }`}>{index + 1}</span>
                    }
                    { ( index > pagerLimit && ( (index + 1) === ( Math.ceil(totalRows / perPage) ) ) ) &&
                        <span key={index} onClick={() => { handlePageChecker( index + 1 ); handlePageChange( index + 1 ); }} className={`${ ( index + 1 ) == currentPage ? 'current-page' : '' }`}>{index + 1}</span>
                    }
                    </>
                ))}
                <div className={`${ next ? 'disabled ' : '' }custom-next-arrow`} onClick={handleNextChange}><AngleRight color="#383938" /></div>
            </>
            }
            </div>
            <div className="custom-pagination-total">
                { currentPage === 1 && 
                <>
                    Showing {rowCount} of {totalRows} entries
                </>
                }
                { currentPage > 1 &&
                <>
                    Showing {currentPage * perPage > totalRows ? (((currentPage * perPage) - perPage ) + rowCount ) : (currentPage * perPage) } of {totalRows} entries
                </>
                }
            </div>
        </div>
        }
        </>
    )
}

export default CustomPagination;