/* eslint-disable */
import React, { useState, useEffect, useMemo, useRef } from "react";
import { useHistory } from 'react-router-dom';
import DataTable from "react-data-table-component";
import ApiServices from "@/utils/ApiServices";
import CustomPagination from '@/components/CustomPagination'
import {
    Button,
} from "reactstrap";
import Empty from "@/components/Empty";
import { TABLE_SETTINGS } from "@/utils/constant";
import TableLoading from '@/components/TableLoading'
  
const PropertyResult =   React.memo(({params}) => { 
    const history = useHistory();
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(TABLE_SETTINGS.PAGINATION_PER_PAGE);
    const [currentPage, setCurrentPage] = useState(1);
    const [rowCount, setRowCount] = useState(0)
    const [search, setSearch] = useState('');
    const [searchKey, setSearchKey] = useState('');
    const page_ref = useRef(1);
    const saerch_ref = useRef(null);

    useEffect(() =>{
        if(!loading){
            setLoading(true)
            setTimeout(() => {
                setLoading(false)
            },1000) 
        } 
    
      },[params])

    const columns = useMemo(
        () => [
            {
                name: "Owner's Name",
                selector: "owner_name",
                sortable: false,
            },
            {
                name: "Address",
                selector: "property_address",
                sortable: false,
            },
            {
                name: "City, State  Zip",
                selector: "property_city",
                sortable: false,
            },
            {
                name: "County",
                selector: "county",
                sortable: false,
            },
            {
                name: "Account #",
                selector: "account_number",
                sortable: false,
            },
            {
                name: "Property Type",
                selector: "property_type",
                sortable: false,
            },
            {
                cell: (row) => (
                    <Button className="my-0  btn-sm white-nowrap"  onClick={()=> handleRowClick(row)}>Details</Button>
                ),
            },
        ],
    );

  

    useEffect(() => {
        if(params){
            saerch_ref.current = params;
            fetchData(1);
        }
    }, [params]);
    
    const fetchData = async (page, size = perPage, searchData = search) => {  
        if(saerch_ref?.current){
            page_ref.current = page;
            ApiServices.customPropertiesFilterList({'page' : page, 'per_page': size, filters: saerch_ref?.current})
            .then((result) => {    
                setData(result.data.data);
                setRowCount(result.data.data.length)
                setTotalRows(result.data.total);
                setLoading(false);
            })
            .catch(function (error) {
            });
        }
    };

    const handleRowClick = (row) => {  
        history.push({pathname: `/property/details/${row?.id}`})
    } 
    
    const handlePageChange = (page) => {
        fetchData(page);
        setCurrentPage(page);
    };

    const handlePerRowsChange = async (newPerPage, page) => { 
        fetchData(page, newPerPage);
        setPerPage(newPerPage);
    };



    const  dataTable = []
    const list = data
    for (let index = 0; index < list.length; index++) {
        dataTable.push({
            id: list[index].id,
            owner_name: list[index].owner_name,
            property_address: list[index].property_address,
            property_city: `${list[index]?.property_city || '' }, ${list[index]?.property_state || ''} ${list[index]?.property_zip || ''}`,
            county: list[index].country_name,
            account_number: list[index].account_number,
            property_type: list[index].property_type_value,
        })
    }

    return (
       <>
           <div className="dashboard-card-title">
                <p>Properties</p>
            </div>
            <DataTable
                //title="Users"
                columns={columns}
                data={dataTable}
                progressPending={loading}
                progressComponent={<TableLoading/>}
                pagination={false}
                paginationServer
                paginationTotalRows={totalRows}
                paginationDefaultPage={currentPage}
                striped={TABLE_SETTINGS.STRIPED}
                highlightOnHover={true}
                onChangeRowsPerPage={handlePerRowsChange}
                onChangePage={handlePageChange}
                onSelectedRowsChange={({ selectedRows }) =>
                    console.log(selectedRows)
                }
                paginationPerPage={TABLE_SETTINGS.PAGINATION_PER_PAGE}
                paginationRowsPerPageOptions={TABLE_SETTINGS.ROW_PER_PAGE}
                noDataComponent={<Empty />}
            />
            <CustomPagination rowCount={rowCount} currentPage={currentPage} totalRows={totalRows} perPage={TABLE_SETTINGS.PAGINATION_PER_PAGE} handlePageChange={handlePageChange} />
       </>
    );
})

export default PropertyResult;
