/* eslint-disable */
import React, { useState, useEffect } from "react";
import { Card, CardBody, Col, Container, Row, Button } from "reactstrap";
import { Link, useHistory, useParams } from "react-router-dom";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import ActiveProperties from "./components/activeProperties";
import ClientActivities from "./clientActivities";
import ActivitiesList from "@/components/ActivitiesList";
import ClientNotes from "./components/clientNotes";
import OutstadingBalance from "./components/OutstadingBalance";
import ChangePassword from "@/components/ChangePassword";
import ClientSheets from "./client-sheet";
import PencilIcon from "@/images/pencilIcon";
import StopIcon from "@/images/stopIcon";
import DollarIcon from "@/images/dollarIcon";
import ExportIcon from "@/images/exportIcon";
import AngleArrowLeft from "@/images/angleArrowLeft";
import TitleBreadCrumbs from "@/components/TitleBreadCrumbs";
import ModalConfirm from "@/components/ModalConfirm";
import ApiServices from "@/utils/ApiServices";
import {
    successMsg,
    globalError,
    formError,
    successMsgCustom,
    toastLoading,
} from "@/utils/message_herlper";
import moment from "moment";
import ToggleSwitch from "@/components/ToggleSwitch";
import ReportIcon from "@/images/reportsIcon";
import ModalRequestEmail from "@/components/ModalRequestEmail";
import { useCookies } from "react-cookie";
import { API, TIMEZONE, USERLEVELS } from "@/utils/constant";
import ModalEditNotes from "@/components/ModalEditNotes";

const ClientDetails = (props) => {
    const userData = JSON.parse( localStorage.getItem('userData') )
    let { id, rid } = useParams();
    const history = useHistory();
    const [cookies, removeCookie] = useCookies(["name"]);

    const [user, setUser] = useState(0);
    const [show, setShow] = useState(false);
    const [show2, setShow2] = useState(false);
    const [outStandingValue, setOutstadingValue] = useState(false);
    const [outStanding, setOutstading] = useState(false);
    const [active, setActive] = useState(false);
    const [showOutstanding, setShowOutstading] = useState(false);
    const [changePass, setChangePass] = useState(false);
    const [details, setDetails] = useState(null);
    const [notes, setNotes] = useState([]);
    const [isNotes, setIsNotes] = useState(false);
    const [resetNotes, setResetNotes] = useState(false);
    const [activePropCount, setActivePropCount] = useState(0);
    const [inactivePropCount, setInactivePropCount] = useState(0);
    const [noteCount, setNoteCount] = useState(0);
    const [activitiesCount, setActivitiesCount] = useState(0);
    const [request, setRequest] = useState(false);
    const [requestData, setRequestData] = useState(null);
    const [showEdit, setShowEdit] = useState(false);
    const [editNotesDetails, setEditNotesDetails] = useState(null);

    useEffect(() => {
        if (rid && rid !== "0") {
            ApiServices.requestEmail({
                id: atob(rid),
                type: "profile-edit",
            }).then((result) => {
                setTimeout(() => {
                    setRequest(true);
                }, 1000);
                setRequestData(result.data);
            });
        }
    }, [props]);

    const handleOutstading = (e) => {
        var value = !e ? "Y" : "N";
        updateOustanding(value);
        // setShowOutstading(!showOutstanding)
    };

    const handleSetActivePropCount = (count) => {
        setActivePropCount(count);
    };

    const handleSetInactivePropCount = (count) => {
        setInactivePropCount(count);
    };
    const handleSetNoteCounts = (count) => {
        setNoteCount(count);
    };
    const handleSetActivitiesCount = (count) => {
        setActivitiesCount(count);
    };
    const [dataStatus, setDataStatus] = useState(null);
    const [isSubmit, setSubmit] = useState(false);
    const [activities, setActivities] = useState([]);
    const [isSubmitNotes, setSubmitNotes] = useState(false);

    useEffect(() => {
        clientDetails();
        fetchNotes();
        fetchActivities();
    }, [id]);

    const handleOutstanding = (e) => {
        if (e.target.value == "true") {
            setOutstadingValue(true);
        } else {
            setOutstadingValue(false);
        }
    };

    const confirmOutstanding = () => {
        setOutstading(outStandingValue);
    };

    const handlePassModal = () => {
        setChangePass(false);
    };

    const clientDetails = () => {
        ApiServices.clientsDetails(id)
            .then((result) => {
                setDetails(result?.data);
                setOutstading(
                    result?.data?.outstanding_balance === "Y" ? true : false
                );
                setActive(result?.data?.status === "A" ? true : false);
            })
            .catch((error) => {
                formError();
            });
    };

    const handleEditClient = () => {
        history.push({ pathname: `/client/edit/${id}` });
    };

    const onSubmit = (form) => {
        setIsNotes(true);
        setResetNotes(false);
        form.id = id;
        form.type = 1;
        ApiServices.saveNotes(form)
            .then((result) => {
                setTimeout(() => {
                    const newData = [...notes];
                    newData.unshift(result?.data?.notes[0]);
                    setNotes(newData);
                    setIsNotes(false);
                    setResetNotes(true);
                    successMsg("NOTES_SUCCESS");
                }, 1000);
            })
            .catch((error) => {
                setIsNotes(false);
                formError();
            });
    };

    const fetchNotes = () => {
        ApiServices.fetchNotes(id)
            .then((result) => {
                setNotes(result.data);
            })
            .catch((error) => {
                globalError();
            });
    };

    const fetchActivities = () => {
        ApiServices.clientActivities(id)
            .then((result) => {
                var tempAct = result.data;

                ApiServices.viewEditPending(id)
                    .then((result) => {
                        var viewPendingTemp = result.data;

                        if (
                            viewPendingTemp.length !== 0 &&
                            viewPendingTemp.status === 0
                        ) {
                            var data = tempAct.concat(viewPendingTemp);
                            setActivities(data);
                        } else {
                            setActivities(tempAct);
                        }
                    })
                    .catch((error) => {
                        globalError();
                    });
            })
            .catch((error) => {
                globalError();
            });
    };

    const updateOustanding = (value) => {
        setSubmit(true);
        let params = {
            id: id,
            value: value,
        };
        ApiServices.updateOustanding(params)
            .then((result) => {
                setTimeout(() => {
                    setSubmit(false);
                    setShowOutstading(false);
                    // setOutstading(value === 'Y' ? true : false)
                    setOutstading(!outStanding);
                    successMsg("OUTSTANDING_SUCCESS");
                }, 1000);
            })
            .catch((error) => {
                setSubmit(false);
                globalError();
            });
    };

    const onChangePassword = (form) => {
        setSubmit(true);
        form.id = id;
        ApiServices.updatePassword(form)
            .then((result) => {
                fetchActivities();
                setTimeout(() => {
                    setChangePass(false);
                    setSubmit(false);
                    successMsg("PASSWORD_SUCCESS");
                }, 1000);
            })
            .catch((error) => {
                setSubmit(false);
                globalError();
            });
    };

    const updateStatus = () => {
        let params = {
            id: id,
            status: dataStatus,
        };
        ApiServices.updateStatus(params)
            .then((result) => {
                successMsg("STATUS_SUCCESS");
                setTimeout(() => {
                    window.location.reload();
                }, 1000);
                // setShow(false);
                // setShow2(false);

                // setActive(dataStatus === 'A' ? true : false)
                // setDataStatus(null)
                // fetchActivities();
            })
            .catch((error) => {
                globalError();
            });
    };

    const updateStatusConfirm = (status) => {
        setDataStatus(status);
        if (status === "I") {
            setShow(true);
        } else {
            setShow2(true);
        }
    };

    const handleAccountSpecific = () => {
        history.push({
            pathname: `/reports/account-specific-report/${id}/${details?.client_first_name} ${details?.client_last_name}`,
        });
    };

    const closeRequestEmal = () => {
        setRequest(false);
        history.push({ pathname: `/client/details/${id}` });
    };

    const handleExport = () => {
        window.open(
            `${API}client-data-export/${btoa(id || 0)}/${btoa(
                moment()
                    .tz(TIMEZONE)
                    .add(1, "hours")
                    .format("YYYY-MM-DD HH:mm:ss")
            )}`,
            "_blank"
        );
    };

    const deleteNotes = (id) => {
        const newData = [...notes];
        const prevIndex = notes.findIndex((item) => item.id === id);
        if (prevIndex !== -1) {
            newData.splice(prevIndex, 1);
        }
        setNotes(newData);
        ApiServices.deleteClientNotes({ id: id })
            .then((result) => {
                setTimeout(() => {
                    //successMsgCustom('Notes successfully deleted');
                }, 1000);
            })
            .catch((error) => {
                setSubmit(false);
                globalError();
            });
    };

    const editNotes = (details) => {
        setEditNotesDetails(details);
        setShowEdit(true);
    };

    const submitEditedNotes = (details) => {
        setSubmitNotes(true);

        ApiServices.editClientNotes({
            id: editNotesDetails?.id,
            notes: details?.notes,
        })
            .then((result) => {
                setTimeout(() => {
                    setSubmitNotes(false);
                    successMsgCustom("Notes successfully updated");
                    setShowEdit(false);
                    setEditNotesDetails(null);
                    const newData = [...notes];
                    const prevIndex = notes.findIndex(
                        (item) => item.id === editNotesDetails?.id
                    );
                    if (prevIndex !== -1) {
                        newData[prevIndex].notes = details?.notes;
                    }
                    setNotes(newData);
                }, 1000);
            })
            .catch((error) => {
                setSubmitNotes(false);
                globalError();
            });
    };

    return (
        <>
            <ModalRequestEmail
                open={request}
                requestData={requestData}
                close={() => closeRequestEmal()}
            />
            <OutstadingBalance
                isSubmit={isSubmit}
                outStanding={outStanding}
                open={showOutstanding}
                close={() => setShowOutstading(false)}
                action={(value) => updateOustanding(value)}
            />
            <ChangePassword
                isSubmit={isSubmit}
                open={changePass}
                onSubmit={(form) => onChangePassword(form)}
                close={() => setChangePass(false)}
            />
            <ModalConfirm
                title="Deactivate Client"
                description="Are you sure you want to delete this client? All the properties connected to the Client will also be deleted?"
                btnText="Deactivate"
                open={show}
                status={dataStatus}
                Onsubmit={() => updateStatus()}
                close={() => setShow(false)}
            />
            <ModalConfirm
                title="Activate Client"
                description="Activating a client will not activate the properties of the client, Are you sure you want to activate the client?"
                btnText="Activate"
                open={show2}
                status={dataStatus}
                Onsubmit={() => updateStatus()}
                close={() => setShow2(false)}
            />
            <ModalEditNotes
                open={showEdit}
                isSubmit={isSubmitNotes}
                values={editNotesDetails}
                onSubmit={submitEditedNotes}
                close={() => {
                    setShowEdit(false);
                    setEditNotesDetails(null);
                }}
            />
            <Container>
                <TitleBreadCrumbs title="Client profile">
                    <div className="page-breadcrumbs">
                        <Link to="/dashboard">Dashboard</Link>
                        <Link to={`/clients`}>Clients</Link>
                        <span className="current_breadcrumbs">
                            Client profile{" "}
                        </span>
                    </div>
                    <div className="mb-4">
                        {rid === "0" ? (
                            <Link className="backto-link" to={`/activities`}>
                                <AngleArrowLeft color="#FFA647" />
                                Back to all activities
                            </Link>
                        ) : (
                            <Link className="backto-link" to={`/clients`}>
                                <AngleArrowLeft color="#FFA647" />
                                Back to all clients
                            </Link>
                        )}
                    </div>
                </TitleBreadCrumbs>

                <CardBody>
                    <Row>
                        <Col md={4}>
                            <div className="client-details-left">
                                <span
                                    className={
                                        active ? "is-active" : "not-active"
                                    }
                                >
                                    {active ? "Active" : "Inactive"}
                                </span>
                                <h3>
                                    <b>
                                        {details?.client_first_name}{" "}
                                        {details?.client_last_name}
                                    </b>
                                </h3>
                                <span className="outstanding-icon">
                                    {!outStanding ? (
                                        <DollarIcon color="#00C48C" />
                                    ) : (
                                        <DollarIcon color="#C35354" />
                                    )}
                                </span>
                                <span
                                    className={`${
                                        outStanding
                                            ? "with-balance"
                                            : "no-balance"
                                    } mr-3`}
                                >
                                    {outStanding
                                        ? "Outstanding Balance"
                                        : "No Outstanding Balance"}
                                </span>
                                <ToggleSwitch
                                    id={`general-switch`}
                                    checked={!outStanding ? true : false}
                                    disabled={false}
                                    onChange={(e) => {
                                        handleOutstading(e);
                                    }}
                                />
                                {/* <a className="outstanding-edit" onClick={() => setShowOutstading(true)}><MarkerIcon /></a> */}
                            </div>
                        </Col>
                        <Col md={8}>
                            <div className="client-details-right text-right">
                                <Button onClick={() => setChangePass(true)}>
                                    {/*<PasswordIcon />*/} Change Password
                                </Button>
                                <Button
                                    color="secondary"
                                    onClick={() => handleEditClient()}
                                >
                                    <PencilIcon color="#FFA647" /> Edit
                                </Button>
                                {active ? (
                                    <Button
                                        color="secondary"
                                        onClick={() => updateStatusConfirm("I")}
                                    >
                                        <StopIcon color="#FFA647" /> Deactivate
                                    </Button>
                                ) : (
                                    <Button
                                        color="secondary"
                                        onClick={() => updateStatusConfirm("A")}
                                    >
                                        <StopIcon color="#FFA647" /> Activate
                                    </Button>
                                )}
                                {userData.userlevel === USERLEVELS.ADMIN && (
                                    <>
                                        <Button
                                            color="secondary"
                                            onClick={() =>
                                                handleAccountSpecific()
                                            }
                                        >
                                            <ReportIcon color="#FFA647" />{" "}
                                            Account Specific Report
                                        </Button>
                                        <Button
                                            color="secondary"
                                            className="mr-0"
                                            onClick={() => handleExport()}
                                        >
                                            <ExportIcon color="#FFA647" />{" "}
                                            Export Data{" "}
                                        </Button>
                                    </>
                                )}
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={4}>
                            <Card className="pb-0">
                                <CardBody className="client-details-body">
                                    <div className="card-header">
                                        <p>Personal info</p>
                                    </div>
                                    <div className="card-content">
                                        <p className="card-detail-label">
                                            Company Name:
                                        </p>
                                        <p>{details?.company_name}</p>
                                        <p className="card-detail-label">
                                            Username:
                                        </p>
                                        <p>{details?.username}</p>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col md={4}>
                            <Card className="pb-0">
                                <CardBody className="client-details-body">
                                    <div className="card-header">
                                        <p>Contact Information</p>
                                    </div>
                                    <div className="card-content">
                                        <p className="card-detail-label">
                                            Mailing Address:
                                        </p>
                                        <p>
                                            {details?.homeaddress},{" "}
                                            {details?.homecity},{" "}
                                            {details?.homestate}{" "}
                                            {details?.homezip}
                                        </p>
                                        <p className="card-detail-label">
                                            Email:
                                        </p>
                                        <p>{details?.email}</p>
                                        <p className="card-detail-label">
                                            Phone(1):
                                        </p>
                                        <p>{details?.homephone1}</p>
                                        <p className="card-detail-label">
                                            Phone(2):
                                        </p>
                                        <p>{details?.homephone2}</p>

                                        <p>
                                            <b>Additional Details</b>
                                        </p>
                                        <p className="card-detail-label">
                                            Email:{" "}
                                            {details?.additionalcontactemail}
                                        </p>

                                        <p className="card-detail-label">
                                            Contact Number:{" "}
                                            {details?.additionalcontactnumber}
                                        </p>

                                        <p className="card-detail-label">
                                            Contact Name:{" "}
                                            {details?.additionalcontactname}
                                        </p>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col md={4}>
                            <Card className="pb-0">
                                <CardBody className="client-details-body">
                                    <div className="card-header">
                                        <p>Account Details</p>
                                    </div>
                                    <div className="card-content">
                                        <p className="card-detail-label">
                                            Billing Percentage:
                                        </p>
                                        <p>
                                            {details?.accnt_percent_of_savings}{" "}
                                            %
                                        </p>
                                        <p className="card-detail-label">
                                            Timestamp:
                                        </p>
                                        <p>
                                            {moment
                                                .utc(details?.aofatimestamp)
                                                .local()
                                                .format("MM/DD/yyyy hh:mmA")}
                                        </p>
                                        <p className="card-detail-label">
                                            IP Address:
                                        </p>
                                        <p>{details?.ipaddress}</p>
                                        <p className="card-detail-label">
                                            Paperless Billing:
                                        </p>
                                        <p>
                                            {details?.paperless_billing === "Y"
                                                ? "Online"
                                                : "No"}
                                        </p>
                                        <p className="card-detail-label">
                                            Promo Code:
                                        </p>
                                        <p>{details?.affiliatepromocode}</p>
                                        <p className="card-detail-label">
                                            How did you hear about us:
                                        </p>
                                        <p></p>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </CardBody>
                <Card className="mb-3 mt-4 card-controlled">
                    <CardBody className="cardbody-controlled">
                        <Tabs defaultActiveKey="activeProperties">
                            <Tab
                                eventKey="activeProperties"
                                title={
                                    <>
                                        Active Properties{" "}
                                        {activePropCount !== 0 && (
                                            <span
                                                className={`tab-counter tab-length-${
                                                    activePropCount?.length?.toString()
                                                        .length
                                                }`}
                                            >
                                                {activePropCount}{" "}
                                            </span>
                                        )}
                                    </>
                                }
                            >
                                <ActiveProperties
                                    id={id}
                                    owner_name={`${details?.client_first_name} ${details?.client_last_name}`}
                                    type="A"
                                    setCount={handleSetActivePropCount}
                                    perPageSet="90"
                                />
                            </Tab>
                            <Tab
                                eventKey="inactiveProperties"
                                title={
                                    <>
                                        Inactive Properties{" "}
                                        {inactivePropCount !== 0 && (
                                            <span
                                                className={`tab-counter tab-length-${
                                                    inactivePropCount?.length?.toString()
                                                        .length
                                                }`}
                                            >
                                                {inactivePropCount}{" "}
                                            </span>
                                        )}
                                    </>
                                }
                            >
                                <ActiveProperties
                                    id={id}
                                    owner_name={`${details?.client_first_name} ${details?.client_last_name}`}
                                    type="I"
                                    setCount={handleSetInactivePropCount}
                                    perPageSet="60"
                                />
                            </Tab>
                            <Tab
                                eventKey="notes"
                                title={
                                    <>
                                        {" "}
                                        Notes{" "}
                                        {noteCount !== 0 && (
                                            <span
                                                className={`tab-counter tab-length-${
                                                    noteCount?.length?.toString()
                                                        .length
                                                }`}
                                            >
                                                {noteCount?.length}
                                                {noteCount}
                                            </span>
                                        )}
                                    </>
                                }
                            >
                                <ClientNotes
                                    setCount={handleSetNoteCounts}
                                    isSubmit={isNotes}
                                    resetNotes={resetNotes}
                                    id={id}
                                    notes={notes}
                                    onSubmit={(form) => onSubmit(form)}
                                    deleteNotes={(id) => deleteNotes(id)}
                                    editNotes={(id) => editNotes(id)}
                                />
                            </Tab>
                            <Tab
                                eventKey="activities"
                                title={
                                    <>
                                        Activities{" "}
                                        {activities?.length !== 0 && (
                                            <span
                                                className={`tab-counter tab-length-${
                                                    activities?.length?.toString()
                                                        .length
                                                }`}
                                            >
                                                {activities?.length}{" "}
                                            </span>
                                        )}
                                    </>
                                }
                            >
                                <ActivitiesList
                                    activityData={activities}
                                    clientData={details}
                                />
                            </Tab>
                            <Tab eventKey="clientSheet" title="Documents">
                                <ClientSheets id={id} />
                            </Tab>
                        </Tabs>
                    </CardBody>
                </Card>
            </Container>
        </>
    );
};

export default ClientDetails;
