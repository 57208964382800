/* eslint-disable */
import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import { Row, Col, Card, CardBody, Container, Button, UncontrolledTooltip } from "reactstrap";
import { Link, useHistory } from "react-router-dom";
import TitleBreadCrumbs from "@/components/TitleBreadCrumbs";
import ApiServices from "@/utils/ApiServices";
import { successMsg } from "@/utils/message_herlper";
import { USERLEVELS } from "@/utils/constant";
import FileLoading from "./FileLoading";
import CardDocument from "./CardDocument";
import AngleArrowLeft from '@/images/angleArrowLeft';

const MultiUpload = () => {
    let { type } = useParams();
    const userData = JSON.parse(localStorage.getItem("userData"));
    if (userData?.userlevel === USERLEVELS.CLIENT ) {
        window.location.href = "/404";
    }
    const history = useHistory();

    const [files, setFiles] = useState([]);
    const [open, setOpen] = useState(false);
    const [state, setState] = useState([]);

    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [totalRows, setTotalRows] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [perPage, setPerpage] = useState(20);
    const page_ref = useRef(1);
    const saerch_ref = useRef(null);
    const [isSubmit, setSubmit] = useState(false);
    const messageRef = useRef(false);

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async (page) => {
        ApiServices.getMultiUploads({
            page: currentPage,
            perPage: perPage,
        })
            .then((result) => {
                setData((oldData) => [
                    ...oldData,
                    ...(result?.data?.data || []),
                ]);
                setTotalRows(result?.data?.total || 0);
            })
            .catch(function (error) {});
    };


    const upload = (e) => {
        messageRef.current = false;
        const files = e.target.files;
        if (files.length > 0) {
            setOpen(true);
            //setFiles(files);
            let files_list = [];
            for (let index = 0; index < files.length; index++) {
                files_list.push(files[index]);
                UploadService(files[index], index);
            }
            setFiles(files_list);
        }
    };

    const UploadService = (file, index) => {
        ApiServices.uploadMultiple(file, (event) => {
            setState((prevState) => ({
                ...prevState,
                [index]: Math.round((100 * event.loaded) / event.total),
            }));
        }).catch((error) => {
            console.log(error);
        });
    };

    useEffect(() => {
        let done = 0;
        for (var i = 0; i < files.length; i++) {
            if (state[i] && state[i] === 100) {
                done++;
            }
        }

        if (done !== 0 && done === files.length) {
            setTimeout(() => {
                if (!messageRef.current) {
                    successMsg("MULTI_UPLOAD_SAVE");
                    messageRef.current = true;
                    fetchData(1);
                    document.getElementById("fileid").value = null;
                }
            }, 1000);
            setTimeout(() => {
                setOpen(false);
                setFiles([]);
            }, 3000);
        }
    }, [state]);

    function groupBy(array, property) {
        var hash = {};
        for (var i = 0; i < array.length; i++) {
            if (!hash[array[i][property]]) hash[array[i][property]] = [];
            hash[array[i][property]].push(array[i]);
        }
        return hash;
    }

    const groupDocuments = [];

    const groupedByDocuments = groupBy(data, "account_number");

    const onDelete = (id) => {
        const oldData = [...data];
        const index = data.findIndex((element) => element.id === id);
        if (index !== 1) {
            oldData.splice(index, 1);
            deleteAction(id);
        }
        setData(oldData);
    };

    const deleteAction = (id) => {
        ApiServices.deleteMultiUploads({
            id: id,
        });
    };

    const removeDuplicate = (array, value) => {
        const result = [];
        const map = new Map();
        for (const item of array) {
            if (!map.has(item[value])) {
                map.set(item[value], true);
                result.push(item);
            }
        }
        return result;
    };

    Object.keys(groupedByDocuments).forEach(function (key) {
        if (key === "null") {
            const documents_array = {};
            const error_documents = groupedByDocuments[key];
            documents_array["files"] = removeDuplicate(error_documents, "id");
            groupDocuments.unshift({
                label: "Errors",
                documents: documents_array,
            });
        } else {
            const documents_array = {};
            const documents = groupedByDocuments[key];
            documents_array["files"] = removeDuplicate(documents, "id");
            groupDocuments.push({
                label: `${documents[0]?.name}`,
                labelProperty: `(Account Number:${documents[0]?.account_number})`,
                property_id: `${documents[0]?.property_id}`,
                documents: documents_array,
            });
        }
    });

    const loadMore = () => {
        const page = currentPage + 1;
        fetchData(page);
        setCurrentPage(page);
    };

    const viewDetails = (id) => {
        history.push({pathname: `/property/details/${id}`})
    }

    const viewDetailsClient = (id) => {
        history.push({pathname: `/client/details/${id}`})
    }

    

    let total_items = totalRows - data.length;

    return (
        <>
            <Container>
                <input
                    onChange={(e) => upload(e)}
                    id="fileid"
                    type="file"
                    multiple={true}
                    hidden
                />
                <TitleBreadCrumbs title="Multi-upload Documents">
                    <div className="page-breadcrumbs">
                        <Link to="/dashboard">Dashboard</Link>
                        <Link to="/properties">Properties</Link>
                        <span className="current_breadcrumbs">
                            Property Details
                        </span>
                    </div>
                    <div className="mb-4">
                        {type === "properties" ? (
                            <Link className="backto-link" to={`/properties`}>
                                <AngleArrowLeft color="#FFA647" />
                                Back to all properties
                            </Link>
                        ) : (
                            <Link className="backto-link" to={`/clients`}>
                                <AngleArrowLeft color="#FFA647" />
                                Back to all clients
                            </Link>
                        )}
                    </div>
                </TitleBreadCrumbs>
                <Card className="card-controlled">
                    <CardBody>
                        <Row>
                            <Col md={8} xs={12} className="mb-4">
                                <h3
                                    style={{
                                        color: "#383938",
                                        fontWeight: "bold",
                                    }}
                                >
                                    Documents
                                </h3>
                                <p>
                                    Please ensure that each filename includes
                                    the account number following format
                                    AC_1234567890.
                                </p>
                                <p className="mb-4">
                                    Otherwise, the documents will not be linked
                                    correctly.
                                </p>
                            </Col>
                            <Col md={4} xs={12}>
                                <Button
                                    color="primary"
                                    onClick={() =>
                                        document
                                            .getElementById("fileid")
                                            .click()
                                    }
                                    style={{ float: "right" }}
                                >
                                    Add Files
                                </Button>
                            </Col>
                        </Row>
                        <div>
                            {Array.isArray(groupDocuments) &&
                                groupDocuments.map((item, i) => { 
                                    const documents = item?.documents?.files;
                                    return (
                                        <Row
                                            key={i}
                                            className="pb-4 container-file"
                                        >
                                            <Col lg={12} className="mt-4">
                                                <h5 > 
                                                    <b className="cursor-pointer multi-upload-action"  title="View Client" onClick={()=> viewDetailsClient(item?.documents.files[0]?.client_id)} >{item?.label}</b>
                                                    <b className="cursor-pointer multi-upload-action ml-1"   title="View Property" onClick={()=> viewDetails(item?.property_id)} >{item?.labelProperty}</b>
                                                </h5>
                                            </Col>
                                            {Array.isArray(documents) &&
                                                documents.map((item2, i2) => {
                                                    return (
                                                        <Col
                                                            md={6}
                                                            xs={12}
                                                            key={i2}
                                                        >
                                                            <CardDocument
                                                                progress={0}
                                                                id={item2?.id}
                                                                name={
                                                                    item2?.filename
                                                                }
                                                                type={
                                                                    item2?.filetype
                                                                }
                                                                owner_name={
                                                                    item2?.owner_name
                                                                }
                                                                client={
                                                                    item2?.name
                                                                }
                                                                property_id={
                                                                    item2?.property_id
                                                                }
                                                                is_view={true}
                                                                onDelete={(
                                                                    id
                                                                ) =>
                                                                    onDelete(id)
                                                                }
                                                            />
                                                        </Col>
                                                    );
                                                })}
                                        </Row>
                                    );
                                })}
                        </div>
                        {total_items > 0 && (
                            <div className="text-center mt-4">
                                <Button
                                    color="secondary"
                                    onClick={() => loadMore()}
                                >
                                    Load More (
                                    <small>{total_items} items</small>)
                                </Button>
                            </div>
                        )}
                    </CardBody>
                </Card>
            </Container>
            <FileLoading isOpen={open} files={files} state={state} />
        </>
    );
};

export default MultiUpload;
