/* eslint-disable */
import React, { useEffect, useState } from "react";
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import { Link } from 'react-router-dom';
import TitleBreadCrumbs from '@/components/TitleBreadCrumbs'
import EditForm from './component/editForm';
import EditFormClient from './component/editFormClient';
import { USERLEVELS } from "@/utils/constant";
import ApiServices from "@/utils/ApiServices"
import { successMsg, globalError, formError } from "@/utils/message_herlper"

const AccountDetails = (props) => {
    const [details, setDetails] = useState(null);
    const userData = JSON.parse(localStorage.getItem('userData'));

    useEffect(() => { 
        clientDetails();
    }, [props]);

    const clientDetails = () => {
        ApiServices.clientsDetails(userData?.id).then((result) => {  
            setDetails(result.data);
        })
        .catch((error) => {
            formError();
        })
    }

    return (
        <Container>
            <TitleBreadCrumbs title={`${ userData.userlevel !== USERLEVELS.ADMIN ? 'Profile' : 'Your Account Info' }`} >
                { userData.userlevel === USERLEVELS.ADMIN && 
                <div className="page-breadcrumbs">
                   <Link to="/dashboard">Dashboard</Link>
                    <Link to="/settings">Settings</Link>
                    <span className="current_breadcrumbs">
                        Account
                    </span>
                </div>
                }
            </TitleBreadCrumbs>
                <Card>
                    <CardBody>
                        <Row>
                            <Col md={12} className="edit-county-wrap">
                            {/* <EditFormClient /> */}
                            { userData.userlevel ===  USERLEVELS.ADMIN ?
                                <EditForm details={details} />
                            :
                                <EditFormClient  details={details} />
                            } 
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
        </Container>
    );
}

export default AccountDetails;