/* eslint-disable */
import React, { useState, useEffect, useMemo, useRef } from "react";
import { Link, useHistory } from "react-router-dom";
import DataTable from "react-data-table-component";
import ApiServices from "@/utils/ApiServices";
import Empty from "@/components/Empty";
import { TABLE_SETTINGS } from "@/utils/constant";
import { Button, Row, Col, Spinner } from "reactstrap";
import CustomPagination from "@/components/CustomPagination";
import ToggleSwitch from "@/components/ToggleSwitch";
import { globalError } from "@/utils/message_herlper";
import { USERLEVELS } from "@/utils/constant";

const PropertyBilling = ({ id, isTotal, firstData }) => {
    const userData = JSON.parse(localStorage.getItem("userData"));
    const history = useHistory();
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(TABLE_SETTINGS.PAGINATION_PER_PAGE);
    const [currentPage, setCurrentPage] = useState(1);
    const [rowCount, setRowCount] = useState(0);
    const page_ref = useRef(1);
    const saerch_ref = useRef(null);
    const [isSubmit, setSubmit] = useState(false);
    const [loadingBilling, setLoadingBilling] = useState(true);

    const columns = useMemo(() => [
        {
            name: "Tax Year",
            selector: "year",
            sortable: false,
        },
        {
            name: "Noticed Value",
            selector: "noticedValue",
            sortable: false,
        },
        {
            name: "Assessed Value",
            selector: "assessed_value ",
            sortable: false,
        },
        {
            name: "Final Value",
            selector: "settledValue",
            sortable: false,
        },
        {
            name: "Amount of Reduction",
            selector: "reduction",
            sortable: false,
        },
        {
            name: "Tax Rate",
            selector: "taxrate",
            sortable: false,
        },
        {
            name: "Tax Savings",
            selector: "taxsavings",
            sortable: false,
        },
        {
            name: "Billing Percentage",
            selector: "billingPercentage",
            sortable: false,
        },
        {
            name: "Invoice Amount",
            selector: "invoiceamount",
            sortable: false,
        },
        {
            name: "Results",
            selector: "result",
            sortable: false,
        },
        {
            name: "Agent",
            selector: "agent_id",
            sortable: false,
        },
        {
            name: "Invoice paid",
            cell: (row) => (
                <>
                    <ToggleSwitch
                        id={`general-switch-${row.id}`}
                        checked={row.is_paid === 1 ? true : false}
                        onChange={() => invoicePaid(row)}
                    />
                </>
            ),
        },
        {
            cell: (row) => (
                <Button
                    color="secondary"
                    className="mb-0"
                    onClick={() => handlEdit(row)}
                >
                    Edit
                </Button>
            ),
        },
    ]);

    const handlEdit = (row) => {
        //window.location = `/property/edit-billing/${id}?billing_id=${row?.id}`
        history.push({ pathname: `/property/edit-billing/${id}/${row?.id}` });
    };

    const fetchData = async (page, size = perPage) => {
        ApiServices.propertyBillingByProperty({
            page: page,
            per_page: size,
            id: parseInt(id),
        })
            .then((result) => {
                if(Array.isArray(result?.data?.data)){
                    firstData(result?.data?.data[0]);
                }
                setLoadingBilling(false);
                setData(result?.data?.data);
                setRowCount(result.data.data.length);
                setTotalRows(result.data.total);
                setLoading(false);
                if (isTotal) {
                    isTotal(result.data.total);
                }
            })
            .catch(function (error) {});
    };

    useEffect(() => {
        if (id) {
            saerch_ref.current = id;
            fetchData(1);
        }
    }, [id]);

    const handlePageChange = (page) => {
        fetchData(page);
        setCurrentPage(page);
    };

    const handlePerRowsChange = async (newPerPage, page) => {
        fetchData(page, newPerPage);
        setPerPage(newPerPage);
    };

    const invoicePaid = (property) => {
        const newData = [...data];
        const prevIndex = data.findIndex((item) => item.id === property?.id);
        if (prevIndex !== -1) {
            newData[prevIndex].is_paid = property?.is_paid === 0 ? 1 : 0;
        }
        setData(newData);
        let is_paid = property?.is_paid === 0 ? 1 : 0;
        ApiServices.updatePropertyBillingPaid({
            is_paid: is_paid,
            id: property?.id,
        })
            .then((result) => {})
            .catch(function (error) {});
    };

    const exportReport = () => {
        setSubmit(true);
        ApiServices.propertyBillingExport({ id: id })
            .then((result) => {
                setTimeout(() => {
                    setSubmit(false);
                    const url = window.URL.createObjectURL(
                        new Blob([result.data])
                    );
                    const link = document.createElement("a");
                    link.href = url;
                    const fileName = `property-billing.csv`;
                    link.setAttribute("download", fileName);
                    document.body.appendChild(link);
                    link.click();
                    link.remove();
                }, 1000);
            })
            .catch((error) => {
                setSubmit(false);
                globalError();
            });
    };

    const dataTable = [];
    const list = data;

    for (let index = 0; index < list.length; index++) {
        dataTable.push({
            id: list[index].id,
            is_paid: list[index].is_paid,
            year: list[index].year,
            noticedValue: list[index].noticedValue
                ? `$${list[index].noticedValue
                      .toFixed(2)
                      .replace(/\d(?=(\d{3})+\.)/g, "$&,")}`
                : ``,
            assessed_value: list[index].assessed_value
                ? `$${list[index].assessed_value
                      .toFixed(2)
                      .replace(/\d(?=(\d{3})+\.)/g, "$&,")}`
                : ``,
            settledValue: list[index].settledValue
                ? `$${list[index].settledValue
                      .toFixed(2)
                      .replace(/\d(?=(\d{3})+\.)/g, "$&,")}`
                : ``,
            reduction: list[index].reduction
                ? `$${list[index].reduction
                      .toFixed(2)
                      .replace(/\d(?=(\d{3})+\.)/g, "$&,")}`
                : ``,
            taxrate: list[index].taxrate
                ? `${(list[index].taxrate * 100).toFixed(2)}%`
                : ``,
            taxsavings: list[index].taxsavings
                ? `$${list[index].taxsavings
                      .toFixed(2)
                      .replace(/\d(?=(\d{3})+\.)/g, "$&,")}`
                : ``,
            billingPercentage: `${list[index].billingPercentage}%`,
            invoiceamountOrig: list[index].is_paid,
            invoiceamount: list[index].invoiceamount
                ? `$${list[index].invoiceamount
                      .toFixed(2)
                      .replace(/\d(?=(\d{3})+\.)/g, "$&,")}`
                : ``,
            result: list[index].result_name,
            invoice_status: <span style={{ color: list[index].invoice_status === 'paid' ? '#4caf50' : '#f44336'}} >{list[index].invoice_status}</span>,
            agent_id: list[index].agent_fname
                ? list[index].agent_fname + " " + list[index].agent_lname
                : "",
            is_marketcut: list[index].is_marketcut,
            is_no_change: list[index].is_no_change,
        });
    }

    const updateMarketcut = (values) => {
        let old_data = [...data];
        const prevIndex = data.findIndex((item) => item.id === values?.id);
        if (prevIndex !== -1) {
            old_data[prevIndex].is_marketcut =
                values.is_marketcut === 0 ? 1 : 0;
            ApiServices.updatePropertyBillingMarket({
                is_marketcut: values.is_marketcut === 0 ? 1 : 0,
                id: values?.id,
            })
                .then((result) => {})
                .catch(function (error) {});
        }
        setData(old_data);
    };

    const updateNoChange = (values) => {
        let old_data = [...data];
        const prevIndex = data.findIndex((item) => item.id === values?.id);
        if (prevIndex !== -1) {
            old_data[prevIndex].is_no_change =
                values.is_no_change === 0 ? 1 : 0;
            ApiServices.updatePropertyNoChange({
                is_no_change: values.is_no_change === 0 ? 1 : 0,
                id: values?.id,
            })
                .then((result) => {})
                .catch(function (error) {});
        }
        setData(old_data);
    };

    return (
        <div className="activeProperties__wrapper pt-4">
            <Row>
                <Col md={6}>
                    <p className="tab-content-title">Property billing</p>
                </Col>
                <Col md={6} className="text-right">
                    {userData.userlevel === USERLEVELS.ADMIN && (
                        <div className="left-action">
                            <Link
                                to={`/property/add-billing/${id}`}
                                className="btn btn-primary"
                            >
                                Add Billing
                            </Link>
                            <Button
                                className="btn-info"
                                onClick={() => exportReport()}
                                disabled={isSubmit}
                            >
                                {" "}
                                {isSubmit ? (
                                    <Spinner
                                        size="sm"
                                        color="secondary"
                                        className="mr-2"
                                    />
                                ) : (
                                    ""
                                )}{" "}
                                Export Billings
                            </Button>
                        </div>
                    )}
                </Col>
                <Col md={12}>
                    {loadingBilling && <Spinner size="sm" color="warning" />}

                    <table style={{ width: "100%" }}>
                        <thead>
                            <tr>
                                <td>Tax Year</td>
                                <td>Noticed Value</td>
                                <td>Assessed Value</td>
                                <td>Final Value</td>
                                <td>Amount of Reduction</td>
                                <td>Tax Rate</td>
                                <td>Tax Savings</td>
                                <td>Billing Percentage</td>
                                <td>Invoice Amount</td>
                                <td>Results</td>
                                <td>Market Cut</td>
                                <td>No Change</td>
                                {/* <td>Agent</td>
                                <td>Invoice paid</td> */}
                                <td>&nbsp;</td>
                            </tr>
                        </thead>
                        <tbody>
                            {dataTable.map((i, k) => (
                                <tr>
                                    <td>{i.year}</td>
                                    <td>{i.noticedValue}</td>
                                    <td>{i.assessed_value}</td>
                                    <td>{i.settledValue}</td>
                                    <td>{i.reduction}</td>
                                    <td>{i.taxrate}</td>
                                    <td>{i.taxsavings}</td>
                                    <td>{i.billingPercentage}</td>
                                    <td>{i.invoiceamount}</td>
                                    <td>{i.result}</td>
                                    <td>
                                        {userData.userlevel ===
                                            USERLEVELS.ADMIN && (
                                                // i.year !== 2023 && (
                                                    <input
                                                        data-year={i.year}
                                                        name="client-info"
                                                        type="checkbox"
                                                        value="clientphone2"
                                                        style={{ width: 18 }}
                                                        defaultChecked={
                                                            i.is_marketcut === 0
                                                                ? false
                                                                : true
                                                        }
                                                        onChange={() =>
                                                            updateMarketcut(i)
                                                        }
                                                    />
                                                // )
                                        )}
                                    </td>
                                    <td>
                                        {userData.userlevel ===
                                            USERLEVELS.ADMIN && (
                                                <input
                                                    data-year={i.year}
                                                    name="client-info"
                                                    type="checkbox"
                                                    value="clientphone2"
                                                    style={{ width: 18 }}
                                                    defaultChecked={Boolean(i.is_no_change)}
                                                    onChange={() =>
                                                        updateNoChange(i)
                                                    }
                                                />
                                        )}
                                    </td>
                                    {/* <td>{i.invoice_status}</td> */}
                                    {/* <td>{i.user_status == 'A' ? i.agent_id : ''}</td>
                                    <td>                    <ToggleSwitch
                        id={`general-switch-${i.id}`}
                        checked={ i.is_paid === 1 ? true : false}
                        onChange={()=> invoicePaid(i)}
                    /></td> */}
                                    <td>
                                        {userData.userlevel ===
                                            USERLEVELS.ADMIN && (
                                            <Button
                                                color="secondary"
                                                className="mb-0"
                                                onClick={() => handlEdit(i)}
                                            >
                                                Edit
                                            </Button>
                                        )}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    <div style={{ display: "none" }}>
                        <DataTable
                            //title="Users"
                            columns={columns}
                            data={dataTable}
                            progressPending={loading}
                            pagination={false}
                            paginationServer
                            paginationTotalRows={totalRows}
                            paginationDefaultPage={currentPage}
                            striped={TABLE_SETTINGS.STRIPED}
                            highlightOnHover={true}
                            onChangeRowsPerPage={handlePerRowsChange}
                            onChangePage={handlePageChange}
                            paginationPerPage={
                                TABLE_SETTINGS.PAGINATION_PER_PAGE
                            }
                            paginationRowsPerPageOptions={
                                TABLE_SETTINGS.ROW_PER_PAGE
                            }
                            noDataComponent={<Empty />}
                        />
                        <CustomPagination
                            rowCount={rowCount}
                            currentPage={currentPage}
                            totalRows={totalRows}
                            perPage={perPage}
                            handlePageChange={handlePageChange}
                        />
                    </div>
                </Col>
            </Row>
        </div>
    );
};

export default PropertyBilling;
