/* eslint-disable */
import React, { useEffect, useState } from "react";
import { Link } from 'react-router-dom';
import { Card, CardBody, Col, Container, Row, Button, Spinner } from 'reactstrap';
import TitleBreadCrumbs from '@/components/TitleBreadCrumbs'
import {reduxForm } from "redux-form";
import { useForm } from "react-hook-form";
import { FormField, FormSelect } from "@/shared/components/FormField";
import ApiServices from "@/utils/ApiServices";
import _ from 'lodash';
import moment from 'moment';
import { successMsg, globalError, formError } from "@/utils/message_herlper"

const Acknowledgment = (props) => {
    const { handleSubmit, reset, control, errors, register, setValue, clearErrors } = useForm();
    const [isSubmit, setSubmit] = useState(false);
    const [settings, setSettings] = useState(null)

    useEffect(() => { 
        getSettings();
    }, [props]);

    const getSettings = () => {
        ApiServices.getSettings().then((result) => {
            if(result?.data?.length > 0){  console.log(result?.data[0]?.message)
                setValue('ack_message',result?.data[0]?.message)
            }
            setSettings(result?.data?.length > 0 ? result?.data[0] : [] )
        });
    };

    const onChangeAckStatus = (value) => {
        setValue('ack_status', value)
    }

    const onSubmit  = (form) => {  console.log('settings -->', settings)
        form.id = settings?.id
        form.ack_status = form.ack_status ? parseInt(form.ack_status) : !_.isNull(settings) && !_.isEmpty(settings) && settings.status === 1  ? 1 : 0 
        form.ack_message = form.ack_message ? form.ack_message : !_.isNull(settings) && !_.isEmpty(settings)  ? settings.message : ''
        setSubmit(true)
        ApiServices.editSettings(form)
        .then((result) => {  
            getSettings();
           setTimeout(()=> {
               setSubmit(false) 
                successMsg('SETTINGS_UPDATE');
           },1000)
        })
        .catch((error) => {
            setSubmit(false)
            if (error.response) {
                formError(error?.response?.data?.errors)
               return
            }
            globalError()
        });
    }


    return (
        <Container>
            <TitleBreadCrumbs title="Acknowledgment" >
                <div className="page-breadcrumbs">
                    <Link to="/dashboard">Dashboard</Link>
                    <Link to="/settings">Settings</Link>
                    <span className="current_breadcrumbs">Acknowledgment</span>
                </div>
            </TitleBreadCrumbs>
            <Card>
                <CardBody>
                    <Row>
                        <Col md={6}>
                            <div className="acknowledgment-status">
                                <h4>Current Acknowledgment Status:  {!_.isNull(settings) && !_.isEmpty(settings) && settings.status === 1  ? <span className="success">On</span> : <span className="error">Off</span> } </h4>
                                <h4>Check Sign Up Page: <span className="normal">RPT - SignUp</span></h4>
                                <h4>Last Changed By: <span className="none"> {!_.isNull(settings) && !_.isEmpty(settings)  ? `${settings.username}` : <span className="error">Not Set</span> }</span></h4>
                                <h4>Last Changed On: <span className="none">{!_.isNull(settings) && !_.isEmpty(settings)  ? moment(settings.updated_at).format('MMM DD, YYYY hh:mm A')  : <span className="error">Not Set</span> }</span></h4>
                            </div>
                            <hr />
                            <div className="edit-county-wrap">
                                <form className="userdetails-edit-form acknowledgment_form" onSubmit={handleSubmit(onSubmit)}>
                                    
                                    <div className="form__form-group">
                                    <span className="form__form-group-label">
                                        Acknowledgment Status:
                                        </span>
                                        <div className="form__form-group-field">
                                            <FormSelect
                                                name="ack_status"
                                                control={control}
                                                options={[{id: 1, name: 'ON'}, {id: 0, name: 'OFF'}]}
                                                isOject={true}
                                                objectName="name"
                                                defaultValue={!_.isNull(settings) && !_.isEmpty(settings) && settings.status === 1  ? 1 : 0 }
                                                {...register("ack_status")} 
                                                onChange={(value)=> onChangeAckStatus(value)}
                                            />
                                        </div>
                                    </div>
                                    <div className="form__form-group">
                                    <span className="form__form-group-label">
                                        Message
                                        </span>
                                        <div className="form__form-group-field">
                                            <FormField
                                                name="ack_message"
                                                component="textarea"
                                                control={control}
                                                errors={errors}
                                                placeholder="Message"
                                                defaultValue={!_.isNull(settings) && !_.isEmpty(settings)  ? settings?.message : '' }
                                                rows={4}
                                            />
                                        </div>
                                    </div>
                                    <div className="text-left">
                                        <Button
                                            type="submit"
                                            color="primary"
                                            disabled={isSubmit}
                                        >
                                            {isSubmit ? <Spinner size="sm" color="secondary" className="mr-2" /> : ''}
                                                Change Acknowledgment
                                        </Button>
                                    </div>
                                </form>
                            </div>
                        </Col>
                    </Row>
                </CardBody>
            </Card>
        </Container>
    )
}

export default reduxForm({
    form: "acknowledgment_status",
})(Acknowledgment);