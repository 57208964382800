/* eslint-disable */
import React, { useMemo, useState, useEffect, useRef } from "react";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import DataTable from "react-data-table-component";
import { Col, Row, Spinner, Button, Card, CardBody, } from "reactstrap";
import moment from "moment";
import { useHistory } from "react-router-dom";
import _ from "lodash";
import Empty from "@/components/Empty";
import CustomPagination from "@/components/CustomPagination";
import ApiServices from "@/utils/ApiServices";
import TableLoading from "@/components/TableLoading";
import { TABLE_SETTINGS } from "@/utils/constant";
import { USERLEVEL } from "@/utils/constant";
import CloseIcon from "@/images/closeIcon";
import { globalError, successMsgCustom } from "@/utils/message_herlper";
import ProfileRequestChanges from "@/containers/Client/components/profileRequestchanges";
import ProfileDeleteRequest from "@/containers/Client/components/ProfileDeleteRequest";
import PropertyRequestChanges from "@/containers/Properties/components/PropertyRequestChanges";
import PropertyDeleteRequest from "@/containers/Properties/components/PropertyDeleteRequest";

const PendingUpdates = ({ type }) => {
  const history = useHistory();

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pageLoad, setPageLoad] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(TABLE_SETTINGS.PAGINATION_PER_PAGE);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowCount, setRowCount] = useState(0);
  const page_ref = useRef(1);
  const [activeTab, setActiveTab] = useState('activities')

  useEffect(() => {
    fetchListing(1);
  }, [type]);

  // const fetchListing = async (page, size = perPage) => {
  //     page_ref.current = page;
  //     ApiServices.activitiesFilter({
  //         page: page,
  //         per_page: size,
  //         type: type,
  //     }).then((result) => {
  //             setPageLoad(true)
  //             setData(result?.data?.data);
  //             setTotalRows(result?.data?.total);
  //             setRowCount(result.data.data.length);
  //             setLoading(false);
  //             if(isTotal){
  //                 isTotal(result.data.total);
  //             }

  //         })
  //         .catch(function (error) {});
  // };

  const fetchListing = async (page, size = perPage) => {
    page_ref.current = page;
    ApiServices.viewAllPending({
      page: page,
      per_page: size,
      type: type,
    })
      .then((result) => {
        setData(result?.data?.data);
        setTotalRows(result?.data?.total);
        setRowCount(result.data.data.length);
        setLoading(false);
        setPageLoad(true);
        if (isTotal) {
          isTotal(result.data.total);
        }
      })
      .catch(function (error) {});
  };

  //Dynamic data soon
  const columns = useMemo(() => [
    {
      name: "",
      selector: "activitydata",
      sortable: false,
    },
  ]);

  const handlePageChange = (page) => {
    fetchListing(page);
    setCurrentPage(page);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    fetchListing(page, newPerPage);
    setPerPage(newPerPage);
  };

  const property_details = (id) => {
    history.push({ pathname: `/property/details/${id}` });
  };

  const client_details = (id) => {
    history.push({ pathname: `/client/details/${id}/0` });
  };

  const delete_activity = (id) => {
    ApiServices.deleteActivity({
      id: id,
    })
      .then((result) => {
        const newData = [...data];
        const prevIndex = data.findIndex((item) => item.id === id);
        if (prevIndex !== -1) {
          newData.splice(prevIndex, 1);
          setData(newData);
          setTotalRows(totalRows - 1);
        }
        successMsgCustom("Activity successfully deleted.");
      })
      .catch(function (error) {
        globalError();
      });
  };

  const dataTable = [];
  const list = data;
  for (let index = 0; index < list.length; index++) {
    dataTable.push({
      activitydata: (
        <>
          <div className="text-right btn-close" title="Delete">
            <span
              className=" mt-4"
              onClick={() => delete_activity(list[index].id)}
            >
              <CloseIcon color="#FFA647" />
            </span>
          </div>
          <Row>
            <Col md={6}>
              <p className="activity_user">
                <b>
                  {list[index].user_type &&
                  list[index].user_type === USERLEVEL.ADMIN
                    ? "Admin:"
                    : "Client:"}{" "}
                  {list[index].client_first_name ||
                    list[index].client_first_name ||
                    list[index].first_name}{" "}
                  {list[index].client_last_name ||
                    list[index].client_last_name ||
                    list[index].last_name}
                </b>
              </p>
              {list[index].type && list[index].type === "profile_change" && (
                <ProfileRequestChanges
                  clientData={list[index].user_id}
                  requestChangesData={list[index]}
                />
              )}
              {list[index].type && list[index].type === "property_change" && (
                <PropertyRequestChanges
                  clientData={list[index].user_id}
                  requestChangesData={list[index]}
                />
              )}
              {list[index].type && list[index].type === "delete_account" && (
                <ProfileDeleteRequest
                  clientData={list[index].user_id}
                  requestChangesData={list[index]}
                />
              )}
              {list[index].type && list[index].type === "delete_property" && (
                <PropertyDeleteRequest
                  clientData={list[index].user_id}
                  requestChangesData={list[index]}
                />
              )}

              {list[index].details && (
                <>
                  <p className="color-secondary">{list[index].details}</p>
                  {!_.isNull(list[index].fields) &&
                    !_.isEmpty(list[index].fields) &&
                    _.isArray(list[index].fields) &&
                    list[index].fields &&
                    JSON.parse(list[index].fields).map((obj2, i2) => {
                      var field = null;
                      for (var key in obj2) {
                        field = key;
                      }
                      return (
                        <>
                          <p className="activity-details-fields">
                            <span>{field}</span> from{" "}
                            <span>{obj2[field]?.old}</span> to{" "}
                            <span>{obj2[field]?.new}</span>
                          </p>
                        </>
                      );
                    })}
                </>
              )}
            </Col>
            <Col md={6} className="text-right">
              <p>{moment(list[index].created_at).format("MMM DD, h:mm A")}</p>
              {list[index].type === "aoa_ready" ||
              list[index].type === "aoa_not_ready" ||
              list[index].type === "aoa_not_ready" ||
              list[index].type === "property_inactive" ? (
                <Button
                  color="secondary"
                  className="mt-4 float-right"
                  onClick={() => property_details(list[index].property_id)}
                >
                  Details
                </Button>
              ) : (
                ""
              )}

              {list[index].type === "register-client" ||
              list[index].type === "activated_client" ||
              list[index].type === "deleted_client" ? (
                <Button
                  color="secondary"
                  className="mt-4 float-right"
                  onClick={() => client_details(list[index].client_id)}
                >
                  Details
                </Button>
              ) : (
                ""
              )}
              {list[index].type === "" ? (
                <Button
                  color="secondary"
                  className="mt-4 float-right"
                  onClick={() => client_details(list[index].client_id)}
                >
                  Details
                </Button>
              ) : (
                ""
              )}
            </Col>
          </Row>
        </>
      ),
    });
  }

  return (
    <>
      <div className="activity-table-wrapper nopager mt-4">
        <DataTable
        columns={columns}
        data={dataTable}
        progressPending={loading}
        pagination={false}
        progressComponent={<TableLoading />}
        paginationServer
        paginationTotalRows={totalRows}
        paginationDefaultPage={currentPage}
        striped={TABLE_SETTINGS.STRIPED}
        highlightOnHover={true}
        onChangeRowsPerPage={handlePerRowsChange}
        onChangePage={handlePageChange}
        onSelectedRowsChange={({ selectedRows }) =>
            console.log(selectedRows)
        }
        paginationPerPage={TABLE_SETTINGS.PAGINATION_PER_PAGE}
        paginationRowsPerPageOptions={TABLE_SETTINGS.ROW_PER_PAGE}
        noDataComponent={<Empty />}
        />
        <CustomPagination
        rowCount={rowCount}
        currentPage={currentPage}
        totalRows={totalRows}
        perPage={TABLE_SETTINGS.PAGINATION_PER_PAGE}
        handlePageChange={handlePageChange}
        />
    </div>
    </>
  );
};
export default PendingUpdates;
