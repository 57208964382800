/* eslint-disable */
import React, { useEffect, useState } from "react";
import {
    Card,
    CardBody,
    Col,
    Container,
    Row,
} from "reactstrap"
import { Link, useHistory, useParams } from "react-router-dom"
import "./ccreate-style.css";
import ApiServices from "../../utils/ApiServices"
import { successMsg, globalError, formError } from "../../utils/message_herlper"
import EditForm from './components/EditForm'
import TitleBreadCrumbs from '@/components/TitleBreadCrumbs'

const ClientCreate = () => {
    let { id } = useParams(); 
    const [showPassword, setShowPassword] = useState(false);
    const history = useHistory();
    const [isSubmit, setSubmit] = useState(false);
    const [errors, seErrors] = useState([]);
    const [details, setDetails] = useState(null);
    const [county, setCounty] = useState([]);

    useEffect(() => { 
        clientDetails();
        getCounty();
    }, [id]);

    const clientDetails = () => {
        ApiServices.clientsDetails(id).then((result) => {  
            setDetails(result.data);
        })
        .catch((error) => {
            formError();
        })
    }

    const onSubmit = (data) => {
        setSubmit(true)
        data.id = id
        ApiServices.editClient(data)
        .then((result) => {  console.log('result', result.data);
           setTimeout(() =>{
                successMsg('CLIENT_EDIT_SUCCESS');
                history.push({pathname: `/client/details/${id}`});
           },1000)
        })
        .catch((error) => {
            setSubmit(false)
            if (error.response) {
                console.log(error.response.data.errors)
                seErrors(error?.response?.data?.errors)
                formError(error?.response?.data?.errors)
               return
            }
            globalError()
        });
    }

    const getCounty = () =>{
        ApiServices.county()
        .then((result) => {   
            setCounty(result.data);
        })
        .catch(function (error) {
        });
    }
    
    const Oncancel = () => {
        history.push({pathname: `/client/details/${id}`});
    }
   
    
    return (
        <Container className="client-registration-container">
            <TitleBreadCrumbs title="Client" >
                <div className="page-breadcrumbs">
                    <Link to="/dashboard">Dashboard</Link>
                    <Link to={`/client/details/${id}`}>Client</Link>
                    <span className="current_breadcrumbs">
                        Edit
                    </span>
                </div>
            </TitleBreadCrumbs>
            <Row>
                <Col md={12}>
                    <Card>
                        <CardBody className="client-registration-form-container">
                            <EditForm details={details} county={county} onSubmit={(data)=> onSubmit(data)}  Oncancel={(data)=> Oncancel()}  isSubmit={isSubmit} errorList={errors} />
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
};


export default ClientCreate;

