/* eslint-disable */
import React, { useState } from 'react';
import Tabs from 'react-bootstrap/Tabs'
import Tab from 'react-bootstrap/Tab'
import PropertiesTable from '@/components/PropertiesTable'
import { USERLEVELS } from '@/utils/constant'
const userData = JSON.parse(localStorage.getItem('userData'));


const ClientSideProperties = ({ setActiveCount }) => {
    const [countAll,setCountAll] = useState(0)
    const [countAll2,setCountAll2] = useState(0)

    return (
        <PropertiesTable  title="Properties" status="A" is_new={false} isTotal={(value)=> { setCountAll(value); setActiveCount(value) }} />
        // <Tabs defaultActiveKey="active" className="mb-3 datatable-tab-container">
        //     <Tab eventKey="active" title={<>Active {countAll !== 0 && <span className={`tab-counter tab-length-${countAll?.toString().length}`}>{`${countAll}`}</span> }</>}>
        //         <PropertiesTable  title="Properties" status="A" is_new={false} isTotal={(value)=> { setCountAll(value); setActiveCount(value) }} />
        //     </Tab>
        //     { userData.userlevel !== USERLEVELS.CLIENT ?
        //     <Tab eventKey="inactive" title={<>Inactive {countAll2 !== 0 && <span className={`tab-counter tab-length-${countAll2?.toString().length}`}>{`${countAll2}`}</span> }</>}>
        //         <PropertiesTable  title="Inactive Properties" status="I" is_new={false} isTotal={(value)=> setCountAll2(value)} />
        //     </Tab> 
        //     :''}
        // </Tabs>
    )
}

export default ClientSideProperties;