/* eslint-disable */
import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import Modal from 'react-bootstrap/Modal'
import { Button, Spinner } from 'reactstrap'
import CloseIcon from '@/images/closeIcon'
import { useForm } from "react-hook-form";
import { FormField } from "@/shared/components/FormField";
import LoadingIcon from 'mdi-react/LoadingIcon';

const ModalEditNotes = ({open, close, Onsubmit, values, isSubmit, onSubmit}) => {   
    const { handleSubmit, control, errors, setValue} = useForm();
     const [aoaAdress,setAoaAdress] = useState(false)

     useEffect(() => { console.log(values?.notes)
        if(values?.notes) 
           setValue('notes', values?.notes)
     },[values])


    const expandClass = classNames({
        icon: true,
        expand: true,
        'expand--load': isSubmit,
    });

    

    const onDataSubmit  = (event) =>{
        onSubmit(event)  
    }
     
    return(
        <>
            <Modal show={open} onHide={close}  size="md"    centered >
                <Button onClick={close} className="modalconfirm-closebtn"><CloseIcon color="#383938" /></Button>
                <Modal.Body className="text-center" >
                    <h3 className="mt-3 mb-3"><b>Edit Notes</b></h3>
                    <form onSubmit={handleSubmit(onSubmit)} >
                    <FormField
                        className="notes-text"
                        name="notes"
                        component="textarea"
                        control={control}
                        errors={errors}
                        placeholder="Write here.."
                        rows={4}
                        rules={{
                            required: "This is required field",
                        }}
                        // onChange={handleChange}
                    />
                    {errors?.notes && <span className="form__form-group-error">{errors?.notes?.message}</span>}

                    <div className="pt-2">
                        <Button type="submit" color="primary" className={isSubmit ? expandClass : ''} disabled={isSubmit}>
                        {isSubmit ? <LoadingIcon /> : ''}
                           Submit
                        </Button>
                    </div>
                    </form>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default ModalEditNotes;