/* eslint-disable */
import React, { useState, useEffect, useRef } from 'react';
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';
import { 
   CardBody, Container
} from 'reactstrap';

import { Link, useLocation, useHistory } from 'react-router-dom';
import ClientResult from './ClientResult'
import PropertyResult from './PropertyResult'
import TitleBreadCrumbs from '@/components/TitleBreadCrumbs'

const SearchResults = (props) => {
    const location = useLocation()
    const history = useHistory()
    const [params, setParams] = useState(null)
    const typeRef = useRef(null)

    useEffect(() => {  
        let {type, keyword, active} =  props?.match?.params
        typeRef.current = type
        setParams({
            type: type,
            keyword: keyword,
            active: active,
        })
    }, [location]);
    
    return (
        <Container>
            <TitleBreadCrumbs title={`${typeRef.current === 'all' ? 'Properties & Client': ''} ${typeRef.current === 'client' ? 'Client': ''} ${typeRef.current === 'property' ? 'Properties': ''} Search Results`} >
                <div className="page-breadcrumbs">
                   <Link to="/dashboard">Dashboard</Link>
                    <span className="current_breadcrumbs">Search Results</span>
                </div>
            </TitleBreadCrumbs>
            {typeRef.current === 'property' ? 
            <>
             <CardBody>
                 <PropertyResult  params={params} />
            </CardBody>
            </>
            :''}
            {typeRef.current === 'client' ? 
            <>
             <CardBody>
                 <ClientResult  params={params} />
            </CardBody>
            </>
            :''}
            {typeRef.current === 'all' ? 
            <>
            <CardBody>
                 <PropertyResult  params={params} />
            </CardBody>
             <CardBody className="mt-4">
                 <ClientResult  params={params} />
            </CardBody>
            </>
            :''}
           
           
        </Container>
    );
}


export default withRouter(SearchResults);