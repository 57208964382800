/* eslint-disable */
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
    Card,
    CardBody,
    Col,
    Container,
    Row,
    Button,
    Spinner,
} from "reactstrap";
import TitleBreadCrumbs from "@/components/TitleBreadCrumbs";
import { reduxForm } from "redux-form";
import { useForm } from "react-hook-form";
import { FormField, FormSelect } from "@/shared/components/FormField";
import ApiServices from "@/utils/ApiServices";
import _ from "lodash";
import moment from "moment";
import { successMsg, globalError, formError } from "@/utils/message_herlper";

const RegistrationSettings = (props) => {
    const {
        handleSubmit,
        reset,
        control,
        errors,
        register,
        setValue,
        clearErrors,
    } = useForm();
    const [isSubmit, setSubmit] = useState(false);
    const [isRegister, setRegister] = useState(false);
    const [title, setTitle] = useState("");
    const [message, setMessage] = useState("");

    useEffect(() => {
        getSettings();
    }, [props]);

    const getSettings = () => {
        ApiServices.getRegistrationSettings().then((result) => {
            let value = false;
            if (result?.data && result?.data?.enable_register?.key_value === "true") {
                value = true;
            }
            setRegister(value);

            setTitle(result?.data?.placeholder_title?.key_value);
            setValue('placeholder_title', result?.data?.placeholder_title?.key_value);
            
            setMessage(result?.data?.placeholder_message?.key_value);
            setValue('placeholder_message', result?.data?.placeholder_message?.key_value);
        });
    };

    const onChangeAckStatus = (value) => {
        setValue("ack_status", value);
    };

    const onSubmit = (form) => {
        console.log(form)
        form.ack_status = parseInt(form.ack_status) ? "true" : "false";
        setSubmit(true);
        ApiServices.updateRegistrationSettings(form)
            .then((result) => {
                setTimeout(() => {
                    setSubmit(false);
                    successMsg("SETTINGS_REGISTER");
                }, 1000);
            })
            .catch((error) => {
                setSubmit(false);
                if (error.response) {
                    formError(error?.response?.data?.errors);
                    return;
                }
                globalError();
            });
    };

    const onTextChange = (e) => {
        setValue(e.target.name, e.target.value);
    };

    console.log(errors);

    return (
        <Container>
            <TitleBreadCrumbs title="Registration">
                <div className="page-breadcrumbs">
                    <Link to="/dashboard">Dashboard</Link>
                    <Link to="/settings">Settings</Link>
                    <span className="current_breadcrumbs">Registration</span>
                </div>
            </TitleBreadCrumbs>
            <Card>
                <CardBody>
                    <Row>
                        <Col md={6}>
                            <div className="edit-county-wrap">
                                <form
                                    className="userdetails-edit-form acknowledgment_form"
                                    onSubmit={handleSubmit(onSubmit)}
                                >
                                    <div className="form__form-group">
                                        <span className="form__form-group-label">
                                            Registration Status:
                                        </span>
                                        <div className="form__form-group-field">
                                            <FormSelect
                                                name="ack_status"
                                                control={control}
                                                options={[
                                                    { id: 1, name: "Enable" },
                                                    { id: 0, name: "Disable" },
                                                ]}
                                                isOject={true}
                                                objectName="name"
                                                defaultValue={
                                                    isRegister
                                                        ? 1
                                                        : 0
                                                }
                                                {...register("ack_status")}
                                                onChange={(value) =>
                                                    onChangeAckStatus(value)
                                                }
                                            />
                                        </div>
                                    </div>
                                    <div className="form__form-group">
                                        <span className="form__form-group-label">
                                            Placeholder Title:
                                        </span>
                                        <div className="form__form-group-field">
                                            <div style={{flex: 1}}>
                                                <input 
                                                    name="placeholder_title" 
                                                    placeholder="Title" 
                                                    {...register("placeholder_title", { required: true })} 
                                                    onChange={onTextChange}
                                                    defaultValue={title}
                                                />
                                                {errors.placeholder_title && (
                                                    <div style={{color: 'red' }}>Required</div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form__form-group">
                                        <span className="form__form-group-label">
                                            Placeholder Message:
                                        </span>
                                        <div className="form__form-group-field">
                                            <div style={{flex: 1}}>
                                                <textarea 
                                                    style={{minHeight: 120, fontSize: 13}} 
                                                    placeholder="Message" 
                                                    name="placeholder_message" 
                                                    {...register("placeholder_message", { required: true })} onChange={onTextChange} 
                                                    defaultValue={message}
                                                />
                                                {errors.placeholder_message && (
                                                    <div style={{color: 'red' }}>Required</div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="text-left">
                                        <Button
                                            type="submit"
                                            color="primary"
                                            disabled={isSubmit}
                                        >
                                            {isSubmit ? (
                                                <Spinner
                                                    size="sm"
                                                    color="secondary"
                                                    className="mr-2"
                                                />
                                            ) : (
                                                ""
                                            )}
                                            Change Status
                                        </Button>
                                    </div>
                                </form>
                            </div>
                        </Col>
                    </Row>
                </CardBody>
            </Card>
        </Container>
    );
};

export default RegistrationSettings;
