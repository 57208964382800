/* eslint-disable */
import React, {useEffect, useState, useRef, useMemo} from 'react';
import { 
    Card, CardBody, Col, Container, Row
  } from 'reactstrap';
import queryString from 'query-string';
import {  reduxForm } from 'redux-form';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { Button } from 'reactstrap';
import Select from 'react-select'
import TitleBreadCrumbs from '@/components/TitleBreadCrumbs'
import ApiServices from "@/utils/ApiServices";
import { TABLE_SETTINGS, SELECT_STYLES, API } from "@/utils/constant";
import DataTable from "react-data-table-component";
import TableLoading from "@/components/TableLoading";
import Empty from "@/components/Empty";
import { globalError } from "@/utils/message_herlper";

const customStyles = {
    control: (base, state) => ({
        ...base,
        color: state.isSelected ? 'red' : '#009adc',
        boxShadow: 'none',
        '&:hover': {
            border: '1px solid #009adc',
        },
    }),
    option: (styles, state) => ({
        ...styles,
        color: state.isSelected ? "#009adc" : styles.color,
        backgroundColor: state.isSelected ? "#009adc" : styles.color,
        borderBottom: "1px solid rgba(0, 0, 0, 0.125)",
        "&:hover": {
          color: "#FFF",
          backgroundColor: "#009adc"
        }
      }),
  };

const BalanceReport = (props) => {
    const history = useHistory();
    const location = useLocation()
    const currentYear = (new Date()).getFullYear()
    const range = (start, stop, step) => Array.from({ length: (stop - start) / step + 1}, (_, i) => start + (i * step))
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [totalRows, setTotalRows] = useState(0);
    const [show, setShow] = useState(false);
    const [perPage, setPerPage] = useState(TABLE_SETTINGS.PAGINATION_PER_PAGE);
    const [currentPage, setCurrentPage] = useState(1);
    const [years, setYears] = useState(range(currentYear, currentYear - 8, -1)?.reverse())
    const [resultList, setResultList] = useState([])
    const [agentList, setAgentList] = useState([])
    const [propertyType, sePropertyType] = useState([]);
    const [exemptionSeledted, setExemptionSeledted] = useState([])
    const [yearSeledted, setYearSeledted] = useState([])
    const [porpertySeledted, setPropertySeledted] = useState([])
    const [billingSeledted, setBillingSeledted] = useState([])
    const [resultSeledted, setRsultSeledted] = useState([])
    const [exemptions, setExemptions] = useState([]);
    const yearRef = useRef(null)
    const propertyRef = useRef(null)
    const billingRef = useRef(null)
    const resultRef = useRef(null)
    const  keyRef = useRef(null)
    const  exemptionRef = useRef(null)
    const dataTable = [];
    const list = data;


    useEffect(() => { 
        balanceList()
    }, []); 

    const balanceList = () =>{
        ApiServices.outstandingBalance()
        .then((result) => { 
            setData(result.data)
        })
    }


    const exportReport = (type) => {
        let { key } =  props?.match?.params
        window.open(`${API}report/outstanding-export/${type}`, '_blank');
    };


    let TaxYearList = []
    for (let index = 0; index < years.length; index++) {
        TaxYearList.push({ value: years[index], label: years[index] })
    }
    
    const columns = useMemo(() => [
        {
            name: "County Name ",
            selector: "client_first_name",
            sortable: false,
            center: true,
        },
        {
            name: "County Invoice",
            selector: "total_invoice",
            sortable: false,
            right: true,
        }
    ]);

    if(Array.isArray(list)){
        for (let index = 0; index < list.length; index++) {
            dataTable.push({
                client_first_name: list[index].client_first_name +' '+ list[index].client_last_name,
                total_invoice: `$${list[index].total_invoice?.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}`,
            });
        }
    }

    
    return (
        <Container>
            <TitleBreadCrumbs title="Outstanding Balance" >
                <div className="page-breadcrumbs">
                <Link to="/dashboard">Home</Link>
                <Link to="#">Reports</Link>
                {!show && (
                    <>
                        <span className="current_breadcrumbs">Outstanding Balance Reports </span>
                    </>
                )}
                {show && (
                    <>
                        <Link onClick={() => goBack()} to="#">
                           Outstanding Balance Balance
                        </Link>
                        <span className="current_breadcrumbs">Result </span>
                    </>
                )}
                </div>
            </TitleBreadCrumbs>
            <Row>
                <Col md={12}>
                    <Card>
                        <CardBody className="filter-invoice-report">
                            <Row>
                            <Col md={12} className="invoiced-report-result">
                                        <table style={{'width': '100%'}}>
                                        <thead>
                                            <tr>
                                                <td>Client Name</td>
                                                <td style={{ width: 200, textAlign: 'right' }}>Balance</td>
                                                {/* <td>Details</td> */}
                                            </tr>
                                        </thead>
                                        <tbody>
                                        {
                                            dataTable.map((i,k) => 
                                                <tr>
                                                    <td>{i.client_first_name}</td>
                                                    <td style={{ width: 200, textAlign: 'right' }}>{i.total_invoice}</td>
                                                    {/* <td><Button  className="my-0 primary-2 white-nowrap" color="primary"  onClick={()=> handlView(i)}>View Properties</Button></td> */}
                                                </tr>
                                            )
                                        }
                                        </tbody>
                                        </table> 
                                        <div style={{ textAlign: 'center', marginTop: 20 }}>
                                        <Button
                                                onClick={() =>
                                                    exportReport('excel')
                                                }
                                            >
                                                
                                                Export to Excel
                                            </Button>
                                            <Button
                                                onClick={() =>
                                                    exportReport('pdf')
                                                }
                                            >
                                                Export to PDF
                                            </Button>           

                                        </div>
                                    </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
}

export default reduxForm({
    form: 'filter_invoice_report',
})(BalanceReport);