/* eslint-disable */
import React, { useState, useEffect, useMemo, useRef } from "react";
import { Link, useHistory } from 'react-router-dom';
import DataTable from "react-data-table-component";
import ApiServices from "@/utils/ApiServices";
import Empty from "@/components/Empty";
import { TABLE_SETTINGS } from "@/utils/constant";
import {  globalError } from "@/utils/message_herlper"
import TableLoading from '@/components/TableLoading'

import CustomPagination from '@/components/CustomPagination'


const PropertyTypeTable = (props) => {     
    const history = useHistory();
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [rowCount, setRowCount] = useState(0);
    const [perPage, setPerPage] = useState(TABLE_SETTINGS.PAGINATION_PER_PAGE);
    const [currentPage, setCurrentPage] = useState(1);

    const columns = useMemo(
        () => [
            {
                name: "Property Type",
                selector: "type",
                sortable: false,
            },
            {
                name: "Value",
                selector: "value",
                sortable: false,
            },
            {
                width: '70px',
                center: true,
                cell: (row) => (
                    <button className="btn btn-primary my-1" onClick={()=> handlEdit(row)}>Edit</button>
                ),
            },
        ],
    );

    const handlEdit = (row) => {
        history.push({ pathname: `/property-type-form/update/${row?.id}`})
   } 
    
    const fetchData = async (page, size = perPage, ) => {  
        ApiServices.propertyTypeList({'page' : page, 'per_page': size})
        .then((result) => { 
            setData(result?.data?.data)
            setTotalRows(result.data.total)
            setRowCount(result.data.data.length);
            setLoading(false)
        })
        .catch(function (error) {
            setLoading(false)
            globalError();
        });
    };

    useEffect(() => {
        fetchData(1);
    }, [props]);
    

   
    const handlePageChange = (page) => {
        fetchData(page);
        setCurrentPage(page);
    };

    const handlePerRowsChange = async (newPerPage, page) => { 
        fetchData(page, newPerPage);
        setPerPage(newPerPage);
    };


    const dataTable = []
    const list = data || []

    for (let index = 0; index < list.length; index++) {
        dataTable.push({
           id: list[index].id,
           type: list[index].type,
           value: list[index].value,
        })
        
    }

    return (
        <div className="activeProperties__wrapper">
            {/* <Link className="btn btn-primary create-county-btn" to="/property-type-form?type=create">Create </Link> */}
            <DataTable
                columns={columns}
                data={dataTable}
                progressPending={loading}
                progressComponent={<TableLoading/>}
                pagination={false}
                paginationServer
                paginationTotalRows={totalRows}
                paginationDefaultPage={currentPage}
                striped={TABLE_SETTINGS.STRIPED}
                highlightOnHover={true}
                onChangeRowsPerPage={handlePerRowsChange}
                onChangePage={handlePageChange}
                paginationPerPage={TABLE_SETTINGS.PAGINATION_PER_PAGE}
                paginationRowsPerPageOptions={TABLE_SETTINGS.ROW_PER_PAGE}
                noDataComponent={<Empty />}
            />
            <CustomPagination rowCount={rowCount} currentPage={currentPage} totalRows={totalRows} perPage={TABLE_SETTINGS.PAGINATION_PER_PAGE} handlePageChange={handlePageChange} />
       </div>
    );
};

export default PropertyTypeTable;
