/* eslint-disable */
import React, { useState, useEffect } from 'react'
import Modal from 'react-bootstrap/Modal'
import { Button, Spinner } from 'reactstrap'
import TagOutlineIcon from 'mdi-react/TagOutlineIcon'

const OutstadingBalance = ({open, close, outStanding, action, isSubmit}) => {   
    const[value,seValue] = useState('N')

    useEffect(() => {
        seValue(outStanding ? 'Y': 'N')
    },[outStanding])
    
    return(
        <>
            <Modal className="deleteClientModal" show={open} onHide={close}   size="md" centered>
                <Modal.Body className="text-center">
                    <TagOutlineIcon />
                    <h3 className="mt-4 mb-0"><b>Outstanding Balance</b></h3>
                    <select onChange={(e) => seValue(e.target.value)}   >
                        <option value="N" selected={!outStanding} >No</option>
                        <option value="Y" selected={outStanding}>Yes</option>
                    </select>
                    <Button className="deleteClient-btn" onClick={()=> action(value)} >  {isSubmit ? <Spinner size="sm" color="secondary" className="mr-2" /> : ''} Change Outstanding Balance</Button>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default OutstadingBalance;