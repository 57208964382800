/* eslint-disable */
import React from 'react';
import { 
    Card, CardBody, Col, Container, Row
  } from 'reactstrap';
import { Link } from 'react-router-dom';
import PropertyTypeTable from './PropertyTypeTable'
import TitleBreadCrumbs from '@/components/TitleBreadCrumbs'

const PropertyTypeList = () => {
  
    return (
        <Container>
            <TitleBreadCrumbs title="List of Property Type" link={`property-type-form/create`} btn="Create" >
                <div className="page-breadcrumbs">
                <Link to="/dashboard">Dashboard</Link>
                <Link to="/settings">Settings</Link>
                    <span className="current_breadcrumbs">Property Type List</span>
                </div>
            </TitleBreadCrumbs>
            <Row>
                <Col md={12}>
                    <Card class="card-controlled">
                        <CardBody class="cardbody-controlled">
                            <div className="">
                                <PropertyTypeTable />
                            </div>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
}

export default PropertyTypeList;