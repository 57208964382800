/* eslint-disable */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useParams } from 'react-router-dom';
import {
    Button,
    Spinner,
    Tooltip,
    Alert,
    Row,
    Col
} from "reactstrap";
import { useTranslation } from "react-i18next";
import { reduxForm } from "redux-form";
import { useForm } from "react-hook-form";
import { FormField, FormSelect } from "@/shared/components/FormField";
import {  zipPattern } from '@/shared/helpers';
import AccountOutlineIcon from "mdi-react/AccountIcon";
import MapMarkerIcon from "mdi-react/MapMarkerIcon";
import CancelIcon from "mdi-react/CancelIcon";
import PaperClip from '@/images/paperClip';
import CameraIcon from '@/images/cameraIcon';
import InfoIcon from '@/images/infoIcon'
import { successMsg, globalError, formError } from "@/utils/message_herlper"
import ModalUpload from '@/components/ModalDocuUpload'
import ApiServices from "@/utils/ApiServices";
import PropertyDescriptionDocs from '@/components/propertyDescriptionDoc'
import { USERLEVELS } from '@/utils/constant'
const userData = JSON.parse(localStorage.getItem('userData'));



const EditFormClient = ({ onSubmit, onCancel,  isSubmit, errorList, propertyType, county, details, exemptions, }) => {   
    let { id } = useParams()
    const { t } = useTranslation("common");
    const { handleSubmit, reset, control, errors, register, setValue, clearErrors } = useForm();
    const[emailError, setEmailError] = useState([]);
    const[usernameError, setUsernameError] = useState([]);
    const [propertyTypes, sePropertyType] = useState([]);
    const [showPassword, setShowPassword] = useState(false);
    const [currCounty, setCurrCounty] = useState(null);
    const [tooltipOpen, setTooltipOpen] = useState(false);
    const [tooltipOpen2, setTooltipOpen2] = useState(false);
    const [purchasedTwoYears, setPurchasedTwoYears] = useState(false);
    const [majorChanges, setMajorChanges] = useState(false);
    const [remodelUpgrades, setRemodelUpgrades] = useState(false);
    const [devaluesProperty, setDevaluesProperty] = useState(false);
    const userData = JSON.parse(localStorage.getItem('userData'));

    const toggle = () => setTooltipOpen(!tooltipOpen);
    const toggle2 = () => setTooltipOpen2(!tooltipOpen2);
    const [accountnumner, setAccountNumber] = useState('');

    useEffect(() => { 
        setEmailError(errorList?.email ? errorList?.email : [])
        setUsernameError(errorList?.username ? errorList?.username  : [] )
    },[errorList]);

    useEffect(() => {  
        setValue('owner_name', details?.owner_name)
        setValue('property_address', details?.property_address)
        setValue('property_address_2', details?.property_address_2)
        setValue('property_city', details?.property_city)
        setValue('legal_desc', details?.legal_desc)
        setValue('txtdescription', details?.txtdescription)
        setValue('county', details?.county_id)
        setCurrCounty(details?.county_id);
        setValue('property_type', parseInt(details?.property_type) )
        setValue('property_state', details?.property_state)
        setValue('property_zip', details?.property_zip)
        setValue('account_number', details?.account_number)
        setAccountNumber(details?.account_number)
        setValue('exemption_type', details?.exemption_type)
        setValue('property_state', 'TX')
        setPurchasedTwoYears( details?.purchased_within_two_years ? true : false )
        setMajorChanges( details?.has_major_dmg ? true : false )
        setRemodelUpgrades( details?.remodels_within_three_years ? true : false )

        setTimeout(function(){
            setValue('major_dmg_description', details?.has_major_dmg_description)
            setValue('remodel_description', details?.remodels_within_three_years_description)
        }, 500)
        
        propertyDocuments(1);
    },[details])

    const toggleShowPassword = (e) => {
        e.preventDefault();
        setShowPassword(!showPassword);
    };

    const onchangeCounty = (value) => {
        setValue('county', value)
        setCurrCounty(value);
        clearErrors('county')
    }

    const onchangeType = (value) => {
        setValue('property_type', value)
        clearErrors('property_type')
    }

    const onchangeExemption = (value) => {
        setValue("exemption_type", value);
        clearErrors("exemption_type");
      };

    /* doc variable for the three questions */
    const [purchasedDocs, setPurchasedDocs] = useState([]);
    const [majorDMGdocs, setMajorDMDdocs] = useState([]);
    const [remodelDocs, setRemodelDocs] = useState([]);
    const [questionDoc, setQuestionDoc] = useState(1);

    const [submitDocument, setSubmitDocument] = useState(false);
    const [submitGeneral, setSubmitGeneral] = useState(false);
    const [dataConfirm, setDataConfirm] = useState(null);
    const [generalDocoments, setgeneralDocoments] = useState([]);
    const [addLabel, setAddLabel] = useState('files')
    const [docType, setDocType] = useState('1');
    const [type, setType] = useState(1)
    const [show, setShow] = useState(false)
    const handleClose = () => {
        setShow(false);
    }

    const deleteDocument = (id, doc) => {
        if( doc == 1 ){
            const temp = purchasedDocs.filter((file, index) => index !== id)
            setPurchasedDocs( temp )
        }else if( doc == 2 ){
            const temp = majorDMGdocs.filter((file, index) => index !== id)
            setMajorDMDdocs( temp )
        }else{
            const temp = remodelDocs.filter((file, index) => index !== id)
            setRemodelDocs( temp )
        }
    }

    const handlePutfiles = (file) => {
        if( questionDoc == 1 ){
            setPurchasedDocs( oldContent => [...oldContent, file] )
        }else if( questionDoc == 2 ){
            setMajorDMDdocs( oldContent => [...oldContent, file] )
        }else{
            setRemodelDocs( oldContent => [...oldContent, file] )
        }
    }

 

    const propertyDocuments = (type) => { 
        ApiServices.propertyDocuments({id: id, type: type}).then((result) => {    
            if(type == 1) {
                setgeneralDocoments(result.data)
            }
        })
        .catch((error) => {
            globalError();
        })
    }

    const uploadDocs = () => {
        if( purchasedDocs.length !== 0 ){
            purchasedDocs.map( (file, i) => {
                const data = new FormData();
                data.append('id', id);
                data.append('file', file.file);
                data.append('type', 10);

                ApiServices.uploadDocument(data).then((result) => {  
                    // do nothing
                })
                .catch((error) => {
                    globalError();
                })
            });
        }

        if( majorDMGdocs.length !== 0 ){
            majorDMGdocs.map( (file, i) => {
                const data = new FormData();
                data.append('id', id);
                data.append('file', file.file);
                data.append('type', 11);

                ApiServices.uploadDocument(data).then((result) => {  
                    // do nothing
                })
                .catch((error) => {
                    globalError();
                })
            });
        }

        if( remodelDocs.length !== 0 ){
            remodelDocs.map( (file, i) => {
                const data = new FormData();
                data.append('id', id);
                data.append('file', file.file);
                data.append('type', 12);

                ApiServices.uploadDocument(data).then((result) => {  
                    // do nothing
                })
                .catch((error) => {
                    globalError();
                })
            });
        }
    }

    const [onRequest, setOnRequest] = useState(false)

    useEffect(() => {
        ApiServices.viewPropPending(userData.id)
        .then((result) => {
            if( result.data.status == 0  ){
                setOnRequest(true)
            }
        })
    }, [])

    function submitForm(form){
        form.purchase_two_years = purchasedTwoYears ? 1 : 0
        form.property_major_changes = majorChanges ? 1 : 0
        form.remodels_three_years = remodelUpgrades ? 1 : 0
        onSubmit(form)
      }
    

      const onChangeAccount = (value) =>{  
       // var formatted = value.replace(/^(\d{3})(\d{3})(\d{4}).*/, '$1-$2-$3');
       //https://www.titanwolf.org/Network/q/ec40b666-95d2-46a7-bfde-fd59b0941455/y
        if(currCounty == 1 && value.length == 13) { //for harris only
            let formatted = value.match(/\d{3}(?=\d{2,3})|\d+/g).join("-")
            setAccountNumber(formatted);
            setValue("account_number", formatted);
        } else {
            setAccountNumber(value);
            setValue("account_number", value);
        }

        
        clearErrors("account_number");   


    }

    return (
        <>
        <ModalUpload open={show} close={handleClose} type={type} submitDocment={handlePutfiles} is_submitDocument={submitDocument} docuType={docType} addLabel={addLabel} />
        <form
            className="client-reg-form property-edit-form"
            onSubmit={handleSubmit(submitForm)}
        >
            {userData.userlevel !== USERLEVELS.ADMIN  && (
            <Alert color="primary" className="account-alert mb-5">
                <Row>
                    <Col md={12} className="alert-with-icon">
                    <span className="mr-2"><InfoIcon color="#ffffff" /></span>
                    { onRequest ? <><p>Data change request is under review.</p></> : <><p>Your request for data change is first send for our moderators to review.<br />You'll receive an email notifcation once it's approved.</p></>}
                    
                    </Col>
                    {/* <Col md={5} className="text-right">
                        <Link to="" className="btn btn-secondary secondary-inverted mb-0">Contact Us</Link>
                    </Col> */}
                </Row>
            </Alert>
             )}
            <h4>Property Information</h4>
            <div className="form__form-group ">
                <span className="form__form-group-label">
                    Owner Name
                </span>
                <div className="form__form-group-field">
                    <FormField
                        name="owner_name"
                        component="input"
                        control={control}
                        errors={errors}
                        rules={{
                            required: "This is required field",
                        }}
                        placeholder="Owner Name"
                    />
                </div>
                {errors?.owner_name && <span className="form__form-group-error">{errors?.owner_name?.message}</span>}
            </div>
            <div className={`form__form-group  ${userData.userlevel !== USERLEVELS.ADMIN ? 'd-none' : ''}`}>
                <span className="form__form-group-label">
                    Property Type:
                </span>
                <div className="form__form-group-field">
                    <div className="form__form-group-icon">
                        <MapMarkerIcon />
                    </div>
                    <FormSelect
                        name="property_type"
                        placeholder="Select Property Type"
                        rules={{
                            required: "This is required field",
                        }}
                        control={control}
                        options={propertyType}
                        isOject={true}
                        objectName="type"
                        defaultValue={details?.property_type ? parseInt(details?.property_type) : 'DEFAULT'}
                        {...register("property_type", { required: "This is required field" })} 
                        onChange={(value)=> onchangeType(value)}
                    />
                </div>
                {errors?.property_type && <span className="form__form-group-error">{errors?.property_type?.message}</span>}
            </div>
            <div className="form__form-group">
          <span className="form__form-group-label">Exemption Type:</span>
          <div className="flex-input">
            <div className="input-1">
              <div className="form__form-group-field">
                {/* <div className="form__form-group-icon">
                  <MapMarkerIcon />
                </div> */}
                <FormSelect
                  name="exemption_type"
                  placeholder="Select Exemption Type"
                  defaultValue={"DEFAULT"}
                //   rules={{
                //     required: "This is required field",
                //   }}
                  control={control}
                  options={exemptions}
                  isOject={true}
                  objectName="title"
                  defaultValue={ (details?.exemption_type || details?.exemption_type == 0) ? details?.exemption_type : 'DEFAULT'}
                  {...register("exemption_type")}
                  onChange={(value) => onchangeExemption(value)}
                  className={errors?.exemption_type ? "error-field" : ""}
                />
              </div>
            </div>
            <div>
              {errors?.exemption_type && (
                <span className="form__form-group-error">
                  {errors?.exemption_type?.message}
                </span>
              )}
            </div>
          </div>
        </div>

            <div className="form__form-group">
                <span className="form__form-group-label">
                    Account Number
                </span>
                <div className="form__form-group-field">
                    <input
                        value={accountnumner}
                        {...register("account_number")}
                        onChange={(value) => onChangeAccount(value?.target?.value)}
                    />
                    {/* <FormField
                        name="account_number"
                        component="input"
                        control={control}
                        errors={errors}
                        placeholder="Account Number"
                    /> */}
                </div>
                {errors?.account_number && <span className="form__form-group-error">{errors?.account_number?.message}</span>}
            </div>

            <div className="form__form-group">
                <span className="form__form-group-label">
                    Property Address
                </span>
                <div className="form__form-group-field">
                    <FormField
                        name="property_address"
                        component="input"
                        control={control}
                        errors={errors}
                        // rules={{
                        //     required: "This is required field",
                        // }}
                        placeholder="Property Address"
                    />
                </div>
                {errors?.property_address && <span className="form__form-group-error">{errors?.property_address?.message}</span>}
            </div>

            <div className="form__form-group">
                <span className="form__form-group-label">
                    Property City
                </span>
                <div className="form__form-group-field">
                    <FormField
                        name="property_city"
                        component="input"
                        control={control}
                        errors={errors}
                        // rules={{
                        //     required: "This is required field",
                        // }}
                        placeholder="City"
                    />
                </div>
                {errors?.property_city && <span className="form__form-group-error">{errors?.property_city?.message}</span>}
            </div>            

            <div className="form__form-group">
                <span className="form__form-group-label">
                    Property State
                </span>
                <div className="form__form-group-field">
                    <FormField
                        name="property_state"
                        component="input"
                        control={control}
                        errors={errors}
                        rules={{
                            required: "This is required field",
                        }}
                        placeholder="Texas"
                        disabled={true}
                    />
                </div>
                {errors?.property_state && <span className="form__form-group-error">{errors?.property_state?.message}</span>}
            </div>
            <div className="form__form-group">
                <span className="form__form-group-label">
                    Property County
                </span>
                <div className="form__form-group-field">
                    <FormSelect
                        name="county"
                        placeholder="Select County"
                        control={control}
                        options={county}
                        isOject={true}
                        objectName="name"
                        defaultValue={details?.county_id ? details?.county_id : 'DEFAULT'}
                        {...register("county", { required: "This is required field"})} 
                        onChange={(value)=> onchangeCounty(value)}
                    />
                </div>
                {errors?.county && <span className="form__form-group-error">{errors?.county?.message}</span>}
            </div>


            <div className="form__form-group d-none">
                    <span className="form__form-group-label">
                        Property Address 2
                    </span>
                    <div className="form__form-group-field">
                        <FormField
                            name="property_address_2"
                            component="input"
                            control={control}
                            errors={errors}
                            placeholder="Property Address"
                        />
                    </div>
                    {errors?.address2 && <span className="form__form-group-error">{errors?.address2?.message}</span>}
                </div>
            <div className="form__form-group">
                <span className="form__form-group-label">
                    Property Zip
                </span>
                <div className="form__form-group-field">
                    <FormField
                        name="property_zip"
                        component="input"
                        control={control}
                        errors={errors}
                        rules={{
                            pattern: {
                                value: zipPattern,
                                message: 'Entered value does not match zip code format',
                            },
                        }}
                        placeholder="Zip"
                    />
                </div>
                {errors?.property_zip && <span className="form__form-group-error">{errors?.property_zip?.message}</span>}
            </div>

            {userData.userlevel === USERLEVELS.ADMIN &&
            <div className="form__form-group">
                <span className="form__form-group-label">
                    Property Legal Description
                </span>
                <div className="form__form-group-field">
                    <FormField
                        name="legal_desc"
                        component="textarea"
                        control={control}
                        errors={errors}
                        placeholder="Property Legal Description"
                        rows={4}
                    />
                </div>
                {errors?.legal_desc && <span className="form__form-group-error">{errors?.legal_desc?.message}</span>}
            </div>
            }
            {userData.userlevel === USERLEVELS.ADMIN ?
                <>
            <hr />
            <h4>Client Property Notes</h4>
            <div className="form__numbered_options">
                <span className="form__number_label">1</span>
                <div className="form__options_container">
                    <div className="form__options_label">
                        Have you purchased this property within the last two years? 
                    </div>
                    <div className="form__options_wrapper">
                        <label>
                            <FormField
                                name="purchase_two_years"
                                component="input"
                                type="radio"
                                control={control}
                                errors={errors}
                                defaultValue="1"
                                value="1"
                                defaultChecked={ details?.purchased_within_two_years == "1" ? true : null }
                                onClick={(e) => {setPurchasedTwoYears(true); e.target.value = '1';}}
                            />
                            Yes
                        </label>
                        <label>
                            <FormField
                                name="purchase_two_years"
                                component="input"
                                type="radio"
                                control={control}
                                errors={errors}
                                defaultValue="0"
                                value="0"
                                defaultChecked={ details?.purchased_within_two_years == "1" ? null : true }
                                onClick={(e) => {setPurchasedTwoYears(false); e.target.value = '0';}}
                            />
                            No
                        </label>
                    </div>
                    {purchasedDocs.map((obj,i) =>{
                        return(
                            <React.Fragment key={i}>  <PropertyDescriptionDocs  index={i}  docuType="1"  id={i} status={obj?.status} name={obj?.file.name} file_type={obj?.file.type} onDelete={() => deleteDocument(i, 1) } onStatus={(data)=> onStatus(data)}  /></React.Fragment>
                        )
                    })}
                    { purchasedTwoYears &&
                    <div className="form__optional_upload_container">
                        <label>
                            {/* <input type="file" /> */}
                            <Button onClick={() => { setShow(true); setType(1); setDocType('1'); setAddLabel('documents'); setQuestionDoc(1); } } color="secondary" className="secondary-2"><PaperClip /> Add documents</Button>
                        </label>
                        {/* <span onHover={toggle} className="form__custom_tooltip"><a id="diclosure_tooltip">i</a></span>
                        <Tooltip placement="top-start" isOpen={tooltipOpen} popperClassName="custom-tooltip-popper" className="custom-tooltip" target="diclosure_tooltip" arrowClassName="custom-tooltip-arrow" toggle={toggle}>
                            The Closing Disclosure is a five-page form that a lender provides to a home buyer at least 3 business days before their loan closes. It outlines the final terms and costs of the mortgage.
                        </Tooltip> */}
                    </div>
                    }
                </div>
            </div>
            <div className="form__numbered_options mt-3">
                <span className="form__number_label">2</span>
                <div className="form__options_container">
                    <div className="form__options_label">
                        Does the property have any major damage?
                        <br /> <i>(i.e. foundation issues, roof damage, cracks in the exterior or interior walls, etc.)</i>
                    </div>
                    <div className="form__options_wrapper">
                        <label>
                            <FormField
                                name="property_major_changes"
                                component="input"
                                type="radio"
                                control={control}
                                errors={errors}
                                defaultValue="1"
                                value="1"
                                defaultChecked={ details?.has_major_dmg ? true : null }
                                onClick={(e) => { setMajorChanges(true);  e.target.value = '1'; }}
                            />
                            Yes
                        </label>
                        <label>
                            <FormField
                                name="property_major_changes"
                                component="input"
                                type="radio"
                                control={control}
                                errors={errors}
                                defaultValue="0"
                                value="0"
                                defaultChecked={ details?.has_major_dmg ? null : true }
                                onClick={(e) => { setMajorChanges(false);  e.target.value = '0';}}
                            />
                            No
                        </label>
                    </div>
                    { majorChanges &&
                    <div className="form__conditional_field_container">
                        <div className="form__conditional_field-label">
                            Description
                        </div>
                        <div className="form__conditional_field-wrapper">
                            <FormField name="major_dmg_description" component="textarea" control={control} errors={errors} placeholder="Description" />
                            {majorDMGdocs.map((obj,i) =>{
                                return(
                                    <React.Fragment key={i}>  <PropertyDescriptionDocs  index={i}  docuType="1"  id={i} status={obj?.status} name={obj?.file.name} file_type={obj?.file.type} onDelete={() => deleteDocument(i, 2)} onStatus={(data)=> onStatus(data)}  /></React.Fragment>
                                )
                            })}
                            <div className="form__conditional_field-btns pt-2">
                                <Button onClick={() => { setDocType('2'); setShow(true); setType(1);  setAddLabel('photos'); setQuestionDoc(2); } } color="secondary" className="secondary-2"><CameraIcon /> Add photos</Button>
                                <Button onClick={() => { setDocType('1'); setShow(true); setType(1);  setAddLabel('documents'); setQuestionDoc(2); } } color="secondary" className="secondary-2"><PaperClip /> Add documents</Button>
                                {/* <span onHover={toggle} className="docu-tooltip"><a id="desc_tooltip"><InfoIcon color="#383938" /></a></span>
                                <Tooltip placement="top-start" isOpen={tooltipOpen2} target="desc_tooltip" popperClassName="custom-tooltip-popper" className="custom-tooltip" arrowClassName="custom-tooltip-arrow" toggle={toggle2}>
                                    The Closing Disclosure is a five-page form that a lender provides to a home buyer at least 3 business days before their loan closes. It outlines the final terms and costs of the mortgage.
                                </Tooltip> */}
                            </div>
                        </div>
                    </div>
                    }
                </div>
            </div>
            <div className="form__numbered_options">
                <span className="form__number_label">3</span>
                <div className="form__options_container">
                    <div className="form__options_label">
                        Have you done any remodels or upgrades to the property within the last three years?
                    </div>
                    <div className="form__options_wrapper">
                        <label>
                            <FormField
                                name="remodels_three_years"
                                component="input"
                                type="radio"
                                control={control}
                                errors={errors}
                                defaultValue="1"
                                value="1"
                                defaultChecked={ details?.remodels_within_three_years ? true : null }
                                onClick={(e) => { setRemodelUpgrades(true); e.target.value = '1';}}
                            />
                            Yes
                        </label>
                        <label>
                            <FormField
                                name="remodels_three_years"
                                component="input"
                                type="radio"
                                control={control}
                                errors={errors}
                                defaultValue="0"
                                value="0"
                                defaultChecked={ details?.remodels_within_three_years ? null : true }
                                onClick={(e) => { setRemodelUpgrades(false); e.target.value = '0';}}
                            />
                            No
                        </label>
                    </div>
                    { remodelUpgrades &&
                    <div className="form__conditional_field_container">
                        <div className="form__conditional_field-label">
                            Description of the remodel or upgrades
                        </div>
                        <div className="form__conditional_field-wrapper">
                            <FormField name="remodel_description" component="textarea" control={control} errors={errors} placeholder="Description of the remodel or upgrades" />
                            {remodelDocs.map((obj,i) =>{
                                return(
                                    <React.Fragment key={i}>  <PropertyDescriptionDocs  index={i}  docuType="1"  id={i} status={obj?.status} name={obj?.file.name} file_type={obj?.file.type} onDelete={() => deleteDocument(i, 3)} onStatus={(data)=> onStatus(data)}  /></React.Fragment>
                                )
                            })}
                            <div className="form__conditional_field-btns pt-2">
                                <Button onClick={() => { setShow(true); setType(1); setDocType('1'); setAddLabel('documents'); setQuestionDoc(3); } } color="secondary" className="secondary-2 mb-0"><PaperClip /> Add documents</Button>
                            </div>
                        </div>
                    </div>
                    }
                </div>
            </div>
            </>
            :''}
            <hr className="mb-4 mt-3"/>
            <div className="">
                <Button
                    type="submit"
                    color="primary"
                    disabled={isSubmit}
                    onClick={uploadDocs}
                >
                    {isSubmit ? <Spinner size="sm" color="secondary" className="mr-2" /> : ''}
                        Save
                </Button>
                <Button
                    type="button"
                    color="cancel"
                    onClick={()=> onCancel()}
                    className="secondary-2"
                >
                    Cancel
                </Button>
            </div>
        </form>
        </>
    );
};

EditFormClient.propTypes = {
    isHorizontal: PropTypes.bool,
};

EditFormClient.defaultProps = {
    isHorizontal: false, 
};



export default reduxForm({
    form: "client_registration",
  })(EditFormClient);