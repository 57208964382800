/* eslint-disable */
import React, { useState, useEffect, useCallback, useMemo, useRef } from "react";
import { Link, useHistory } from 'react-router-dom';
import DataTable from "react-data-table-component";
import ApiServices from "@/utils/ApiServices";
import Empty from "@/components/Empty";
import { Button, Row, Col } from 'reactstrap';
import CustomPagination from '@/components/CustomPagination'
import "./active-prop.css";
import { COUNTRY_LIST, PROPERTY_TYPE_LIST, TABLE_SETTINGS } from "@/utils/constant";
import { USERLEVELS } from "../../../utils/constant";

const ActiveProperties = ({id, type, setCount, owner_name, perPageSet}) => {   
    const userData = JSON.parse(localStorage.getItem("userData"));  
    const history = useHistory();
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [rowCount, setRowCount] = useState(0)
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(TABLE_SETTINGS.PAGINATION_PER_PAGE);
    const [currentPage, setCurrentPage] = useState(1);
    const page_ref = useRef(1);
    const saerch_ref = useRef(null);

    const columns = useMemo(
        () => [
            {
                name: "No.",
                selector: "prop_num",
                sortable: false,
              
            },            
            {
                name: "Entity Name",
                selector: "owner_name",
                sortable: false,
                width: 300,
                cell: row => <div style={{ whiteSpace: 'normal !important', }}>{row.owner_name}</div>,
            },
            {
                name: "Account No.",
                selector: "account_number",
                sortable: false,
            },
            {
                name: "Property Type",
                selector: "property_type",
                sortable: false,
            },
            {
                name: "Address",
                selector: "property_address",
                sortable: false,
                cell: row => <div style={{ whiteSpace: 'normal !important' }}>{row.property_address}</div>,
            },
            {
                name: "City, State Zip",
                selector: "citystate",
                sortable: false,
            },
            {
                name: "County",
                selector: "county_id",
                sortable: false,
            },
            {
                name: "AOA",
                selector: "aofaready",
                sortable: false,
            },
            {
                cell: (row) => (
                    <Button color="primary" className="primary-2 my-0" onClick={()=> handleRowDocuments(row)}>+ Add documents</Button>
                ),
                name: 'Document',
                width: '200px'
            },
            {
                cell: (row) => (
                    <Button color="secondary" className="my-0  btn-sm white-nowrap" onClick={()=> handleRowDetails(row)}>Details</Button>
                ),
                right: true,
            },
        ],
    );

    const handleClientDetails = (userID) => {
        history.push({
            pathname: '/client-details',
            userID: userID
        })
    }

    const handleRowDetails = (row) => {
        console.log(row);
        history.push({pathname: `/property/details/${row?.id}`})
    } 
    
    const handleRowDocuments = (row) => {
        history.push({pathname: `/property/details/${row?.id}/documents`})
    }   
     

    const fetchData = async (page, size = perPage, ) => {
        
        size = perPageSet ? perPageSet : perPage;

        ApiServices.clientsActiveProperties2({'page' : page, 'per_page': size, id: parseInt(id), pstatus: type, })
        .then((result) => {   
            var temps = result.data.data;   
            temps.map((item) => {
                item.county_id = item.county_name
                item.property_type = item.type
                item.citystate = `${item.property_city || ''}, ${item.property_state || ''}, ${item.property_zip || ''}`;
            })
            setData(temps);
            setTotalRows(result?.data?.total)
            setRowCount(result?.data?.data.length)
            setCount(result?.data?.data.length)
        })
        .catch(function (error) {
        });
    };

    useEffect(() => {
        if(id){
            saerch_ref.current = id;
            fetchData(1);
        }
    }, [id]);
    

   
    const handlePageChange = (page) => {
        fetchData(page);
        setCurrentPage(page);
    };

    const handlePerRowsChange = async (newPerPage, page) => { 
        fetchData(page, newPerPage);
        setPerPage(newPerPage);
    };

    return (
        <div className="activeProperties__wrapper pt-4">
             <Row>
               <Col md={6}>
                    <p className="tab-content-title">{`${type === 'A' ? 'Active' : 'Inactive'}`} properties</p>
                </Col>
                <Col md={6} className="text-right">
                    {
                        userData.userlevel === USERLEVELS.ADMIN && (
                            <Link to={`/property/create/${id}/${owner_name}`} className="btn btn-primary mb-1">Add Property</Link>
                        )
                    }
                </Col>
                <Col md={12}>
                    <table style={{'width': '100%'}}>
                    <thead>
                        <tr>
                            <td>No.</td>
                            <td>Entity Name</td>
                            <td>Account No.</td>
                            <td>Property Type</td>
                            <td>Address</td>
                            <td>City, State Zip</td>
                            <td>County</td>
                            <td>AOA</td>
                            <td>Document</td>
                            <td>&nbsp;</td>
                        </tr>
                    </thead>
                    <tbody>
                    {
                        data.map((i,k) => 
                            <tr>
                                <td>{k+1}</td>
                                <td>{i.owner_name}</td>
                                <td>{i.account_number}</td>
                                <td>{i.property_type}</td>
                                <td>{i.property_address}</td>
                                <td>{i.citystate}</td>
                                <td>{i.county_id}</td>
                                <td>{i.aofaready}</td>
                                <td><Button color="primary" className="primary-2 my-0" onClick={()=> handleRowDocuments(i)}>+&nbsp;Add&nbsp;documents</Button></td>
                                <td><Button color="secondary" className="my-0  btn-sm white-nowrap" onClick={()=> handleRowDetails(i)}>Details</Button></td>                                
                            </tr>
                        )
                    }
                    </tbody>
                    </table>
                    <div style={{'display': 'none'}}>
                    <DataTable
                        //title="Users"
                        columns={columns}
                        data={data}
                        progressPending={loading}
                        pagination={false}
                        paginationServer
                        paginationTotalRows={totalRows}
                        paginationDefaultPage={currentPage}
                        striped={true}
                        highlightOnHover={true}
                        onChangeRowsPerPage={handlePerRowsChange}
                        onChangePage={handlePageChange}
                        onSelectedRowsChange={({ selectedRows }) =>
                            console.log(selectedRows)
                        }
                        paginationPerPage={TABLE_SETTINGS.PAGINATION_PER_PAGE}
                        paginationRowsPerPageOptions={TABLE_SETTINGS.ROW_PER_PAGE}
                        noDataComponent={<Empty />}
                    />
                    <CustomPagination rowCount={rowCount} currentPage={currentPage} totalRows={totalRows} perPage={TABLE_SETTINGS.PAGINATION_PER_PAGE} handlePageChange={handlePageChange} />
                    </div>
                </Col>
            </Row>
       </div>
    );
};

export default ActiveProperties;
