/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { 
    Card, CardBody, Col, Container, Row
  } from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import { Link, useHistory } from 'react-router-dom';
import { Button } from 'reactstrap';
import ButtonToolbar from 'react-bootstrap/ButtonToolbar';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import MapMarkerIcon from 'mdi-react/MapMarkerIcon';
import SearchIcon from 'mdi-react/SearchIcon';
import UndoIcon from 'mdi-react/UndoIcon';
import { removeSpecials } from '@/utils/helpers';
import ApiServices from "@/utils/ApiServices";
import TitleBreadCrumbs from '@/components/TitleBreadCrumbs'
import AngleArrowLeft from '@/images/angleArrowLeft';
import HomeIcon from '@/images/homeIcon' 

const PropertySearch = (props) => {
    const { handleSubmit, reset } = props
    const history = useHistory();
    
    const formInitialData = ({ property_owner: '', account_number: '', property_type: '', property_address: '', property_address2: '', city: '', state: '', county: '', zipcode: '' });
    const [formData, updateFormData] = useState(formInitialData);
    const [isActive, setIsActive] = useState(true);
    const [counties, setCounty] = useState([]);
    const [propertyType, sePropertyType] = useState([]);

    const handleisActive = () => {
        setIsActive(!isActive);
    }

    useEffect(() => { 
        getCounty()
        getPropertyTypes()
    },[props])

    const getCounty = () =>{
        ApiServices.county()
        .then((result) => {   
            setCounty(result.data);
        })
        .catch(function (error) {
        });
    }

    const getPropertyTypes = () =>{
        ApiServices.PropertyTypes()
        .then((result) => { 
            sePropertyType(result.data);
        })
        .catch(function (error) {
        });
    }
    
    const handleInputChange = (e) => {
        updateFormData({
            ...formData,
            [e.target.name] : e.target.value.trim()
        })
    }

    const handleFormSubmit = (e) => {
        e.preventDefault()
        let params = `?type=property&owner_name=${removeSpecials(formData?.property_owner)}&account_number=${formData?.account_number}&property_address=${formData?.property_address}&property_address2=${formData?.property_address2}&city=${formData?.city}&state=${formData?.state}&county=${formData?.county}&property_type=${formData?.property_type}&zipcode=${formData?.zipcode}&active=${isActive}`;
        history.push({ pathname: `/custom-search-results/${btoa(params)}` });
    }
   
    return (
        <Container>
            <TitleBreadCrumbs title="Property Search"   link={`/properties`}  btn="View all properties" >
                <div className="page-breadcrumbs">
                    <Link to="/dashboard">Dashboard</Link>
                    <Link to="/properties">Property</Link>
                    <span className="current_breadcrumbs">Property Search</span>
                </div>
                {/* <div className="mb-4">
                    <Link className="backto-link" to={`/properties`}><HomeIcon color="#FFA647" />View all properties</Link>
                </div> */}
            </TitleBreadCrumbs>
            <Row>
                
                <Col md={12}>
                    <Card className="property-search-container">
                        <CardBody>
                            <Row>
                                <Col md={6}>
                                    <p>You can search for a client using the following filters:</p>
                                </Col>
                                <Col md={6}>
                                    <ButtonToolbar className="activeInactiveSearch">
                                        <ButtonGroup className="me-2" >
                                            <Button onClick={handleisActive} className={`${isActive ? 'active' : ''}`}>Active</Button>
                                            <Button onClick={handleisActive} className={`${ !isActive ? 'active' : ''}`} type="button">Inactive</Button>
                                        </ButtonGroup>
                                    </ButtonToolbar>
                                </Col>
                                <Col md={12}>
                                    <form id="property_search_form" className="property_search_form" onSubmit={handleFormSubmit}>
                                        <div className="form__form-group">
                                            <span className="form__form-group-label">Property Owner:</span>
                                            <div>
                                                <Field
                                                    name="property_owner"
                                                    component="input"
                                                    type="text"
                                                    placeholder="Owner's Name/Entity Name"
                                                    onChange={handleInputChange}
                                                />
                                            </div>
                                        </div>
                                        <div className="form__form-group-two">
                                            <div className="form__form-group">
                                                <span className="form__form-group-label">Account Number:</span>
                                                <div>
                                                    <Field
                                                        name="account_number"
                                                        component="input"
                                                        type="text"
                                                        placeholder="Account Number"
                                                        onChange={handleInputChange}
                                                    />
                                                </div>
                                            </div>
                                            <div className="form__form-group">
                                                <span className="form__form-group-label">Property Type:</span>
                                                <div>
                                                    <Field
                                                        name="property_type"
                                                        component="select"
                                                        className="select-light-border"
                                                        onChange={handleInputChange}
                                                    >
                                                           <option value="">Select Property Type</option>
                                                        {propertyType.map((obj,i) => (
                                                            <option key={i} value={obj.id}>{obj.type}</option>
                                                        ))}
                                                    </Field>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form__form-group">
                                            <span className="form__form-group-label">Property Address:</span>
                                            <div className="form__form-group-field">
                                                <div className="form__form-group-icon">
                                                    <MapMarkerIcon />
                                                </div>
                                                <Field
                                                    name="property_address"
                                                    component="input"
                                                    type="text"
                                                    placeholder="Address"
                                                    onChange={handleInputChange}
                                                />
                                            </div>
                                        </div>
                                        <div className="form__form-group">
                                            <span className="form__form-group-label">Property Address 2:</span>
                                            <div className="form__form-group-field">
                                                <div className="form__form-group-icon">
                                                    <MapMarkerIcon />
                                                </div>
                                                <Field
                                                    name="property_address2"
                                                    component="input"
                                                    type="text"
                                                    placeholder="Address 2"
                                                    onChange={handleInputChange}
                                                />
                                            </div>
                                        </div>
                                        <div className="form__form-group-two">
                                            <div className="form__form-group">
                                                <span className="form__form-group-label">City:</span>
                                                <div className="form__form-group-field">
                                                    <div className="form__form-group-icon">
                                                        <MapMarkerIcon />
                                                    </div>
                                                    <Field
                                                        name="city"
                                                        component="input"
                                                        type="text"
                                                        placeholder="City"
                                                        onChange={handleInputChange}
                                                    />
                                                </div>
                                            </div>
                                            <div className="form__form-group">
                                                <span className="form__form-group-label">State:</span>
                                                <div className="form__form-group-field">
                                                    <div className="form__form-group-icon">
                                                        <MapMarkerIcon />
                                                    </div>
                                                    <Field
                                                        name="state"
                                                        component="input"
                                                        type="text"
                                                        placeholder="State"
                                                        onChange={handleInputChange}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form__form-group-two">
                                            <div className="form__form-group">
                                                <span className="form__form-group-label">County:</span>
                                                <div className="form__form-group-field">
                                                    <div className="form__form-group-icon">
                                                        <MapMarkerIcon />
                                                    </div>
                                                    <Field
                                                        name="county"
                                                        component="select"
                                                        className="select-light-border"
                                                        onChange={handleInputChange}
                                                        >
                                                           <option value="">Select County</option>
                                                        {counties.map((obj,i) => (
                                                            <option key={i} value={obj.id}>{obj.name}</option>
                                                        ))}
                                                    </Field>
                                                </div>
                                            </div>
                                            <div className="form__form-group">
                                                <span className="form__form-group-label">ZipCode:</span>
                                                <div className="form__form-group-field">
                                                    <div className="form__form-group-icon">
                                                        <MapMarkerIcon />
                                                    </div>
                                                    <Field
                                                        name="zipcode"
                                                        component="input"
                                                        type="text"
                                                        placeholder="ZipCode"
                                                        onChange={handleInputChange}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="property-search-btns">
                                            <Button type="button" className="cancel__btn" onClick={reset}><UndoIcon /> Reset</Button>
                                            <Button type="submit" color="primary" className="search-btn"><SearchIcon /> Search</Button>
                                        </div>
                                    </form>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
}

export default reduxForm({
    form: 'property_search',
})(PropertySearch);