/* eslint-disable */
import React, { useMemo, useState, useEffect } from 'react';
import { Col, Row, Table, Button, Spinner } from 'reactstrap';
import { successMsg, globalError, formError, successMsgCustom } from "@/utils/message_herlper"
import ApiServices from "@/utils/ApiServices"

const PropertyRequestChanges = ({ clientData, requestChangesData }) => {
    const [isSubmit, setIsSubmit] = useState(false)
    const [approved, setApproved] = useState(false)
    const [rejected, setRejected] = useState(false)
    
    const AcceptChanges = () => {
        setIsSubmit(true)

        ApiServices.approvePropertyChanges(requestChangesData)
        .then((result) => {
            setIsSubmit(false)
            successMsg('PROPERTY_UPDATE_SUCCESS');
            setApproved(true)
        })
        .catch((error) => {
            setIsSubmit(false)
            globalError()
        });
    }

    const rejectChanges = () =>{  
        ApiServices.rejectPropertyChanges({id: requestChangesData?.id})
        .then((result) => { 
            successMsgCustom("Request successfully rejected!")
            setIsSubmit(false)
            setRejected(true)
        })
        .catch((error) => {
            setIsSubmit(false)
            globalError()
        });
    }

    useEffect(() => {
        if( requestChangesData.status === 1 ){
            setApproved(true)
        }
        if( requestChangesData.status === 2 ){
            setRejected(true)
        }
    }, [])

    return (
        <div className="activity-changes-wrapper mt-3">
        <p>Request the following <span className="highlight">property</span> changes:</p>
        { clientData.property_state !== requestChangesData.property_state &&
        <p><span>property state</span> from <span>{clientData.property_state}</span> to <span>{requestChangesData.property_state}</span></p>
        }
        { clientData.count_id !== requestChangesData.count_id &&
        <p><span>county</span> from <span>{clientData.count_id}</span> to <span>{requestChangesData.count_id}</span></p>
        }
        { clientData.property_city !== requestChangesData.property_city &&
        <p><span>property_city</span> from <span>{clientData.property_city}</span> to <span>{requestChangesData.property_city}</span></p>
        }
        { clientData.property_address !== requestChangesData.property_address &&
        <p><span>Property Address</span> from <span>{clientData.property_address}</span> to <span>{requestChangesData.property_address}</span></p>
        }
        { clientData.property_zip !== requestChangesData.property_zip &&
        <p><span>property zip</span> from <span>{clientData.property_zip}</span> to <span>{requestChangesData.property_zip}</span></p>
        }
        { clientData.purchased_within_two_years !== requestChangesData.purchased_within_two_years &&
        <p><span>purchased within two years</span> from <span>{clientData.purchased_within_two_years}</span> to <span>{requestChangesData.purchased_within_two_years}</span></p>
        }
        { clientData.has_major_dmg !== requestChangesData.has_major_dmg &&
        <p><span>has major dmg</span> from <span>{clientData.has_major_dmg}</span> to <span>{requestChangesData.has_major_dmg}</span></p>
        }
        { clientData.has_major_dmg_description !== requestChangesData.has_major_dmg_description &&
        <p><span>has major dmg description</span> from <span>{clientData.has_major_dmg_description}</span> to <span>{requestChangesData.has_major_dmg_description}</span></p>
        }
        { clientData.remodels_within_three_years !== requestChangesData.remodels_within_three_years &&
        <p><span>remodels within three years</span> from <span>{clientData.remodels_within_three_years}</span> to <span>{requestChangesData.remodels_within_three_years}</span></p>
        }
        { clientData.remodels_within_three_years_description !== requestChangesData.remodels_within_three_years_description &&
        <p><span>remodels within three years description</span> from <span>{clientData.remodels_within_three_years_description}</span> to <span>{requestChangesData.remodels_within_three_years_description}</span></p>
        }
        <Row>
            <Col md={5} className="pt-3 text-left action-btn-wrapper">
            {!approved && !rejected ? <>
                <Button color="primary" onClick={AcceptChanges} disabled={isSubmit}>{isSubmit ? <Spinner size="sm" color="secondary" className="mr-2" /> : ''} Approve</Button>
                <Button  onClick={rejectChanges} color="secondary">Reject</Button>
                </> :''}
                { approved && 
                <Button color="primary" disabled>Approved</Button>
                }
                { rejected && 
                <Button color="primary" disabled >Rejected</Button>
                }
            </Col>
        </Row>
        </div>
    )
}

export default PropertyRequestChanges;