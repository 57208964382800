/* eslint-disable */
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Link, NavLink } from "react-router-dom";
import { withRouter } from "react-router";
import ApiServices from "@/utils/ApiServices";
import RegisterForm from "@/shared/components/login_register/LoginRegisterForm";
import Loading from "../../../shared/components/Loading";

const Register = ({ history }) => {
    const [error, setError] = useState("");
    const [isRegister, setRegister] = useState(false);
    const [title, setTitle] = useState("");
    const [message, setMessage] = useState("");
    const [loading, setLoading] = useState(true);
    // const registerFireBase = (user) => {
    //   firebase.auth().createUserWithEmailAndPassword(user.email, user.password)
    //     .then(() => {
    //       history.push('/log_in');
    //     })
    //     .catch((err) => {
    //       setError(err.message);
    //     });
    // };

    useEffect(() => {
        getSettings();
    }, []);

    const getSettings = () => {
        setLoading(true);
        ApiServices.getRegistrationSettings().then((result) => {
            let value = false;
            console.log(result);
            if (result?.data && result?.data?.enable_register?.key_value === "true") {
                value = true;
            }
            setRegister(value);
            setTitle(result?.data?.placeholder_title?.key_value);
            setMessage(result?.data?.placeholder_message?.key_value);
        })
        .finally(() => {
            setLoading(false);
        });
    };

    console.log({isRegister})

    if(loading) return <Loading />

    return (
        <div className="account account--not-photo">
            <div className="account__wrapper register-page-wrapper">
                <div className="account__card">
                    <div className="login_header pt-2">
                        <div className="topbar__logo" to="/" height="150px" />
                    </div>
                    {isRegister && <RegisterForm errorMessage={error} />}
                    {!isRegister && (
                        <div className="account__have-account">
                            <h3>
                                {title}
                            </h3>
                            <div style={{marginTop: 16}}>
                                
                                    <p style={{marginTop: 0, marginBottom: 0}}>{message}</p>
                                
                            </div>
                            {/* <h3>
                                We are no longer accepting May 15th deadline
                                protest.
                            </h3>
                            <p style={{ marginBottom: 0 }}>
                                Please contact info@republicpropertytax.com if
                                you were noticed late
                            </p>
                            <p style={{ marginTop: 0 }}>
                                or have filed your own protest and would like us
                                to take over.
                            </p> */}
                            <p>
                                Already have an account?{" "}
                                <NavLink to="/login">Login</NavLink>
                            </p>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

Register.propTypes = {
    history: PropTypes.shape({
        push: PropTypes.func,
    }).isRequired,
};

export default withRouter(Register);
