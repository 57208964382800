/* eslint-disable */
import React from 'react';
import moment  from 'moment'
import { useCookies } from 'react-cookie';
import { Col, Row, Table, Button } from 'reactstrap';
import {  API, TIMEZONE  } from '@/utils/constant'

const Exports = () => {
    const [cookies,removeCookie] = useCookies(['name']);

    const handleExport = (e, exportType) => {
        e.preventDefault();
        if( exportType === 'all-clients' ){
            window.open(`${API}export/all-active-clients/${btoa(cookies?.userID || 0)}/${btoa(moment().tz(TIMEZONE).add(1, 'hours').format('YYYY-MM-DD HH:mm:ss'))}`, '_blank');
        }
        if( exportType === 'billing-report' ){
            window.open(`${API}export/billing-report/${btoa(cookies?.userID || 0)}/${btoa(moment().tz(TIMEZONE).add(1, 'hours').format('YYYY-MM-DD HH:mm:ss'))}`, '_blank');
        }
        if( exportType === 'protest-data' ){
            window.open(`${API}export/protest/${btoa(cookies?.userID || 0)}/${btoa(moment().tz(TIMEZONE).add(1, 'hours').format('YYYY-MM-DD HH:mm:ss'))}`, '_blank');
        }
        if( exportType === 'useful-info' ){
            window.open(`${API}export/useful-info/${btoa(cookies?.userID || 0)}/${btoa(moment().tz(TIMEZONE).add(1, 'hours').format('YYYY-MM-DD HH:mm:ss'))}`, '_blank');
        }
        
    };

    return (
    <>
        <Row>
            <Col md={12} className="mb-4">
                <Row>
                    <Col md={12}>
                        <div className="dashboard-card-title">
                            <p>Export Data</p>
                        </div>
                    </Col>
                </Row>
            </Col>
        </Row>
        <Row>
            <Col md={12}>
                <div className="exportbtn-wrapper">
                    <Button color="secondary" onClick={(e) => handleExport(e, 'all-clients')}>All active clients</Button>
                    <Button color="secondary" onClick={(e) => handleExport(e, 'protest-data')}>Protest data</Button>
                    <Button color="secondary" onClick={(e) => handleExport(e, 'useful-info')}>Useful info</Button>
                    <Button color="secondary" onClick={(e) => handleExport(e, 'billing-report')}>Billing report</Button>
                </div>
            </Col>
        </Row>
    </>
    );
}

export default Exports;