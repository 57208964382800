/* eslint-disable */

const PaperPlaneIcon = ({ color="#009ADC", custom }) => {
    return (
    <svg className={`${ custom && custom } custom`} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M22.3125 0.149975L0.585955 12.6843C-0.262483 13.1718 -0.15467 14.3531 0.68908 14.7093L5.67189 16.8L19.1391 4.93122C19.3969 4.70153 19.7625 5.05309 19.5422 5.32028L8.25002 19.0781V22.8515C8.25002 23.9578 9.58596 24.3937 10.2422 23.5921L13.2188 19.9687L19.0594 22.4156C19.725 22.6968 20.4844 22.2796 20.6063 21.5625L23.9813 1.31247C24.1406 0.3656 23.1235 -0.318774 22.3125 0.149975Z" fill={color}/>
    </svg>
    )
}

export default PaperPlaneIcon;