/* eslint-disable */
import React, { useState, useEffect, useMemo, useRef } from "react";
import DataTable from "react-data-table-component";
import ModalConfirm from "@/components/ModalConfirm";
import ApiServices from "@/utils/ApiServices";
import CheckIcon from "@/images/checkIcon";
import { TABLE_SETTINGS } from "@/utils/constant";
import { successMsg, globalError } from "@/utils/message_herlper";
import moment from "moment";
import Empty from "@/components/Empty";
import { useHistory } from "react-router-dom";
import { Button, Row, Col, Container, Card, CardBody } from "reactstrap";
import TableLoading from "@/components/TableLoading";
import CustomPagination from "@/components/CustomPagination";

const ClientsTable = ({type}) => {
    let title = "Title";
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [rowCount, setRowCount] = useState(0);
    const [perPage, setPerPage] = useState(TABLE_SETTINGS.PAGINATION_PER_PAGE);
    const [currentPage, setCurrentPage] = useState(1);
    const history = useHistory();
    const page_ref = useRef(1);
    const id_ref = useRef(0);

    useEffect(() => {
        if(type === 'active-clients'){
            fetchActiveClient(1);
        }else{
            fetchNewClient(1);
        }
        
    }, [type]);

    const fetchActiveClient = async (page, size = perPage) => {
        setLoading(true);
        page_ref.current = page;
        ApiServices.dashboardActive({
            page: page,
            per_page: size,
        })
            .then((result) => {
                setData(result.data.data);
                setTotalRows(result.data.total);
                setRowCount(result.data.data.length);
                setLoading(false);
            })
            .catch(function (error) {
                setLoading(false);
            });
    };

    const fetchNewClient = async (page, size = perPage) => {
        setLoading(true);
        page_ref.current = page;
        ApiServices.dashboardNewClient({
            page: page,
            per_page: size,
        })
            .then((result) => {
                setData(result.data.data);
                setTotalRows(result.data.total);
                setRowCount(result.data.data.length);
                setLoading(false);
            })
            .catch(function (error) {
                setLoading(false);
            });
    };

    const handleViewDetails = (clientID) => {
        history.push({
            pathname: `/client/details/${clientID}`,
        });
    };

    const columns = useMemo(() => [
        {
            name: "Date added",
            selector: "termsofusetimestamp",
            sortable: false,
            width: "150px",
        },
        {
            name: "Full Name",
            selector: "name",
            sortable: false,
            width: "200px",
        },
        {
            name: "Address, City, State Zip code",
            selector: "homeaddress",
            sortable: false,
            width: "250px",
        },
        {
            name: "Phone",
            selector: "homephone1",
            sortable: false,
            width: "150px",
        },
        {
            name: "Email",
            selector: "email",
            sortable: false,
            width: "250px",
        },
        {
            cell: (row) => (
                <>
                    <Button
                        className="my-0  btn-sm white-nowrap"
                        onClick={() => handleViewDetails(row.id)}
                        color="secondary"
                    >
                        Details
                    </Button>
                </>
            ),
            right: true,
        },
    ]);

    const handleMark = (value) => {
        id_ref.current = value;
        setConfirm(true);
    };

    const handlePageChange = (page) => {
        if(type === 'active-clients'){
            fetchActiveClient(page);
        }else{
            fetchNewClient(page);
        }
        setCurrentPage(page);
    };

  

    const handlePerRowsChange = async (newPerPage, page) => {
        if(type === 'active-clients'){
            fetchActiveClient(page, newPerPage);
        }else{
            fetchNewClient(page, newPerPage);
        }
        setPerPage(newPerPage);
    };



    const dataTable = [];
    const list = data;
    for (let index = 0; index < list.length; index++) {
        dataTable.push({
            id: list[index].id,
            termsofusetimestamp: moment
                .utc(list[index].termsofusetimestamp)
                .local()
                .format("MMM DD, hh:mm A"),
            name: `${list[index].client_first_name} ${list[index].client_last_name}`,
            homeaddress: `${list[index].homeaddress}, ${
                list[index]?.homecity || ""
            }, ${list[index]?.homestate || ""} ${list[index]?.homezip || ""}`,
            homephone1: list[index].homephone1,
            email: list[index].email,
        });
    }

    return (
        <>
            <DataTable
                columns={columns}
                data={dataTable}
                progressPending={loading}
                progressComponent={<TableLoading />}
                pagination={false}
                paginationServer
                paginationTotalRows={totalRows}
                paginationDefaultPage={currentPage}
                striped={TABLE_SETTINGS.STRIPED}
                highlightOnHover={true}
                onChangeRowsPerPage={handlePerRowsChange}
                onChangePage={handlePageChange}
                onSelectedRowsChange={({ selectedRows }) =>
                    console.log(selectedRows)
                }
                paginationPerPage={TABLE_SETTINGS.PAGINATION_PER_PAGE}
                paginationRowsPerPageOptions={TABLE_SETTINGS.ROW_PER_PAGE}
                noDataComponent={<Empty />}
            />
            <CustomPagination
                rowCount={rowCount}
                currentPage={currentPage}
                totalRows={totalRows}
                perPage={TABLE_SETTINGS.PAGINATION_PER_PAGE}
                handlePageChange={handlePageChange}
            />
        </>
    );
};

export default React.memo(ClientsTable);
