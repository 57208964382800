/* eslint-disable */
import React from 'react';
import { Col, Row } from 'reactstrap';
import { Link } from 'react-router-dom';
import HomeIcon from '@/images/homeIcon' 
import ClockIcon from '@/images/clockIcon' 
import UserIcon from '@/images/userIcon' 
import PenIcon from '@/images/penIcon' 
import FilePlusIcon from '@/images/filePlusIcon' 
import FileMinusIcon from '@/images/fileMinusIcon' 

const Reports = () => {
    return (
    <>
        <Row>
            <Col md={12} className="mb-4">
                <div className="dashboard-card-title">
                    <p>Reports</p>
                </div>
            </Col>
        </Row>
        <Row>
            <Col md={4} >
                <Link to="/reports/invoice-report" className="report-btn">
                    <div className="report-icon-wrapper">
                        <FilePlusIcon color="#009ADC" />
                    </div>
                    <div className="report-title-wrapper">
                        <p>Invoice Report</p>
                    </div>
                </Link>
            </Col>
            <Col md={4} >
                <Link to="/reports/account-specific-report" className="report-btn">
                    <div className="report-icon-wrapper">
                        <UserIcon color="#009ADC" />
                    </div>
                    <div className="report-title-wrapper">
                        <p>Account specific report</p>
                    </div>
                </Link>
            </Col>
            <Col md={4} >
                <Link to="/reports/yearly-accounting-totals" className="report-btn">
                    <div className="report-icon-wrapper">
                        <ClockIcon color="#009ADC" />
                    </div>
                    <div className="report-title-wrapper">
                        <p>Yearly accounting Total</p>
                    </div>
                </Link>
            </Col>
            <Col md={4} >
                <Link to="/reports/properties-zip" className="report-btn">
                    <div className="report-icon-wrapper">
                        <HomeIcon color="#009ADC" />
                    </div>
                    <div className="report-title-wrapper">
                        <p>Properties by Zip</p>
                    </div>
                </Link>
            </Col>
            <Col md={4} >
                <Link to="/reports/not-invoiced" className="report-btn">
                    <div className="report-icon-wrapper">
                        <FileMinusIcon color="#009ADC" />
                    </div>
                    <div className="report-title-wrapper">
                        <p>Not Invoiced</p>
                    </div>
                </Link>
            </Col>
            <Col md={4} >
                <Link to="/reports/custom-report" className="report-btn">
                    <div className="report-icon-wrapper">
                        <PenIcon color="#009ADC" />
                    </div>
                    <div className="report-title-wrapper">
                        <p>Custom Report</p>
                    </div>
                </Link>
            </Col>
        </Row>
    </>
    );
}

export default Reports;