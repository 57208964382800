/* eslint-disable */
import React, {useState} from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { UserProps } from '@/shared/prop-types/ReducerProps';
import TopbarProfile from './TopbarProfile';
import TopbarSearch from './TopbarSearch';
import { USERLEVELS } from "@/utils/constant";
import ModalConfirm from "@/components/ModalConfirm";
import ApiServices from "@/utils/ApiServices"
import { successMsgCustom, errorMsgCustom } from "@/utils/message_herlper"

const userData = JSON.parse(localStorage.getItem('userData'))

function Topbar(changeMobileSidebarVisibility, changeSidebarVisibility, user){
  
  const [open,setOpen] = useState(false)
  function onDeleteAccount(){
    ApiServices.requestDeleteAccount()
    .then((result) => {   
      setOpen(false);
      successMsgCustom("Request successfully Submitted!")
    }).catch(function (error) {
      errorMsgCustom("Eror found while submitting your request");
    });
  }
  return(
    <>
    <div className="topbar">
    <ModalConfirm
         // submit={submit}
          title="Delete Account"
          description="Are you sure you want to delete your account?"
          btnText="Delete"
          open={open}
          Onsubmit={() => onDeleteAccount()}
          close={() => setOpen(false)}
      />
    {/* <div className="topbar__left">
      <TopbarSidebarButton
        changeMobileSidebarVisibility={changeMobileSidebarVisibility}
        changeSidebarVisibility={changeSidebarVisibility}
      />
      <Link className="topbar__logo" to="/online_marketing_dashboard" />
    </div> */}
    <div className="topbar__right">
      <div className="topbar__right-search">
        <TopbarSearch />
      </div>
      <div className="topbar__right-over">
        {/* <TopbarNotification />
        <TopbarMail new /> */}
         {/* <div className="mt-3">
         {userData.userlevel === USERLEVELS.CLIENT  && <>
          <button className="btn btn-primary btn-sm" onClick={()=> setOpen(true)} >Delete Account</button>
            </>
          }
         </div> */}
        <TopbarProfile user={user} />
        {/* <TopbarLanguage /> */}
      </div>
    </div>
  </div>
    </>
  )
}

Topbar.propTypes = {
  changeMobileSidebarVisibility: PropTypes.func.isRequired,
  changeSidebarVisibility: PropTypes.func.isRequired,
  user: UserProps,
};

Topbar.defaultProps = {
  user: {},
};

export default Topbar;
