/* eslint-disable */
import React from 'react'
import Modal from 'react-bootstrap/Modal'
import { Button, Spinner } from 'reactstrap'
import WarningOutlineIcon from 'mdi-react/WarningOutlineIcon'
import CloseIcon from '@/images/closeIcon'


const ModalConfirm = ({open, close, Onsubmit, title, description, btnText, submit}) => {   

    return(
        <>
            <Modal className="deleteClientModal" show={open} onHide={close}   size="sm" centered >
                <Button onClick={close} className="modalconfirm-closebtn"><CloseIcon color="#383938" /></Button>
                <Modal.Body className="text-center" >
                   <h3 className="mt-31"><b>{title}</b></h3>
                   <p>{description}</p>
                    <Button color="primary" onClick={() => { Onsubmit()  }} disabled={submit} >
                        {submit ? <Spinner size="sm" color="secondary" className="mr-2" /> : ''}{btnText}
                    </Button>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default ModalConfirm;