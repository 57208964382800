/* eslint-disable */

const AngleLeft = ({ color="#383938", custom }) => {
    return (
    <svg className={`${ custom } custom`} width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M10 12.5L6 8.5L10 4.5" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
    )
}

export default AngleLeft;