/* eslint-disable */
import React from "react";
import Modal from "react-bootstrap/Modal";
import { Button, Spinner } from "reactstrap";
import WarningOutlineIcon from "mdi-react/WarningOutlineIcon";
import CloseIcon from "@/images/closeIcon";
import ProgressLoading from './ProgressLoading'
const ModalConfirm = ({ open, description, isDone }) => {
    return (
        <>
            <Modal
                className="deleteClientModal"
                show={open}
                size="sm"
                centered
            >
                <Modal.Body className="text-center">
                    <p> <ProgressLoading isDone={isDone} /> Please wait exporting <b>{description}</b></p>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default ModalConfirm;
