/* eslint-disable */
export const getOidcStorageKey = () => {
  const authSettings = JSON.parse(localStorage.getItem('authSettings'));
  if (authSettings) {
    return `oidc.user:${authSettings.auth_server}:${authSettings.client_id}`;
  }
  return null;
};

export const getOidcInfo = () => {
  const key = getOidcStorageKey();
  if (key) {
    const oidc = JSON.parse(localStorage.getItem(key));
    return oidc;
  }
  return null;
};

export const getToken = () => {
  const oidc = getOidcInfo();
  if (oidc) {
    return oidc.id_token;
  }
  return null;
};

export const removeSpecials = (str) => {
  if(str){
    return str.replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, '');
  }
  return '';
  };

export const numberWithCommas = (valueString) => {
    var amount=parseFloat(valueString).toFixed(2)
    var formattedString= amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    return formattedString
};

export const formatPhoneNumber = (str) => {
  //Filter only numbers from the input
  let cleaned = ('' + str).replace(/\D/g, '');
  
  //Check if the input is of correct length
  let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);

  if (match) {
    return '(' + match[1] + ') ' + match[2] + '-' + match[3]
  };

  return str
};