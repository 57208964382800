/* eslint-disable */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import "moment-timezone";
import { Link, useHistory, useLocation, Switch, Route } from "react-router-dom";
import { useCookies } from "react-cookie";
import SidebarLink from "./SidebarLink";
import SidebarCategory from "./SidebarCategory";
import Nav from "react-bootstrap/Nav";
import NavDropdown from "react-bootstrap/NavDropdown";
import GaugeLowIcon from "mdi-react/CompassOutlineIcon";
import HomeIcon from "@/images/homeIcon";
import AccountOutlineIcon from "@/images/userMultipleIcon";
import ReportIcon from "@/images/reportsIcon";
import ExportIcon from "@/images/exportIcon";
import UserIcon from "@/images/userIcon";
import GearIcon from "mdi-react/GearOutlineIcon";
import EmailMultipleIcon from "mdi-react/EmailMultipleIcon";
import ClockOutlineIcon from "mdi-react/ClockOutlineIcon";
import ModalLoading from "@/components/ModalLoading";
import ModalClient from "@/components/ModalClient";

import $ from "jquery";
import ApiServices from "@/utils/ApiServices";
import { successMsgCustom } from "@/utils/message_herlper";
import { API, TIMEZONE, USERLEVELS } from "@/utils/constant";

const SidebarContent = ({
    onClick,
    changeToLight,
    changeToDark,
    sidebarCollapse,
}) => {
    const location = useLocation();
    const history = useHistory();
    const userData = JSON.parse(localStorage.getItem("userData"));

    const temp = localStorage.getItem("account");
    const [cookies, removeCookie] = useCookies(["name"]);
    const [isExport, setExport] = useState(false);
    const [isDone, setDone] = useState(false);
    const [description, setDescription] = useState("");
    const hideSidebar = () => {
        onClick();
    };

    const checkSidebar = () => {
        $(
            ".sidebar__content .nav-item, .sidebar__content .dropdown-menu"
        ).removeClass("show");
        $(".sidebar__content .dropdown-item.active")
            .closest(".nav-item")
            .addClass("show");
        $(".sidebar__content .dropdown-item.active")
            .closest(".dropdown-menu")
            .addClass("show");
    };

    // useEffect(() => {
    //   checkSidebar();
    // }, [location])

    useEffect(() => {
        checkSidebar();
    }, []);

    const handleExport = (e, exportType) => {
        e.preventDefault();
        if (exportType === "all-clients") {
            window.open(
                `${API}export/all-active-clients/${btoa(
                    cookies?.userID || 0
                )}/${btoa(
                    moment()
                        .tz(TIMEZONE)
                        .add(1, "hours")
                        .format("YYYY-MM-DD HH:mm:ss")
                )}`,
                "_blank"
            );
        }
        if (exportType === "billing-report") {
            window.open(
                `${API}export/billing-report/${btoa(
                    cookies?.userID || 0
                )}/${btoa(
                    moment()
                        .tz(TIMEZONE)
                        .add(1, "hours")
                        .format("YYYY-MM-DD HH:mm:ss")
                )}`,
                "_blank"
            );
        }
        if (exportType === "protest-data") {
            window.open(
                `${API}export/protest/${btoa(cookies?.userID || 0)}/${btoa(
                    moment()
                        .tz(TIMEZONE)
                        .add(1, "hours")
                        .format("YYYY-MM-DD HH:mm:ss")
                )}`,
                "_blank"
            );
        }
        if (exportType === "useful-info") {
            window.open(
                `${API}export/useful-info/${btoa(cookies?.userID || 0)}/${btoa(
                    moment()
                        .tz(TIMEZONE)
                        .add(1, "hours")
                        .format("YYYY-MM-DD HH:mm:ss")
                )}`,
                "_blank"
            );
        }
    };

    const logout = (e) => {
        removeCookie("userDetails");
        removeCookie("token");
        removeCookie("userID");
        successMsgCustom("You have been logged out!");
        localStorage.removeItem("loggedin_id");
        localStorage.removeItem("loggedin_token");
        localStorage.removeItem("userData");
        setTimeout(() => {
            window.location =
                userData?.userlevel !== USERLEVELS.ADMIN
                    ? "/login"
                    : "/admin-login";
        }, 1000);
    };

    const handleExportProperties = () => {
        setExport(true);
    };

    const generateClientReport = (value) => {
        setExport(false);
        window.open(
            `${API}export/client-properties/${btoa(value || 0)}/${btoa(
                moment()
                    .tz(TIMEZONE)
                    .add(1, "hours")
                    .format("YYYY-MM-DD HH:mm:ss")
            )}`,
            "_blank"
        );
    };

    return (
        <div className="sidebar__content">
            <ModalClient
                open={isExport}
                close={() => setExport(false)}
                generateClientReport={(value) => generateClientReport(value)}
            />
            <Link
                className="topbar__logo mb-0"
                to="/dashboard"
                height="150px"
            />
            <Nav
                activeKey={location.pathname}
                defaultActiveKey="/dashboard"
                className="flex-column sidebar-nav-wrapper"
            >
                {/* className="navbar__link--active" */}
                <Nav.Link
                    as={Link}
                    to="/dashboard"
                    className={
                        location?.pathname === "/dashboard"
                            ? "navbar__link--active"
                            : ""
                    }
                >
                    <GaugeLowIcon /> Dashboard
                </Nav.Link>
                {userData?.userlevel !== USERLEVELS.CLIENT && (
                    <>
                        <Nav.Link
                            as={Link}
                            to="/clients"
                            className={
                                location?.pathname === "/clients" ||
                                location?.pathname === "/client-search"
                                    ? "navbar__link--active"
                                    : ""
                            }
                        >
                            <>
                                <AccountOutlineIcon
                                    color={
                                        location?.pathname === "/clients"
                                            ? "#009adc80"
                                            : "#ffffff"
                                    }
                                />{" "}
                                Clients
                            </>
                        </Nav.Link>
                        <Nav.Link
                            as={Link}
                            to="/properties"
                            className={
                                location?.pathname === "/properties" ||
                                location?.pathname === "/properties-search"
                                    ? "navbar__link--active"
                                    : ""
                            }
                        >
                            <>
                                <HomeIcon
                                    color={
                                        location?.pathname === "/properties"
                                            ? "#009adc80"
                                            : "#ffffff"
                                    }
                                />{" "}
                                Properties
                            </>
                        </Nav.Link>
                        <Nav.Link
                            as={Link}
                            to="/activities"
                            className={
                                location?.pathname === "/activities"
                                    ? "navbar__link--active"
                                    : ""
                            }
                        >
                            <>
                                <ClockOutlineIcon /> Activities
                            </>
                        </Nav.Link>
                        {userData.userlevel === USERLEVELS.ADMIN && (
                            <NavDropdown
                                title={
                                    <>
                                        <ReportIcon color="#ffffff" />
                                        Reports
                                    </>
                                }
                                id="reports-dropdown"
                            >
                                <NavDropdown.Item
                                    as={Link}
                                    to="/reports/invoice-report"
                                    className={
                                        location?.pathname ===
                                        "/reports/invoice-report"
                                            ? "navbar__link--active"
                                            : ""
                                    }
                                >
                                    Invoice Report
                                </NavDropdown.Item>
                                <NavDropdown.Item
                                    as={Link}
                                    to="/reports/commission-report"
                                    className={
                                        location?.pathname ===
                                        "/reports/commission-report"
                                            ? "navbar__link--active"
                                            : ""
                                    }
                                >
                                    Commission Report
                                </NavDropdown.Item>
                                {/* <NavDropdown.Item   as={Link} to="/reports/account-specific-report" title="Account Specific Report" className={location?.pathname  === '/reports/account-specific-report' ? 'navbar__link--active' :'' }>Account Specific..</NavDropdown.Item> */}
                                <NavDropdown.Item
                                    as={Link}
                                    to="/reports/yearly-accounting-totals"
                                    title="Yearly Accounting Total"
                                    className={
                                        location?.pathname ===
                                        "/reports/yearly-accounting-totals"
                                            ? "navbar__link--active"
                                            : ""
                                    }
                                >
                                    Yearly Accounting Total
                                </NavDropdown.Item>
                                <NavDropdown.Item
                                    as={Link}
                                    to="/reports/properties-zip"
                                    className={
                                        location?.pathname ===
                                        "/reports/properties-zip"
                                            ? "navbar__link--active"
                                            : ""
                                    }
                                >
                                    Properties By Zip
                                </NavDropdown.Item>
                                <NavDropdown.Item
                                    as={Link}
                                    to="/reports/not-invoiced"
                                    className={
                                        location?.pathname ===
                                        "/reports/not-invoiced"
                                            ? "navbar__link--active"
                                            : ""
                                    }
                                >
                                    Not Invoiced
                                </NavDropdown.Item>
                                <NavDropdown.Item
                                    as={Link}
                                    to="/reports/outstanding-balance"
                                    className={
                                        location?.pathname ===
                                        "/reports/outstanding-balance"
                                            ? "navbar__link--active"
                                            : ""
                                    }
                                >
                                    Outstanding Balance
                                </NavDropdown.Item>
                                <NavDropdown.Item
                                    as={Link}
                                    to="/reports/leads"
                                    className={
                                        location?.pathname ===
                                        "/reports/leads"
                                            ? "navbar__link--active"
                                            : ""
                                    }
                                >
                                    Leads
                                </NavDropdown.Item>
                                <NavDropdown.Item
                                    as={Link}
                                    to="/reports/custom-report"
                                    className={
                                        location?.pathname ===
                                        "/reports/custom-report"
                                            ? "navbar__link--active"
                                            : ""
                                    }
                                >
                                    Custom
                                </NavDropdown.Item>
                            </NavDropdown>
                        )}
                        {userData.userlevel === USERLEVELS.ADMIN && (
                            <NavDropdown
                                title={
                                    <>
                                        <ExportIcon color="#ffffff" />
                                        Export Data
                                    </>
                                }
                                id="export-dropdown"
                            >
                                <NavDropdown.Item
                                    onClick={(e) =>
                                        handleExport(e, "all-clients")
                                    }
                                    href="#"
                                >
                                    All Active Clients
                                </NavDropdown.Item>
                                <NavDropdown.Item
                                    onClick={(e) =>
                                        handleExport(e, "protest-data")
                                    }
                                    href="#"
                                >
                                    Protest Data
                                </NavDropdown.Item>
                                <NavDropdown.Item
                                    onClick={(e) =>
                                        handleExport(e, "useful-info")
                                    }
                                    href="#"
                                >
                                    Useful Info
                                </NavDropdown.Item>
                                <NavDropdown.Item
                                    onClick={(e) =>
                                        handleExport(e, "billing-report")
                                    }
                                    href="#"
                                >
                                    Billing Report
                                </NavDropdown.Item>
                                <NavDropdown.Item
                                    onClick={(e) => handleExportProperties()}
                                    href="#"
                                >
                                    Client Properties
                                </NavDropdown.Item>
                            </NavDropdown>
                        )}
                        {/* <NavDropdown title={<><ContactsIcon />Contact</>} id="contact-dropdown">
          <NavDropdown.Item onClick={sideBarOnClick} href="/send-email">Send Email</NavDropdown.Item>
          <NavDropdown.Item onClick={sideBarOnClick} href="/email-all">Email All Active Client</NavDropdown.Item >
  </NavDropdown> */}

                {userData.userlevel === USERLEVELS.ADMIN && (
                    <>
                        <Nav.Link as={Link} to="/batch-email">
                            <>
                                <EmailMultipleIcon />
                                Batch Email
                            </>
                        </Nav.Link>
                    </>
                )}
                        <Nav.Link as={Link} to="/settings">
                            <>
                                <GearIcon />
                                Settings
                            </>
                        </Nav.Link>
                    </>
                )}
                <Nav.Link
                    onClick={logout}
                    href="#"
                    className="logout-sidebar-link"
                >
                    <>
                        <ExportIcon color="#ffffff" />
                        Log out
                    </>
                </Nav.Link>
                {/* <NavDropdown title={<><GearIcon />System Settings</>} id="system-dropdown">
          <NavDropdown.Item onClick={sideBarOnClick} href="/account-details">Edit Personal Info</NavDropdown.Item>
          <NavDropdown.Item onClick={sideBarOnClick} href="#">System Status</NavDropdown.Item>
        </NavDropdown> */}
            </Nav>
        </div>
    );
};

SidebarContent.propTypes = {
    changeToDark: PropTypes.func.isRequired,
    changeToLight: PropTypes.func.isRequired,
    onClick: PropTypes.func.isRequired,
    sidebarCollapse: PropTypes.bool,
};

SidebarContent.defaultProps = {
    sidebarCollapse: false,
};

export default SidebarContent;
