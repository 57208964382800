/* eslint-disable */
import React, { useCallback, useState, useEffect } from 'react';
import moment from 'moment';
import Modal from 'react-bootstrap/Modal'
import { Button, Spinner } from 'reactstrap'
import CloseIcon from '@/images/closeIcon'
import ProfileRequestChanges from '@/containers/Client/components/profileRequestchanges';


const ModalRequestEmail = ({ open, close, requestData}) => {  
    const [files, setFiles] = useState([]);
    const [theType, setTheType] = useState(2);

    return (
        <Modal className="modal-doc-upload" show={open} onHide={() => { setFiles([]); close(); } } centered size="md">
            <Button onClick={() => { setFiles([]); close(); } } className="modalconfirm-closebtn modal-closebtn"><CloseIcon color="#383938" /></Button>
            <Modal.Body className="text-center">
               <p>{moment(requestData?.updated_at).format('MMM DD, h:mm A')}</p>
             <ProfileRequestChanges clientData={requestData?.user_id} requestChangesData={requestData ? requestData : []}/>
            </Modal.Body>
        </Modal>
    );
}
export default ModalRequestEmail;