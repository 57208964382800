/* eslint-disable */
import React, {useEffect , useState} from 'react'
import ApiServices from "@/utils/ApiServices"
import { Link } from 'react-router-dom';
import { Card, CardBody, Col, Container, Row, Button } from 'reactstrap';


const ClientSideProfileView = () => {
    const [details, setDetails] = useState(null);
    const userData = JSON.parse(localStorage.getItem('userData'));

    const clientDetails = () => {
        ApiServices.clientsDetails(userData?.id).then((result) => {  
            setDetails(result.data);
        })
        .catch((error) => {
            // formError();
        })
    }

    useEffect(() => { 
        clientDetails();
    }, []);

    return (
        <Row>
            <Col md={6}>
                <div className="dashboard-card-title mt-2">
                    <p>Profile Information</p>
                </div>
            </Col>
            <Col md={6} className="text-right">
                <Link className="btn btn-secondary" to="/account-details">Edit</Link>
            </Col>
            <Col md={6}>
                <Card className="pb-0">
                    <CardBody className="client-details-body">
                        <div className="card-header">
                            <p>Personal Information</p>
                        </div>
                        <div className="card-content">
                            <p className="card-detail-label">First Name: <span>{details?.client_first_name}</span></p>
                            <p className="card-detail-label">Last Name: <span>{details?.client_last_name}</span></p>
                            <p className="card-detail-label">Company Name: <span>{details?.company_name}</span></p>
                            <p className="card-detail-label">Email Address: <span>{details?.email}</span></p>
                        </div>
                    </CardBody>
                </Card>
            </Col>
            <Col md={6}>
                <Card className="pb-0">
                    <CardBody className="client-details-body">
                        <div className="card-header">
                            <p>Contact Information</p>
                        </div>
                        <div className="card-content">
                            <p className="card-detail-label">State: <span>{details?.homestate}</span></p>
                            <p className="card-detail-label">County: <span>{details?.homecounty}</span></p>
                            <p className="card-detail-label">City: <span>{details?.homecity}</span></p>
                            <p className="card-detail-label">Address: <span>{details?.homeaddress}</span></p>
                            <p className="card-detail-label">Zip: <span>{details?.homezip}</span></p>
                            <p className="card-detail-label">Phone: <span>{details?.homephone1}</span></p>
                            <p className="card-detail-label">Phone 2: <span>{details?.homephone2}</span></p>
                        </div>
                    </CardBody>
                </Card>
            </Col>
        </Row>
    )
}

export default ClientSideProfileView;