/* eslint-disable */
import React, { useEffect, useState } from "react";
import {
    Card,
    CardBody,
    Col,
    Container,
    Row, 
} from "reactstrap"
import { Link, useHistory, useParams } from "react-router-dom"
import ApiServices from "@/utils/ApiServices"
import { successMsg, globalError, formError } from "@/utils/message_herlper"
import CreateForm from './components/CreateForm' 
import TitleBreadCrumbs from '@/components/TitleBreadCrumbs'
import { USERLEVELS } from "@/utils/constant";

const PropertyCreate = () => {
    let { id , owner_name} = useParams()
    const [showPassword, setShowPassword] = useState(false)
    const history = useHistory()
    const [isSubmit, setSubmit] = useState(false)
    const [errors, seErrors] = useState([]);
    const [propertyType, sePropertyType] = useState([]);
    const [county, setCounty] = useState([]);
    const userData = JSON.parse( localStorage.getItem('userData') )
   const [purchasedDocs, setPurchasedDocs2] = useState([]);
   const [majorDMGdocs, setMajorDMDdocs2] = useState([]);
   const [remodelDocs, setRemodelDocs2] = useState([]);
   const [exemptions, setExemptions] = useState([]);

    useEffect(() => {
        getPropertyTypes();
        getCounty();
        getExemptionsTypes();
    }, []); 

    const getExemptionsTypes = () => {
        ApiServices.ExemptionTypes()
            .then((result) => {
                let data = result.data
                data.unshift({
                    id : 0,
                    title: 'None'
                })
                setExemptions(data);
            })
            .catch(function (error) {});
    };
    
    
    const onSubmit = (data) => {
        if(userData.userlevel === USERLEVELS.CLIENT && userData.id != id){
            globalError()
            return
        }

        setSubmit(true)
        data.user_id = id
        ApiServices.registerProperty(data)
        .then((result) => {   
            if (parseInt(data.purchase_two_years) === 1) {
                for (
                    let index = 0;
                    index < purchasedDocs.length;
                    index++
                ) {
                    saveFile(
                        result?.data.id,
                        1,
                        purchasedDocs[index]
                    );
                }
            }

            if (parseInt(data.property_major_changes) === 1) {
                for (
                    let index = 0;
                    index < majorDMGdocs.length;
                    index++
                ) {
                    saveFile(
                        result?.data.id,
                        2,
                        majorDMGdocs[index]
                    );
                }
            }

            if (parseInt(data.remodels_three_years) === 1) {
                for (
                    let index = 0;
                    index < remodelDocs.length;
                    index++
                ) {
                    saveFile(
                        result?.data.id,
                        3,
                        remodelDocs[index]
                    );
                }
            }
           setTimeout(() =>{
            successMsg('PROPERTY_SUCCESS');
            if( userData.userlevel === USERLEVELS.ADMIN ){
                history.push({pathname: `/client/details/${id}`})
            }else{
                history.push({pathname: `/property/details/${result?.data.id}`})
            }
            
           },1000)
        })
        .catch((error) => {
            setSubmit(false)
            if (error.response) {
                seErrors(error?.response?.data?.errors)
                formError(error?.response?.data?.errors)
               return
            }
            globalError()
        });
    }

    function saveFile(id, type, file) {
        const data = new FormData();
        data.append("id", id);
        data.append("type", type);
        data.append("file", file);
        ApiServices.registerFile(data)
            .then((result) => {
                console.log(result.data);
            })
            .catch((error) => {
                setSubmit(false);
                if (error.response) {
                    //rors(error?.response?.data?.errors)
                    formError(error?.response?.data?.errors);
                    return;
                }
                globalError();
            });
    }

    const getPropertyTypes = () =>{
        ApiServices.PropertyTypes()
        .then((result) => { 
            sePropertyType(result.data);
        })
        .catch(function (error) {
        });
    }

    const getCounty = () =>{
        ApiServices.county()
        .then((result) => {   
            setCounty(result.data);
        })
        .catch(function (error) {
        });
    }

    const onCancel = () =>{
        if( userData.userlevel === USERLEVELS.ADMIN ){
            history.push({pathname: `/client/details/${id}`})
        }else{
            history.push({pathname: `/dashboard`})
        }
        
    }

    
    return (
        <Container className="client-registration-container">
            <TitleBreadCrumbs title="Create New Property" >
                <div className="page-breadcrumbs">
                    <Link to="/dashboard">Dashboard</Link>
                    { userData.userlevel === USERLEVELS.ADMIN && 
                        <Link to="/properties">Property</Link>
                    }
                    <span className="current_breadcrumbs">Create</span>
                </div>
            </TitleBreadCrumbs>
            <Row>
                <Col md={12}>
                    <Card>
                        <CardBody className="client-registration-form-container">
                            <CreateForm county={county} owner_name={owner_name}  onCancel={()=> onCancel()} onSubmit={(data)=> onSubmit(data)} isSubmit={isSubmit} errorList={errors} propertyType={propertyType} setPurchasedDocs2={(file) => setPurchasedDocs2(file)} setMajorDMDdocs2={(file) => setMajorDMDdocs2(file)} setRemodelDocs2={(file) => setRemodelDocs2(file)}  exemptions={exemptions}  />
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
};


export default PropertyCreate;

