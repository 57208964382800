/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { 
     Col, Container, Row
  } from 'reactstrap';
import moment  from 'moment'
import 'moment-timezone';
import DocumentCardView from '@/components/DocumentCardView'
import {  API, TIMEZONE  } from '@/utils/constant'

const ClientSheet = ({ id }) => {
    const infoFile = () => {
       window.open(`${API}property/client-info-sheet/${btoa(id)}/${btoa(moment().tz(TIMEZONE).add(1, 'hours').format('YYYY-MM-DD HH:mm:ss'))}`, '_blank');
    }

    const signTerms = () => {
        window.open(`${API}property/client-sign-terms/${btoa(id)}/${btoa(moment().tz(TIMEZONE).add(1, 'hours').format('YYYY-MM-DD HH:mm:ss'))}`, '_blank');
     }


    return (
        <Container className="pt-4">
            <Row className="controlled-row">
                <Col md={6} className="client-account-files-cont">
                    <p className="tab-content-title">Documents</p>
                    <DocumentCardView     name="Client Info Sheet" file_type="pdf" is_view={true} agentFile={()=> infoFile()}  />
                    <DocumentCardView     name="Client Signed Terms" file_type="pdf" is_view={true} agentFile={()=> signTerms()}  />
                </Col>
                <Col md={6}>

                </Col>
            </Row>
        </Container>
    );
}
export default ClientSheet;