/* eslint-disable */
import React, { useRef } from 'react'
import Modal from 'react-bootstrap/Modal'
import { Button, Spinner } from 'reactstrap'
import DotsHorizontalIcon from 'mdi-react/DotsHorizontalIcon'
import { useForm } from "react-hook-form";



const ChangePassword = ({open, close, onSubmit, isSubmit}) => {   
    const { register, errors, handleSubmit, watch } = useForm({});
    const password = useRef({});
    password.current = watch("password", "");
    return(
        <>
            <Modal className="deleteClientModal" show={open} onHide={close}   size="lg" centered >
                <Modal.Body className="text-center" >
                    <DotsHorizontalIcon />
                    <h3 className="mt-3 mb-3"><b>Change Password</b></h3>
                       <p className="mb-3">Please type the new password and confirm that password.</p>
                        <div className="client-registration-form-container">
                           <form  onSubmit={handleSubmit(onSubmit)}>
                                <div>
                                    <label>Password</label>
                                    <input
                                        name="password"
                                        type="password"
                                        ref={register({
                                        required: "You must specify a password",
                                        minLength: {
                                            value: 8,
                                            message: "Password must have at least 8 characters"
                                        }
                                        })}
                                    />
                                    {errors.password && <p className="form__form-group-error">{errors.password.message}</p>}
                                </div>
                                <div className="mt-3">
                                    <label>Repeat password</label>
                                    <input
                                        name="password_repeat"
                                        type="password"
                                        ref={register({
                                        validate: value =>
                                            value === password.current || "The passwords do not match"
                                        })}
                                    />
                                    {errors.password_repeat && <p className="form__form-group-error">{errors.password_repeat.message}</p>}
                                </div>
                                <div className="client-create-btns justify-center">
                                    <Button
                                        type="submit"
                                        color="primary"
                                        disabled={isSubmit}
                                    >
                                        {isSubmit ? <Spinner size="sm" color="secondary" className="mr-2" /> : ''}
                                        Change Password
                                    </Button>
                                </div>
                            </form>
                        </div>
                    {/* <Button className="deleteClient-btn" onClick={()=> action(value)} >Change Outstanding Balance</Button> */}
                </Modal.Body>
            </Modal>
        </>
    )
}

export default ChangePassword;