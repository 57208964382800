/* eslint-disable */
import React, { useEffect, useState, useRef, useMemo } from "react";
import queryString from "query-string";
import { Card, CardBody, Col, Container, Row,Button,
    Spinner } from "reactstrap";
import { Field, reduxForm, reset } from "redux-form";
import DataTable from "react-data-table-component";
import { Link, useHistory, useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import { FormField, FormSelect } from "@/shared/components/FormField";
import Select from 'react-select'
import { TABLE_SETTINGS, SELECT_STYLES, API } from "@/utils/constant";
import MapMarkerIcon from "mdi-react/MapMarkerIcon";
import SearchIcon from "mdi-react/SearchIcon";
import UndoIcon from "mdi-react/UndoIcon";
import TitleBreadCrumbs from "@/components/TitleBreadCrumbs";
import ApiServices from "@/utils/ApiServices";
import TableLoading from "@/components/TableLoading";
import { globalError } from "@/utils/message_herlper";
import CustomPagination from "@/components/CustomPagination";
import Empty from "@/components/Empty";
import "./report-style.css";



const CustomReport = (props) => {
    const {
        handleSubmit,
        reset,
        control,
        errors,
        register,
        setValue,
        clearErrors,
        formState,
        resetForm,
    } = useForm();

    const history = useHistory();
    const location = useLocation();
    const currentYear = new Date().getFullYear();
    const range = (start, stop, step) =>
        Array.from(
            { length: (stop - start) / step + 1 },
            (_, i) => start + i * step
        );
    const [data, setData] = useState([]);
    const [county, setCounty] = useState([]);
    const [loading, setLoading] = useState(true);
    const [totalRows, setTotalRows] = useState(0);
    const [rowCount, setRowCount] = useState(0);
    const [show, setShow] = useState(false);
    const [perPage, setPerPage] = useState(TABLE_SETTINGS.PAGINATION_PER_PAGE);
    const [currentPage, setCurrentPage] = useState(1);
    const [years, setYears] = useState(
        range(currentYear, currentYear - 8, -1)?.reverse()
    );
    const [resultList, setResultList] = useState([]);
    const [agentList, setAgentList] = useState([]);
    const [propertyType, sePropertyType] = useState([]);
    const [yearSeledted, setYearSeledted] = useState([]);
    const [porpertySeledted, setPropertySeledted] = useState([]);
    const [countySelected, setCountySelected] = useState([]);
    const [resultSelected, setResultSelected] = useState([]);
    const [agentSelected, setAgentSelected] = useState([]);
    const [billingSeledted, setBillingSeledted] = useState([]);
    const [resultSeledted, setRsultSeledted] = useState([]);
    const [propertyInfo, setPropertyInfo] = useState([]);
    const [defaultProperty, setDefaultProperty] = useState();
    const [exemptions, setExemptions] = useState([]);
    const [exemptionSeledted, setExemptionSeledted] = useState([])
    const [isSubmit, setSubmit] = useState(false);
    const infoRef = useRef([]);
    const propertyRef = useRef([]);
    const [selectedOptionsTY, setSelectedOptionsTY] = useState([]);
    const [selectedOptionsPT, setSelectedOptionsPT] = useState([]);
    const [selectedOptionsET, setSelectedOptionsET] = useState([]);
    const [selectedOptionsBT, setSelectedOptionsBT] = useState([]);
    const [selectedOptionsAL, setSelectedOptionsAL] = useState([]);
    const [selectedOptionsRL, setSelectedOptionsRL] = useState([]);

    const OUTSTADING_BALANCE = ["true", "false"];
    const PROPERTY_STATUS = ["Active", "Inactive"];
    const keyRef = useRef(null);
    const propertyaccountnumRef = useRef(null);
    const propertyCityRef = useRef(null);
    const propertyAddressRef = useRef(null);
    const propertyStateRef = useRef(null);
    const propertyzipRef = useRef(null);
    const clientnameRef = useRef(null);
    const clientphone1Ref = useRef(null);
    const clientphone2Ref = useRef(null);
    const clientemailRef = useRef(null);
    const clienthomeaddressRef = useRef(null);
    const clienthomecityRef = useRef(null);
    const clienthomestateRef = useRef(null);
    const clienthomezipRef = useRef(null);
    const propertycountyRef = useRef(null);
    const propertyisactiveRef = useRef(null);
    const propertyownerRef = useRef(null);
    const propertytyperRef = useRef(null);
    
    let columns = []


    useEffect(() => {
        
        let { key } = props?.match?.params;
        if (key) {
            setShow(true);
            let url_params = atob(key);
            let { propertyaccountnum, propertyaddress, propertycity, propertystate, propertyzip, clientname, clientphone1, clientphone2, clientemail, clienthomeaddress, clienthomecity, clientstate, clienthomezip, propertycounty,propertyisactive, propertyowner, propertytype } = queryString.parse(url_params);

            if(propertyaccountnum === 'true'){
                propertyaccountnumRef.current = true
            }else{
                propertyaccountnumRef.current = false
            }
            if(propertycity === 'true'){
                propertyCityRef.current = true
            }else{
                propertyCityRef.current = false
            }
            if(propertyaddress === 'true'){
                propertyAddressRef.current = true
            }else{
                propertyAddressRef.current = false
            }
            if(propertystate === 'true'){
                propertyStateRef.current = true
            }else{
                propertyStateRef.current = false
            }
            if(propertyzip === 'true'){
                propertyzipRef.current = true
            }else{
                propertyzipRef.current = false
            }
            if(clientname === 'true'){
                clientnameRef.current = true
            }else{
                clientnameRef.current = false
            }

            if(clientphone1 === 'true'){
                clientphone1Ref.current = true
            }else{
                clientphone1Ref.current = false
            }

            if(clientphone2 === 'true'){
                clientphone2Ref.current = true
            }else{
                clientphone2Ref.current = false
            }

            if(clientemail === 'true'){
                clientemailRef.current = true
            }else{
                clientemailRef.current = false
            }

            if(clienthomeaddress === 'true'){
                clienthomeaddressRef.current = true
            }else{
                clienthomeaddressRef.current = false
            }

            if(clienthomecity === 'true'){
                clienthomecityRef.current = true
            }else{
                clienthomecityRef.current = false
            }

            if(clientstate === 'true'){
                clienthomestateRef.current = true
            }else{
                clienthomestateRef.current = false
            }

            if(clienthomezip === 'true'){
                clienthomezipRef.current = true
            }else{
                clienthomezipRef.current = false
            }

            if(propertycounty === 'true'){
                propertycountyRef.current = true
            }else{
                propertycountyRef.current = false
            }

            if(propertyisactive === 'true'){
                propertyisactiveRef.current = true
            }else{
                propertyisactiveRef.current = false
            }

            if(propertyowner === 'true'){
                propertyownerRef.current = true
            }else{
                propertyownerRef.current = false
            }

            if(propertytype === 'true'){
                propertytyperRef.current = true
            }else{
                propertytyperRef.current = false
            }
            
            
            keyRef.current = key;
            fetchData(1);
        } else {
            setTimeout(() => {
                // document.getElementById("propertyaddress").checked = true;
                // document.getElementById("propertycity").checked = true;
                // document.getElementById("propertystate").checked = true;
                // document.getElementById("propertyzip").checked = true;
                // document.getElementById("propertyaccountnum").checked = true;
            }, 2000);
            setShow(false);
            resultsList();
            getagentList();
            getPropertyTypes();
            getCounty();
            getExemptionsTypes();
        }
    }, [location]);

    const fetchData = async (page, size = perPage, filterData = keyRef.current) => {
        setData([]);
        ApiServices.customRerport({
            page: page,
            per_page: size,
            filterData: filterData,
        })
            .then((result) => {  
                setData(result.data.data);
                setTotalRows(result.data.total);
                setRowCount(result.data.data.length);
                setLoading(false);
            })
            .catch((error) => {
                globalError();
            });
    };

    const getCounty = () => {
        ApiServices.county()
            .then((result) => {  
            let results = result?.data
            let ConutyList = []
            for (let index = 0; index < results.length; index++) {
                ConutyList.push({ value: results[index].id, label: results[index].name })
            }
            setCounty(ConutyList);
            })
            .catch(function (error) {});
    };

    const resultsList = () => {
        ApiServices.resultsList().then((result) => {
            let results = result?.data
            let ResultList = []
            ResultList.push({ value: 'all', label: 'Select All' })
            ResultList.push({ value: 'none', label: 'Select None' })
            for (let index = 0; index < results.length; index++) {
                ResultList.push({ value: results[index].id, label: results[index].name })
            }
            setResultList(ResultList);
        });
    };

    const getPropertyTypes = () =>{
        ApiServices.PropertyTypes()
        .then((result) => { 
            let results = result?.data
            let PropertyTypeList = []
            PropertyTypeList.push({ value: 'all', label: 'Select All' })
            PropertyTypeList.push({ value: 'none', label: 'Select None' })
            for (let index = 0; index < results.length; index++) {
                PropertyTypeList.push({ value: results[index].id, label: results[index].type })
            }
            sePropertyType(PropertyTypeList);
        })
    }

    const getExemptionsTypes = () => {
        ApiServices.ExemptionTypes()
            .then((result) => {
                let results = result?.data
                let ExemptionTypeList = []
                ExemptionTypeList.push({ value: 'all', label: 'Select All' })
                ExemptionTypeList.push({ value: 'none', label: 'Select None' })
                for (let index = 0; index < results.length; index++) {
                    ExemptionTypeList.push({ value: results[index].id, label: results[index].title })
                }
                setExemptions(ExemptionTypeList);
            })
            .catch(function (error) {});
    };

    const getagentList = () => {
        ApiServices.agentList().then((result) => {
            let results = result?.data;
            let BillingTypeList = [];
            BillingTypeList.push({ value: 'all', label: 'Select All' })
            BillingTypeList.push({ value: 'none', label: 'Select None' })
            for (let index = 0; index < results.length; index++) {
                BillingTypeList.push({
                    value: results[index].id,
                    label: results[index].name,
                });
            }
            setAgentList(BillingTypeList);
        });
    };

    const onSubmit = (form) => {
        let property_selected = propertyRef.current;
        let info_selected = infoRef.current;
        let county = Array.isArray(countySelected)  && countySelected.map(({ value }) => value)
        let propertyResult = Array.isArray(porpertySeledted) && porpertySeledted.map(({ value }) => value)
        let exemptionResult = Array.isArray(exemptionSeledted)  && exemptionSeledted.map(({ value }) => value)
        let resultData = Array.isArray(resultSelected)  && resultSelected.map(({ value }) => value)
        let agentData = Array.isArray(agentSelected)  && agentSelected.map(({ value }) => value)
        let params = `?accountnum=${form.accountnum}&address=${form.address}&year=${form.year}&city=${form.city}&county=${JSON.stringify(county || [])}&exemption=${JSON.stringify(exemptionResult || [])}&email=${form.email}&fname=${form.fname}&lname=${form.lname}&outstanding_balance=${form.outstanding_balance}&owner_name=${form.owner_name}&phone=${form.phone}&phone2=${form.phone2}&property_status=${form.property_status}&property_type=${JSON.stringify(propertyResult || [])}&result=${JSON.stringify(resultData || [])}&agent=${JSON.stringify(agentData || [])}&state=${form.state}&zipcode=${form.zipcode}`;
        params += `&propertycity=${property_selected.includes(
            "propertycity"
        )}&propertyaddress=${property_selected.includes(
            "propertyaddress"
        )}&propertystate=${property_selected.includes(
            "propertystate"
        )}&propertyzip=${property_selected.includes(
            "propertyzip"
        )}&propertycounty=${property_selected.includes(
            "propertycounty"
        )}&propertyisactive=${property_selected.includes(
            "propertyisactive"
        )}&propertyowner=${property_selected.includes(
            "propertyowner"
        )}&propertytype=${property_selected.includes(
            "propertytype"
        )}&propertyaccountnum=${property_selected.includes(
            "propertyaccountnum"
        )}`;
        params += `&clientname=${info_selected.includes(
            "clientname"
        )}&clientphone1=${info_selected.includes(
            "clientphone1"
        )}&clientphone2=${info_selected.includes(
            "clientphone2"
        )}&clientemail=${info_selected.includes(
            "clientemail"
        )}&clienthomeaddress=${info_selected.includes(
            "clienthomeaddress"
        )}&clienthomecity=${info_selected.includes(
            "clienthomecity"
        )}&clienthomestate=${info_selected.includes(
            "clienthomestate"
        )}&clienthomezip=${info_selected.includes("clienthomezip")}`;
        console.log('params', params)
        history.push({ pathname: `/reports/custom-report/${btoa(params)}` });
    };

    const onchangeCounty = (value) => {  
        setValue("county", value);
    };

    const onchangeYear = (value) => {  
        setValue("year", value);
    };


    const onchangeOutstading = (value) => {
        setValue("outstanding_balance", value);
    };

    const onchangePropertyStatus = (value) => {
        setValue("property_status", value);
    };

    const onchangeType = (value) => {
        setValue("property_type", value);
    };

    const onchangeResult = (value) => {
        setValue("result", value);
    };

    const handleChange = (event) => {
        let new_propertyInfo = infoRef.current;
        const target = event.target;
        var value = target.value;

        if (target.checked) {
            new_propertyInfo.push(value);
        } else {
            const objIndex = new_propertyInfo.findIndex((obj) => obj === value);
            new_propertyInfo.splice(objIndex, 1);
        }
        infoRef.current = new_propertyInfo;
    };
    const handleChangeProperty = (event) => {
        let new_propertyInfo = propertyRef.current;
        const target = event.target;
        var value = target.value;
        if (target.checked) {
            new_propertyInfo.push(value);
        } else {
            if (value === "propertyaddress")
                document.getElementById("propertyaddress").checked = false;
            if (value === "propertycity")
                document.getElementById("propertycity").checked = false;
            if (value === "propertystate")
                document.getElementById("propertystate").checked = false;
            if (value === "propertyzip")
                document.getElementById("propertyzip").checked = false;
            if (value === "propertyaccountnum")
                document.getElementById("propertyaccountnum").checked = false;
            const objIndex = new_propertyInfo.findIndex((obj) => obj === value);
            new_propertyInfo.splice(objIndex, 1);
        }
        propertyRef.current = new_propertyInfo;
    };

    const goBack = () => {
        reset('custom_report')
        history.push({ pathname: `/reports/custom-report` });
    };

    const handlePageChange = (page) => {
        fetchData(page);
        setCurrentPage(page);
    };

    const handlePerRowsChange = async (newPerPage, page) => {
        fetchData(page, newPerPage);
        setPerPage(newPerPage);
    };

    const exportReport = (type) => {
        window.open(`${API}report/custom-export/${keyRef.current}/${type}`, '_blank');
        // setSubmit(true);
        // ApiServices.customExport({ filterData : keyRef.current })
        //     .then((result) => {  
        //         setTimeout(() => {  
        //             setSubmit(false);
        //             const url = window.URL.createObjectURL(
        //                 new Blob([result.data])
        //             );
        //             const link = document.createElement("a");
        //             link.href = url;
        //             const fileName = `Custom Report.xlsx`;
        //             link.setAttribute("download", fileName);
        //             document.body.appendChild(link);
        //             link.click();
        //             link.remove();
        //         }, 1000);
        //     })
        //     .catch((error) => {
        //         setSubmit(false);
        //         globalError();
        //     });
    };

  
    if(clientnameRef.current){
        columns.push({
            name: "Client Name",
            selector: "client_name",
            sortable: false,
          })
    }

    if(clientphone1Ref.current){
        columns.push({
            name: "Client Home Phone 1",
            selector: "homephone1",
            sortable: false,
          })
    }

    if(clientphone2Ref.current){
        columns.push({
            name: "Client Home Phone 2",
            selector: "homephone2",
            sortable: false,
          })
    }

    if(clientemailRef.current){
        columns.push({
            name: "Client Email",
            selector: "email",
            sortable: false,
          })
    }

    if(clienthomeaddressRef.current){
        columns.push({
            name: "Client HomeAddress",
            selector: "homeaddress",
            sortable: false,
          })
    }

    if(clienthomecityRef.current){
        columns.push({
            name: "Client HomeCity",
            selector: "homecity",
            sortable: false,
          })
    }

    if(clienthomestateRef.current){
        columns.push({
            name: "Client HomeState",
            selector: "homestate",
            sortable: false,
          })
    }

    if(clienthomezipRef.current){
        columns.push({
            name: "Client HomeZip",
            selector: "homezip",
            sortable: false,
          })
    }

    if(propertyisactiveRef.current){
        columns.push({
            name: "isActive?",
            selector: "isActive",
            sortable: false,
          })
    }
    if(propertyownerRef.current){
        columns.push({
            name: "Entity Name",
            selector: "owner_name",
            sortable: false,
          })
    }

    if(propertyaccountnumRef.current){
        columns.push({
            name: "Account Number",
            selector: "account_number",
            sortable: false,
          })
    }

    if(propertytyperRef.current){
        columns.push({
            name: "Property Type",
            selector: "property_type",
            sortable: false,
          })
    }

    
    
    if(propertyAddressRef.current){
        columns.push({
            name: "Property Address",
            selector: "property_address",
            sortable: false,
        })
    }
 
    if(propertyCityRef.current){
        columns.push({
            name: "Property City",
            selector: "property_city",
            sortable: false,
          })
    }

    if(propertyStateRef.current){
        columns.push({
            name: "Property State",
            selector: "property_state",
            sortable: false,
          })
    }

    if(propertycountyRef.current){
        columns.push({
            name: "Property County",
            selector: "county_name",
            sortable: false,
          })
    }

    if(propertyzipRef.current){
        columns.push({
            name: "Property ZipCode",
            selector: "property_zip",
            sortable: false,
          })
    }

    const dataTable = [];
    const list = data;   
    for (let index = 0; index < list.length; index++) {
        dataTable.push({
            id: list[index].id,
            account_number: list[index].account_number,
            client_name: `${list[index].client_first_name} ${list[index].client_last_name}`,
            homephone1: list[index].homephone1,
            homephone2: list[index].homephone2,
            email: list[index].email,
            homeaddress: list[index].homeaddress,
            homecity: list[index].homecity,
            homestate: list[index].homestate,
            homezip: list[index].homezip,
            isActive: list[index].pstatus === 'A' ? 'True' : 'False',
            owner_name: list[index].owner_name,
            property_type: list[index].property_value,
            property_address: list[index].property_address,
            property_city: list[index].property_city,
            property_state: list[index].property_state,
            county_name: list[index].county_name,
            property_zip: list[index].property_zip,
           
           
        });
    }

    const setYearSeledtedAction = ( value ) => {    
        const selectedValue =  Array.isArray(selectedOptionsTY) && selectedOptionsTY.length != 0 && value  ? value[ value.length - 1 ] : value  ? value[0] : []
        let newDAta = []
        if(selectedValue?.value == 'none'){
            newDAta = []
        }else if(selectedValue?.value == 'all'){
            newDAta = TaxYearList.slice(2);
        }else{
            newDAta = value;
        }
        setSelectedOptionsTY(newDAta);
        setYearSeledted(newDAta);
    }


    const setPropertySeledtedAction = ( value ) => {    
        const selectedValue =  Array.isArray(selectedOptionsPT) && selectedOptionsPT.length != 0 && value  ? value[ value.length - 1 ] : value  ? value[0] : []
        let newDAta = []
        if(selectedValue?.value == 'none'){
            newDAta = []
        }else if(selectedValue?.value == 'all'){
            newDAta = propertyType.slice(2);
        }else{
            newDAta = value;
        }
        setSelectedOptionsPT(newDAta);
        setPropertySeledted(newDAta);
    }

    const setExemptionSeledtedAction = ( value ) => {    
        const selectedValue =  Array.isArray(selectedOptionsET) && selectedOptionsET.length != 0 && value  ? value[ value.length - 1 ] : value  ? value[0] : []
        let newDAta = []

        if(selectedValue?.value == 'none'){
            newDAta = []
        }else if(selectedValue?.value == 'all'){
            newDAta = exemptions.slice(2);
        }else{
            newDAta = value;
        }
        setSelectedOptionsET(newDAta);
        setExemptionSeledted(newDAta);
    }

    const setBillingSeledtedAction = ( value ) => {    
        const selectedValue =  Array.isArray(selectedOptionsBT) && selectedOptionsBT.length != 0 && value  ? value[ value.length - 1 ] : value  ? value[0] : []
        let newDAta = []
        if(selectedValue?.value == 'none'){
            newDAta = []
        }else if(selectedValue?.value == 'all'){
            newDAta = resultList.slice(2);
        }else{
            newDAta = value;
        }
        setSelectedOptionsBT(newDAta);
        setBillingSeledted(newDAta);
    }

    const setRsultSeledtedAction = ( value ) => {    
        const selectedValue =  Array.isArray(selectedOptionsAL) && selectedOptionsAL.length != 0 && value  ? value[ value.length - 1 ] : value  ? value[0] : []
        let newDAta = []
        if(selectedValue?.value == 'none'){
            newDAta = []
        }else if(selectedValue?.value == 'all'){
            newDAta = agentList.slice(2);
        }else{
            newDAta = value;
        }
        setSelectedOptionsAL(newDAta);
        setRsultSeledted(newDAta);
    }

    const setResultSelectedAction = ( value ) => {    
        const selectedValue =  Array.isArray(selectedOptionsRL) && selectedOptionsRL.length != 0 && value  ? value[ value.length - 1 ] : value  ? value[0] : []
        let newDAta = []
        if(selectedValue?.value == 'none'){
            newDAta = []
        }else if(selectedValue?.value == 'all'){
            newDAta = resultList.slice(2);
        }else{
            newDAta = value;
        }
        setSelectedOptionsRL(newDAta);
        setResultSelected(newDAta);
    }
    
    
    return (
        <Container>
            <TitleBreadCrumbs title="Custom Report">
                <div className="page-breadcrumbs">
                    <Link to="/dashboard">Dashboard</Link>
                    {!show && (
                        <>
                            <span className="current_breadcrumbs">
                                Custom Report{" "}
                            </span>
                        </>
                    )}
                    {show && (
                        <>
                            <Link onClick={() => goBack()} to="#">
                                Custom Report
                            </Link>
                            <span className="current_breadcrumbs">Result </span>
                        </>
                    )}
                </div>
            </TitleBreadCrumbs>
            <Row>
                {!show && (
                    <Col md={12}>
                        <Card className="custom-report-card">
                            <CardBody>
                                <form
                                    className="custom-report-form"
                                    onSubmit={handleSubmit(onSubmit)}
                                >
                                    <h3>Client Filters</h3>
                                    <div className="form__form-group-three">
                                        <div className="form__form-group">
                                            <span className="form__form-group-label">
                                                First Name:
                                            </span>
                                            <div>
                                                <FormField
                                                    name="fname"
                                                    component="input"
                                                    control={control}
                                                    errors={errors}
                                                    placeholder="First Name"
                                                />
                                            </div>
                                        </div>
                                        <div className="form__form-group">
                                            <span className="form__form-group-label">
                                                Last Name:
                                            </span>
                                            <div>
                                                <FormField
                                                    name="lname"
                                                    component="input"
                                                    control={control}
                                                    errors={errors}
                                                    placeholder="Last Name"
                                                />
                                            </div>
                                        </div>
                                        <div className="form__form-group">
                                            <span className="form__form-group-label">
                                                Home Phone:
                                            </span>
                                            <div>
                                                <FormField
                                                    name="phone"
                                                    component="input"
                                                    control={control}
                                                    errors={errors}
                                                    placeholder="Home Phone1"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form__form-group-three">
                                        <div className="form__form-group">
                                            <span className="form__form-group-label">
                                                Home Phone 2:
                                            </span>
                                            <div>
                                                <FormField
                                                    name="phone2"
                                                    component="input"
                                                    control={control}
                                                    errors={errors}
                                                    placeholder="Home Phone2"
                                                />
                                            </div>
                                        </div>
                                        <div className="form__form-group">
                                            <span className="form__form-group-label">
                                                Email Address:
                                            </span>
                                            <div>
                                                <FormField
                                                    name="email"
                                                    component="input"
                                                    control={control}
                                                    errors={errors}
                                                    placeholder="Email Address"
                                                />
                                            </div>
                                        </div>
                                        <div className="form__form-group">
                                            <span className="form__form-group-label">
                                                Outstanding Balance:
                                            </span>
                                            <div>
                                                <FormSelect
                                                    name="outstanding_balance"
                                                    control={control}
                                                    options={OUTSTADING_BALANCE}
                                                    isOject={false}
                                                    objectName="name"
                                                    placeholder="Select Nothing selected"
                                                    defaultValue={"DEFAULT"}
                                                    {...register(
                                                        "outstanding_balance"
                                                    )}
                                                    onChange={(value) =>
                                                        onchangeOutstading(
                                                            value
                                                        )
                                                    }
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <h3>Property Filters</h3>
                                    <div className="form__form-group-three">
                                        <div className="form__form-group">
                                            <span className="form__form-group-label">
                                                Status:
                                            </span>
                                            <div>
                                                <FormSelect
                                                    name="property_status"
                                                    control={control}
                                                    options={PROPERTY_STATUS}
                                                    isOject={false}
                                                    placeholder="Select Nothing selected"
                                                    defaultValue={"DEFAULT"}
                                                    {...register(
                                                        "property_status"
                                                    )}
                                                    onChange={(value) =>
                                                        onchangePropertyStatus(
                                                            value
                                                        )
                                                    }
                                                />
                                            </div>
                                        </div>
                                        <div className="form__form-group">
                                            <span className="form__form-group-label">
                                                Owner::
                                            </span>
                                            <div>
                                                <FormField
                                                    name="owner_name"
                                                    component="input"
                                                    control={control}
                                                    errors={errors}
                                                    placeholder="Owner's Name / Entity Name"
                                                />
                                            </div>
                                        </div>
                                        <div className="form__form-group">
                                            <span className="form__form-group-label">
                                                Account Number:
                                            </span>
                                            <div>
                                                <FormField
                                                    name="accountnum"
                                                    component="input"
                                                    control={control}
                                                    errors={errors}
                                                    placeholder="Account Number"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form__form-group-three">
                                        <div className="form__form-group">
                                            <span className="form__form-group-label">
                                                Type:
                                            </span>
                                            <div>
                                                {/* <FormSelect
                                                    name="property_type"
                                                    placeholder="Nothing selected"
                                                    defaultValue={"DEFAULT"}
                                                    control={control}
                                                    options={propertyType}
                                                    isOject={true}
                                                    objectName="type"
                                                    {...register(
                                                        "property_type"
                                                    )}
                                                    onChange={(value) =>
                                                        onchangeType(value)
                                                    }
                                                /> */}
                                                 <Select 
                                                    options={propertyType} 
                                                    value={selectedOptionsPT}
                                                    isMulti="true" 
                                                    styles={SELECT_STYLES}
                                                    onChange={(value) => setPropertySeledtedAction(value)}
                                                />
                                            </div>
                                        </div>
                                        <div className="form__form-group">
                                            <span className="form__form-group-label">
                                                Address:
                                            </span>
                                            <div className="form__form-group-field">
                                                <div className="form__form-group-icon">
                                                    <MapMarkerIcon />
                                                </div>
                                                <FormField
                                                    name="address"
                                                    component="input"
                                                    control={control}
                                                    errors={errors}
                                                    placeholder="Address"
                                                />
                                            </div>
                                        </div>
                                        <div className="form__form-group">
                                            <span className="form__form-group-label">
                                                City:
                                            </span>
                                            <div className="form__form-group-field">
                                                <div className="form__form-group-icon">
                                                    <MapMarkerIcon />
                                                </div>
                                                <FormField
                                                    name="city"
                                                    component="input"
                                                    control={control}
                                                    errors={errors}
                                                    placeholder="City"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form__form-group-three">
                                        <div className="form__form-group">
                                            <span className="form__form-group-label">
                                                State:
                                            </span>
                                            <div className="form__form-group-field">
                                                <div className="form__form-group-icon">
                                                    <MapMarkerIcon />
                                                </div>
                                                <FormField
                                                    name="state"
                                                    component="input"
                                                    control={control}
                                                    errors={errors}
                                                    placeholder="State"
                                                />
                                            </div>
                                        </div>
                                        <div className="form__form-group">
                                            <span className="form__form-group-label">
                                                Counties:
                                            </span>
                                            <div>
                                                {/* <FormSelect
                                                    name="county"
                                                    control={control}
                                                    options={county}
                                                    isOject={true}
                                                    objectName="name"
                                                    placeholder="Select Country"
                                                    defaultValue={"DEFAULT"}
                                                    {...register("county")}
                                                    onChange={(value) =>
                                                        onchangeCounty(value)
                                                    }
                                                /> */}
                                                <Select 
                                                    options={county} 
                                                    isMulti="true" 
                                                    styles={SELECT_STYLES}
                                                    onChange={(value) => setCountySelected(value)}
                                                />
                                            </div>
                                        </div>
                                        <div className="form__form-group">
                                            <span className="form__form-group-label">
                                                ZipCode:
                                            </span>
                                            <div className="form__form-group-field">
                                                <div className="form__form-group-icon">
                                                    <MapMarkerIcon />
                                                </div>
                                                <FormField
                                                    name="zipcode"
                                                    component="input"
                                                    control={control}
                                                    errors={errors}
                                                    placeholder="ZipCode"
                                                />
                                            </div>
                                        </div>
                                        <div className="form__form-group">
                                            <span className="form__form-group-label">
                                            Exemption Type:
                                            </span>
                                            <div>
                                                <Select 
                                                    options={exemptions} 
                                                    value={selectedOptionsET}
                                                    isMulti="true" 
                                                    styles={SELECT_STYLES}
                                                    onChange={(value) => setExemptionSeledtedAction(value)}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <h3>Billing Filters</h3>
                                    <div className="form__form-group-three form-group-three-controlled">
                                        <div className="form__form-group">
                                            <span className="form__form-group-label">
                                                Tax Year:
                                            </span>
                                            <div>
                                                <FormSelect
                                                    name="year"
                                                    control={control}
                                                    options={years}
                                                    isOject={false}
                                                    placeholder="Noting Selected"
                                                    defaultValue={"DEFAULT"}
                                                    {...register("year")}
                                                    onChange={(value) =>
                                                        onchangeYear(value)
                                                    }
                                                />
                                            </div>
                                        </div>
                                        <div className="form__form-group">
                                            <span className="form__form-group-label">
                                                Result Type:
                                            </span>
                                            <div>
                                                 <Select 
                                                    options={resultList} 
                                                    value={selectedOptionsRL}
                                                    isMulti="true" 
                                                    styles={SELECT_STYLES}
                                                    onChange={(value) => setResultSelectedAction(value)}
                                                />
                                            </div>
                                        </div>
                                        <div className="form__form-group">
                                            <span className="form__form-group-label">
                                                Result Agent:
                                            </span>
                                            <div>
                                                 <Select
                                                    options={agentList}
                                                    value={selectedOptionsAL}
                                                    isMulti="true"
                                                    styles={SELECT_STYLES}
                                                    onChange={(value) => setRsultSeledtedAction(value)}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <h3>Report Columns</h3>
                                    <div className="form__form-group">
                                        <span className="form__form-group-label">
                                            Client Info In Results:
                                        </span>
                                        <div className="form__form-group-field">
                                            <label className="checkbox-label">
                                                <input
                                                    name="client-info"
                                                    type="checkbox"
                                                    value="clientname"
                                                    onChange={handleChange}
                                                />
                                                Client Name
                                            </label>
                                            <label className="checkbox-label">
                                                <input
                                                    name="client-info"
                                                    type="checkbox"
                                                    value="clientphone1"
                                                    onChange={handleChange}
                                                />
                                                Client Home Phone 1
                                            </label>
                                            <label className="checkbox-label">
                                                <input
                                                    name="client-info"
                                                    type="checkbox"
                                                    value="clientphone2"
                                                    onChange={handleChange}
                                                />
                                                Client Home Phone 2
                                            </label>
                                            <label className="checkbox-label">
                                                <input
                                                    name="client-info"
                                                    type="checkbox"
                                                    value="clientemail"
                                                    onChange={handleChange}
                                                />
                                                Client Email
                                            </label>
                                            <label className="checkbox-label">
                                                <input
                                                    name="client-info"
                                                    type="checkbox"
                                                    value="clienthomeaddress"
                                                    onChange={handleChange}
                                                />
                                                Client Home Address
                                            </label>
                                            <label className="checkbox-label">
                                                <input
                                                    name="client-info"
                                                    type="checkbox"
                                                    value="clienthomecity"
                                                    onChange={handleChange}
                                                />
                                                Client Home City
                                            </label>
                                            <label className="checkbox-label">
                                                <input
                                                    name="client-info"
                                                    type="checkbox"
                                                    value="clienthomestate"
                                                    onChange={handleChange}
                                                />
                                                Client Home State
                                            </label>
                                            <label className="checkbox-label">
                                                <input
                                                    name="client-info"
                                                    type="checkbox"
                                                    value="clienthomezip"
                                                    onChange={handleChange}
                                                />
                                                Client Home Zip
                                            </label>
                                        </div>
                                    </div>
                                    <div className="form__form-group">
                                        <span className="form__form-group-label">
                                            Property Info In Results:
                                        </span>
                                        <div className="form__form-group-field">
                                            <label className="checkbox-label">
                                                <input
                                                    name="property-info"
                                                    type="checkbox"
                                                    value="propertyaddress"
                                                    id="propertyaddress"
                                                    onChange={
                                                        handleChangeProperty
                                                    }
                                                />
                                                Property Address
                                            </label>
                                            <label className="checkbox-label">
                                                <input
                                                    name="property-info"
                                                    type="checkbox"
                                                    id="propertycity"
                                                    value="propertycity"
                                                    onChange={
                                                        handleChangeProperty
                                                    }
                                                />
                                                Property City
                                            </label>
                                            <label className="checkbox-label">
                                                <input
                                                    name="property-info"
                                                    type="checkbox"
                                                    id="propertystate"
                                                    value="propertystate"
                                                    onChange={
                                                        handleChangeProperty
                                                    }
                                                />
                                                Property State
                                            </label>
                                            <label className="checkbox-label">
                                                <input
                                                    name="property-info"
                                                    type="checkbox"
                                                    id="propertyzip"
                                                    value="propertyzip"
                                                    onChange={
                                                        handleChangeProperty
                                                    }
                                                />
                                                Property Zip
                                            </label>
                                            <label className="checkbox-label">
                                                <input
                                                    name="property-info"
                                                    type="checkbox"
                                                    id="propertycounty"
                                                    value="propertycounty"
                                                    onChange={
                                                        handleChangeProperty
                                                    }
                                                />
                                                Property County
                                            </label>
                                            <label className="checkbox-label">
                                                <input
                                                    name="property-info"
                                                    type="checkbox"
                                                    id="propertyisactive"
                                                    value="propertyisactive"
                                                    onChange={
                                                        handleChangeProperty
                                                    }
                                                />
                                                Property isActive
                                            </label>
                                            <label className="checkbox-label">
                                                <input
                                                    name="property-info"
                                                    type="checkbox"
                                                    id="propertyowner"
                                                    value="propertyowner"
                                                    onChange={
                                                        handleChangeProperty
                                                    }
                                                />
                                                Property Owner
                                            </label>
                                            <label className="checkbox-label">
                                                <input
                                                    name="property-info"
                                                    type="checkbox"
                                                    id="propertytype"
                                                    value="propertytype"
                                                    onChange={
                                                        handleChangeProperty
                                                    }
                                                />
                                                Property Type
                                            </label>
                                            <label className="checkbox-label">
                                                <input
                                                    name="property-info"
                                                    type="checkbox"
                                                    id="propertyaccountnum"
                                                    value="propertyaccountnum"
                                                    onChange={
                                                        handleChangeProperty
                                                    }
                                                />
                                                Property Account Number
                                            </label>
                                        </div>
                                    </div>
                                    <div className="custom-report-btns text-right">
                                        <Button
                                            className="my-0  btn-sm white-nowrap btn btn-secondary"
                                            onClick={reset}
                                        >
                                            <UndoIcon /> Reset
                                        </Button>
                                        <Button
                                            color="primary"
                                            type="submit"
                                            className="my-1 primary-2 white-nowrap btn btn-primary"
                                        >
                                            <SearchIcon /> Search
                                        </Button>
                                    </div>
                                </form>
                            </CardBody>
                        </Card>
                    </Col>
                )}
                {show && (
                    <Col md={12}>
                        <Card className="card-controlled">
                            <CardBody>
                            <DataTable
                                //title="Users"
                                columns={columns}
                                data={dataTable}
                                progressPending={loading}
                                progressComponent={<TableLoading />}
                                pagination={false}
                                paginationServer
                                paginationTotalRows={totalRows}
                                paginationDefaultPage={currentPage}
                                striped={TABLE_SETTINGS.STRIPED}
                                highlightOnHover={true}
                                onChangeRowsPerPage={handlePerRowsChange}
                                onChangePage={handlePageChange}
                                onSelectedRowsChange={({ selectedRows }) =>
                                    console.log(selectedRows)
                                }
                                paginationPerPage={TABLE_SETTINGS.PAGINATION_PER_PAGE}
                                paginationRowsPerPageOptions={
                                    TABLE_SETTINGS.ROW_PER_PAGE
                                }
                                noDataComponent={<Empty />}
                            />
                                <CustomPagination
                                    rowCount={rowCount}
                                    currentPage={currentPage}
                                    totalRows={totalRows}
                                    perPage={TABLE_SETTINGS.PAGINATION_PER_PAGE}
                                    handlePageChange={handlePageChange}
                                />
                                <div className="mt-4 text-center">
                                    <Button
                                        onClick={() =>
                                            exportReport('excel')
                                        }
                                        disabled={isSubmit}
                                    >
                                        {isSubmit ? (
                                            <Spinner
                                                size="sm"
                                                color="secondary"
                                                className="mr-2"
                                            />
                                        ) : (
                                            ""
                                        )}
                                        Export to Excel
                                    </Button>
                                    <Button
                                        onClick={() =>
                                            exportReport('pdf')
                                        }
                                        disabled={isSubmit}
                                    >
                                        {isSubmit ? (
                                            <Spinner
                                                size="sm"
                                                color="link"
                                                className="btn-success"
                                            />
                                        ) : (
                                            ""
                                        )}
                                        Export to PDF
                                    </Button>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                )}
            </Row>
        </Container>
    );
};

export default reduxForm({
    form: "custom_report",
})(CustomReport);
