/* eslint-disable */
import React, {useState, useEffect} from 'react';
import Modal from 'react-bootstrap/Modal'
import { Button, Spinner } from 'reactstrap'
import CloseIcon from '@/images/closeIcon'
import Select from 'react-select'
import ApiServices from "@/utils/ApiServices";
import { errorMsgCustom } from "@/utils/message_herlper";

const ModalClient = ({open, close , generateClientReport}) => {  
    const [clientList, setClientList] = useState([])
    const [clientSeledted, setClientSeledted] = useState(null)

    useEffect(() => {
        getAllCient();
    },[open]) 
    

    const getAllCient = () => {
        ApiServices.getallClient().then((result) => {   
                let results = result?.data
                let ResultAgentList = []
                for (let index = 0; index < results.length; index++) {
                    ResultAgentList.push({ value: results[index].id, label: results[index].name })
                }
                setClientList(ResultAgentList)
            })
            .catch((error) => {
              
            });
    }

    const selectClient = () => {
        if(!clientSeledted){
            errorMsgCustom('Please select a client')
            return
        }
        generateClientReport(clientSeledted.value)
    }
   

    return(
        <>
            <Modal className="moderationModal" show={open} onHide={close} size="md" centered >
                <Button onClick={close} className="modalconfirm-closebtn"><CloseIcon color="#383938" /></Button>
                <Modal.Body className="text-center" >
                    <h3 className="mt-3 mb-3"><b>Select Client!</b></h3>
                    <Select 
                        options={clientList} 
                        //styles={SELECT_STYLES}
                        onChange={(value) => setClientSeledted(value)}
                    />
                    <div className="pt-4">
                        <Button color="primary" className="mb-0" onClick={selectClient}>Select</Button>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default React.memo(ModalClient);