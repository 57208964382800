/* eslint-disable */
import React, { useState, useEffect, useMemo, useRef } from "react";
import DataTable from "react-data-table-component";
import ApiServices from "@/utils/ApiServices";
import Empty from "@/components/Empty";
import CheckIcon from "@/images/checkIcon";
import CheckaAOA from "@/images/checkaAOA";
import CloseAOA from "@/images/closeAOA";
import { useHistory } from "react-router-dom";
import { Button, Row, Col, Spinner } from "reactstrap";
import moment from "moment";
import { TABLE_SETTINGS, USERLEVELS } from "@/utils/constant";
import TableLoading from "@/components/TableLoading";
import { successMsg, globalError } from "@/utils/message_herlper";
import ModalConfirm from "@/components/ModalConfirm";
import CustomPagination from '@/components/CustomPagination'

const AaoReady = ({ account_number, owner_name, legal_desc }) => {
    return (
        <div>
            <ul className="aoa-ready">
                {owner_name ? (
                    <li className="good">
                        <CheckaAOA /> {owner_name}
                    </li>
                ) : (
                    <li className="not-good">
                        <CloseAOA /> No Owner Name
                    </li>
                )}
                {account_number ? (
                    <li className="good">
                        <CheckaAOA /> {account_number}
                    </li>
                ) : (
                    <li className="not-good">
                        <CloseAOA /> No Account Number
                    </li>
                )}
                {legal_desc ? (
                    <li className="good">
                        <CheckaAOA /> {legal_desc}
                    </li>
                ) : (
                    <li className="not-good">
                        <CloseAOA /> No Legal Description
                    </li>
                )}
            </ul>
        </div>
    );
};

const PropertiesTable = ({ title, status, is_new, isTotal, isReload, isReloaded, isPageLoad }) => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [submit, setSubmit] = useState(false);
    const [confirm, setConfirm] = useState(false);
    const [pageLoad, setPageLoad] = useState(false);
    const [rowCount, setRowCount] = useState(0);
    const [perPage, setPerPage] = useState(TABLE_SETTINGS.PAGINATION_PER_PAGE);
    const [currentPage, setCurrentPage] = useState(1);
    const history = useHistory();
    const page_ref = useRef(1);
    const user_ref = useRef(0);
    const id_ref = useRef(0);
    const userData = JSON.parse( localStorage.getItem('userData') )
    const handleViewDetails = (propID) => {
        history.push({
            pathname: `/property/details/${propID}`,
        });
    };
    if(userData?.userlevel === USERLEVELS.CLIENT){
        user_ref.current = userData?.id
    }

    const fetchListing = async (page, size = perPage) => {
        page_ref.current = page;
        let app_call = 'clientsActiveProperties';
        // if(userData?.userlevel === USERLEVELS.CLIENT){
        //     app_call = 'clientsActiveProperties2';
        // }

        ApiServices[app_call]({
            page: page,
            per_page: size,
            pstatus: status,
            ulevel: userData?.userlevel,
            id: user_ref.current,
            is_new: is_new,
        })
            .then((result) => { 
                setData(result?.data?.data);
                setTotalRows(result?.data?.total);
                setRowCount(result.data.data.length);
                setLoading(false);
                if(isTotal){
                    isTotal(result.data.total);
                }
                setPageLoad(true)
            })
            .catch(function (error) {});
    };

    useEffect(() => {
        fetchListing(1);
    }, []);

    useEffect(() => {
        fetchListing(1);
    },[isReloaded]);

    const columns = useMemo(() => [
        {
            name: "Date added",
            selector: "created_at",
            sortable: false,
            width: "150px",
        },
        {
            name: "Owner's Name",
            selector: "owner_name",
            sortable: false,
            width: "150px",
        },
        {
            name: "Address, City, State Zip Code",
            selector: "property_address",
            sortable: false,
            width: "400px",
        },
        {
            name: "County",
            selector: "county",
            sortable: false,
            width: "150px",
        },
        {
            name: "AOA Ready",
            selector: "aoaready",
            sortable: false,
            width: "250px",
        },
        {
            cell: (row) => (
                <>
                    <Button
                        className="my-1 white-nowrap"
                        onClick={() => handleViewDetails(row.id)}
                        color="secondary"
                    >
                        Details
                    </Button>
                    { is_new && userData?.userlevel !== USERLEVELS.CLIENT  && userData?.userlevel !== USERLEVELS.STAFF && userData?.userlevel !== USERLEVELS.STAFF_ROLE && (
                        <Button
                            className="my-1 primary-2 white-nowrap"
                            color="primary"
                            onClick={() => handleMark(row.id)}
                        >
                            <CheckIcon color="#ffffff" /> Mark ready
                        </Button>
                    )}
                    
                </>
            ),
            right: true,
            width: "auto",
        },
    ]);

    const columnsClient = useMemo(() => [
        {
            name: "Date added",
            selector: "created_at",
            sortable: false,
            width: "150px",
        },
        {
            name: "Owner's Name",
            selector: "owner_name",
            sortable: false,
            width: "150px",
        },
        {
            name: "Address, City, State Zip Code",
            selector: "property_address",
            sortable: false,
            width: "400px",
        },
        {
            name: "County",
            selector: "county",
            sortable: false,
            width: "150px",
        },
        {
            cell: (row) => (
                <>
                    <Button
                        className="my-1 white-nowrap"
                        onClick={() => handleViewDetails(row.id)}
                        color="secondary"
                    >
                        Details
                    </Button>
                    { is_new && userData?.userlevel !== USERLEVELS.CLIENT && userData?.userlevel !== USERLEVELS.STAFF_ROLE && (
                        <Button
                            className="my-1 primary-2 white-nowrap"
                            color="primary"
                            onClick={() => handleMark(row.id)}
                        >
                            <CheckIcon color="#ffffff" /> Mark ready
                        </Button>
                    )}
                    
                </>
            ),
            right: true,
            width: "auto",
        },
    ]);

    const handlePageChange = (page) => {
        fetchListing(page);
        setCurrentPage(page);
    };

    const handlePerRowsChange = async (newPerPage, page) => {
        fetchListing(page, newPerPage);
        setPerPage(newPerPage);
    };

    const handleMark = (value) => {
        id_ref.current = value;
        setConfirm(true);
    };

    const onMarkReady = () => {
        setSubmit(true);
        ApiServices.markReadyProperty(id_ref.current)
            .then((result) => {
                successMsg("PROPERTY_MARKED_SUCCESS");
                setConfirm(false);
                fetchListing(1);
                if(isReload){
                    isReload()
                }
                setTimeout(() => {
                     setSubmit(false);
                }, 1000);
            })
            .catch((error) => {
                setSubmit(false);
                globalError();
            });
    };

    const dataTable = [];
    const list = data;
    for (let index = 0; index < list.length; index++) {
        dataTable.push({
            id: list[index].id,
            created_at: moment
                .utc(list[index].created_at)
                .local()
                .format("MMM DD, YYYY hh:mm A"),
            owner_name: list[index].owner_name,
            property_address: `${list[index].property_address}, ${
                list[index]?.property_city || ""
            }, ${list[index]?.property_state || ""} ${ 
                list[index]?.property_zip || ""
            }`,
            county: list[index].country_name,
            aoaready: <AaoReady owner_name={list[index].owner_name}  account_number={list[index].account_number} legal_desc={list[index].legal_desc}  />
        });
    }

    return (
        <>
            <ModalConfirm
                submit={submit}
                title="Mark Property  Ready"
                description="Are you sure you want to mark this property  ready?"
                btnText="Mark  Ready"
                open={confirm}
                Onsubmit={() => onMarkReady()}
                close={() => setConfirm(false)}
            />
            <Row>
                <Col md={12} className="mb-2">
                    <div className="dashboard-card-title mt-2">
                        <p>{title}</p>
                    </div>
                    {!pageLoad ?<Spinner size="sm" color="warning" /> :''}
                    
                </Col>
                <Col md={12}>
                    {pageLoad ?
                        <>
                            <DataTable
                            columns={userData?.userlevel === USERLEVELS.ADMIN ? columns : columnsClient}
                            data={dataTable}
                            progressPending={loading}
                            pagination={false}
                            progressComponent={<TableLoading />}
                            paginationServer
                            paginationTotalRows={totalRows}
                            paginationDefaultPage={currentPage}
                            striped={TABLE_SETTINGS.STRIPED}
                            highlightOnHover={true}
                            onChangeRowsPerPage={handlePerRowsChange}
                            onChangePage={handlePageChange}
                            onSelectedRowsChange={({ selectedRows }) =>
                                console.log(selectedRows)
                            }
                            paginationPerPage={TABLE_SETTINGS.PAGINATION_PER_PAGE}
                            paginationRowsPerPageOptions={
                                TABLE_SETTINGS.ROW_PER_PAGE
                            }
                            noDataComponent={<Empty />}
                        />
                        
                    
                        <CustomPagination rowCount={rowCount} currentPage={currentPage} totalRows={totalRows} perPage={TABLE_SETTINGS.PAGINATION_PER_PAGE} handlePageChange={handlePageChange} />
                        </>
                    : ''}
                    
                </Col>
            </Row>
        </>
    );
};

export default React.memo(PropertiesTable);
