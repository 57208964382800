/* eslint-disable */
// export const API = "http://rpt.local/api/";
//export const API = "http://rpt.test/api/";
//export const API = "http://128.199.87.194/api/";
//export const API = "http://127.0.0.1:8000/api/";
//export const HOME_API = "http://localhost:3000/";

// export const API = "http://republicpropertytax-api.test/api/";
// export const HOME_API = "http://republicpropertytax-api.test/";

export const API = "https://api.republicpropertytax.com/api/";
export const HOME_API = "https://api.republicpropertytax.com/";


export const USERLEVELS = {
    'STAFF_ROLE' : 4,
    'ADMIN' : 3,
    'STAFF' : 2,
    'CLIENT' : 1,
}
export const TIMEZONE = 'America/Tegucigalpa'
export const ENDPOINT = {
    'PROPERTY' : 'property',
    'CLIENT' : 'client',
    'COUNTY' : 'county',
    'NOTE' : 'note',
    'PROPERTY_BILLING' : 'property-billing',
    'PROPERTY_DOCUENT' : 'property-files',
    'PROPERTY_TYPE' : 'property-type',
    'REPORT' : 'report',
    'EXPORT' : 'export',
    'MAIL' : 'mail',
    'AUTH' : 'auth',
    'EXEMPTION' : 'exemption',
}

export const list2FA = [
    {id: 0, name: 'Disabled'},
    {id: 1, name: 'Enabled'},    
];

export const COUNTRY_LIST = ["Harris", "Fort bend", "Brazoria", "Galveston", "Montgomery", "Travis", "Waller", "Austin", "Hays", "Williamson", "Guadalupe", "Fayette", "Calhoun", "Bexar", "Rockwall", "Chambers", "Polk", "Walker", "Liberty", "Matagorda", "Grimes", "Erath", "Wharton", "Tarrant", "Brazos", "Comal", "Ector", "Collin", "Victoria county", "Hidalgo", "Wise", "Burleson", "Bell", "Dallas", "San jacinto", "Aransas", "Nueces", "Jackson", "Washington", ];
export const PROPERTY_TYPE_LIST = ['Residential',   'Apartment/Duplex', 'Vacant Lot', 'Commercial Vacant Lots', 'Agricultural Lands', 'Commercial', 'BPP - RPT', 'BPP - Client'];


export const TOAST_SETTINGS = { 
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        position: 'top-right',
}

export const MESSAGES = {
    'LOGIN_SUCCESS' : 'Successfully logged in!',
    'REGISTER_SUCCESS' : 'You have successfully registered.',
    'PROPERTY_SUCCESS' : 'New Property Created',
    'CLIENT_SUCCESS' : 'New Client Created',
    'CLIENT_EDIT_SUCCESS' : 'Client Details Successfully updated',
    'CLIENT_MARKED_SUCCESS' : 'New Client Successfully MARKED',
    'PROPERTY_MARKED_SUCCESS' : 'New Property Successfully MARKED',
    'NOTES_SUCCESS' : 'New Notes Created',
    'OUTSTANDING_SUCCESS': 'The outstanding balance that you selected was sucessfully updated for this user.',
    'PASSWORD_SUCCESS' : 'The password that you entered was sucessfully updated for this user.',
    'STATUS_SUCCESS' : 'Client status successfully changed',
    'PROPERTY_UPDATE_SUCCESS' : 'Property  successfully updated.',
    'PROPERTY_BILLING_SUCCESS' : 'Property Billing  successfully added.',
    'PROPERTY_BILLING_EDIT_SUCCESS' : 'Property Billing  successfully updated.',
    'PROPERTY_DELETE_SUCCESS' : 'Property Document  successfully deleted.',
    'PROPERTY_DOCUMENT_SUCCESS' : 'Property Document  successfully uploaded.',
    'PROFILE_SUCCESS' : 'Account info successfully updated.',
    'STAFF_EDIT_SUCCESS' : 'Staff info successfully updated.',
    'COUNTY_SUCCESS' : 'New County Created',
    'COUNTY_UPDATE_SUCCESS' : 'County successfully updated.',
    'PT_SUCCESS' : 'New Property Type successfully created.',
    'PT_UPDATE_SUCCESS' : 'Property Type successfully updated.',
    'EROR_SEARCH' : 'Please enter a keyword to search.',
    'SETTINGS_UPDATE' : 'Acknowledgment successfully updated.',
    'MULTI_UPLOAD_SAVE' : 'Documents successfully saved.',
}

export const TABLE_SETTINGS = {
    'ROW_PER_PAGE' : [10, 20, 50, 100],
    'PAGINATION_PER_PAGE' : 10,
    'STRIPED': false,
}

export const USERLEVEL = [
    {id: 3, name: 'Admin'},
    {id: 2, name: 'Staff'},
    {id: 4, name: 'Staff Role'},
]

export const USERSTATUS = [
    {id: 1, name: 'Active'},
    {id: 2, name: 'Inactive'},
]

export const COOKIEMAXAGE = {  maxAge: 345600 }
//expiration 4 days auto logout

export const SELECT_STYLES = {
    control: (base, state) => ({
        ...base,
        color: state.isSelected ? 'red' : '#009adc',
        boxShadow: 'none',
        '&:hover': {
            border: '1px solid #009adc',
        },
        borderRadius: '0px'
    }),
    option: (styles, state) => ({
        ...styles,
        color: state.isSelected ? "#009adc" : styles.color,
        backgroundColor: state.isSelected ? "#009adc" : styles.color,
        borderBottom: "1px solid rgba(0, 0, 0, 0.125)",
        "&:hover": {
          color: "#FFF",
          backgroundColor: "#009adc"
        }
      }),
};

export const US_STATES = [{"name": "Alabama", "id": "AL"}, {"name": "Alaska", "id": "AK"}, {"name": "Arizona", "id": "AZ"}, {"name": "Arkansas", "id": "AR"}, {"name": "California", "id": "CA"}, {"name": "Colorado", "id": "CO"}, {"name": "Connecticut", "id": "CT"}, {"name": "Delaware", "id": "DE"}, {"name": "District Of Columbia", "id": "DC"}, {"name": "Florida", "id": "FL"}, {"name": "Georgia", "id": "GA"}, {"name": "Hawaii", "id": "HI"}, {"name": "Idaho", "id": "ID"}, {"name": "Illinois", "id": "IL"}, {"name": "Indiana", "id": "IN"}, {"name": "Iowa", "id": "IA"}, {"name": "Kansas", "id": "KS"}, {"name": "Kentucky", "id": "KY"}, {"name": "Louisiana", "id": "LA"}, {"name": "Maine", "id": "ME"}, {"name": "Maryland", "id": "MD"}, {"name": "Massachusetts", "id": "MA"}, {"name": "Michigan", "id": "MI"}, {"name": "Minnesota", "id": "MN"}, {"name": "Mississippi", "id": "MS"}, {"name": "Missouri", "id": "MO"}, {"name": "Montana", "id": "MT"}, {"name": "Nebraska", "id": "NE"}, {"name": "Nevada", "id": "NV"}, {"name": "New Hampshire", "id": "NH"}, {"name": "New Jersey", "id": "NJ"}, {"name": "New Mexico", "id": "NM"}, {"name": "New York", "id": "NY"}, {"name": "North Carolina", "id": "NC"}, {"name": "North Dakota", "id": "ND"}, {"name": "Ohio", "id": "OH"}, {"name": "Oklahoma", "id": "OK"}, {"name": "Oregon", "id": "OR"}, {"name": "Pennsylvania", "id": "PA"}, {"name": "Rhode Island", "id": "RI"}, {"name": "South Carolina", "id": "SC"}, {"name": "South Dakota", "id": "SD"}, {"name": "Tennessee", "id": "TN"}, {"name": "Texas", "id": "TX"}, {"name": "Utah", "id": "UT"}, {"name": "Vermont", "id": "VT"}, {"name": "Virginia", "id": "VA"}, {"name": "Washington", "id": "WA"}, {"name": "West Virginia", "id": "WV"}, {"name": "Wisconsin", "id": "WI"}, {"name": "Wyoming", "id": "WY"} ]

export const COUNTY_SITES = [
    {county: 'Austin', 'site': 'https://esearch.austincad.org/'},
    {county: 'Bexar', 'site': 'http://bexar.trueautomation.com/clientdb/?cid=110'},
    {county: 'Brazoria', 'site': 'https://propaccess.trueautomation.com/ClientDB/PropertySearch.aspx?cid=51'},
    {county: 'Brazos', 'site': 'https://esearch.brazoscad.org/'},
    {county: 'Burleson', 'site': 'https://esearch.burlesonappraisal.com/'},
    {county: 'Calhoun', 'site': 'https://propaccess.trueautomation.com/clientdb/?cid=83'},
    {county: 'Chambers', 'site': 'http://www.chamberscad.org/(S(ovou5fuyaquabjg1ygqyr3au))/search.aspx?clientid=chamberscad'},
    {county: 'Collin', 'site': 'https://www.collincad.org/propertysearch'},
    {county: 'Comal', 'site': 'https://propaccess.trueautomation.com/clientdb/?cid=56'},
    {county: 'Dallas', 'site': 'https://www.dallascad.org/searchowner.aspx'},
    {county: 'Fort Bend', 'site': 'https://esearch.fbcad.org/'},
    {county: 'Galveston', 'site': 'https://propaccess.trueautomation.com/clientdb/?cid=81'},
    {county: 'Grimes', 'site': 'https://www.grimescad.org/'},
    {county: 'Guadalupe', 'site': 'https://propaccess.trueautomation.com/clientdb/?cid=2'},
    {county: 'Harris', 'site': 'https://hcad.org/quick-search/'},
    {county: 'Hays', 'site': 'https://esearch.hayscad.com/'},
    {county: 'Hidalgo', 'site': 'http://propaccess.hidalgoad.org/clientdb/?cid=1'},
    {county: 'Jackson', 'site': 'https://jacksoncad.org/'},
    {county: 'Liberty', 'site': 'https://esearch.libertycad.com/'},
    {county: 'Matagorda', 'site': 'https://propaccess.trueautomation.com/clientdb/?cid=72'},
    {county: 'MONTGOMERY', 'site': 'https://esearch.mcad-tx.org/'},
    {county: 'Nueces', 'site': 'https://propaccess.trueautomation.com/clientdb/?cid=75'},
    {county: 'Polk', 'site': 'https://propaccess.trueautomation.com/clientdb/?cid=93'},
    {county: 'San Jacinto', 'site': 'https://propaccess.trueautomation.com/clientdb/?cid=22'},
    {county: 'Tarrant', 'site': 'https://www.tad.org/property-search/'},
    {county: 'Travis', 'site': 'http://propaccess.traviscad.org/clientdb/?cid=1'},
    {county: 'Victoria', 'site': 'https://propaccess.trueautomation.com/clientdb/?cid=13'},
    {county: 'Waller', 'site': 'https://propaccess.trueautomation.com/clientdb/?cid=92'},
    {county: 'Walker', 'site': 'https://esearch.walkercad.org/'},
    {county: 'Washington', 'site': 'https://washingtoncad.org/'},
    {county: 'Wharton', 'site': 'https://esearch.whartoncad.net/'},
    {county: 'Williamson', 'site': 'https://tax.wilco.org/'},
]

export const ARBITRATION_1 = [
    {id: '', name: '- select one -'},
    {id: 'arbitration', name: 'Arbitration Lead'},
    {id: 'litigation', name: 'Litigation Lead'}
]