/* eslint-disable */
import React, { useState, useEffect } from 'react';
import toast from 'react-hot-toast';
import moment from 'moment';
import { Card, CardBody, Col, Container, Row, Tooltip, Button, Collapse } from 'reactstrap';
import { Link, useLocation, useParams, useHistory } from 'react-router-dom';
import Tabs from 'react-bootstrap/Tabs'
import Tab from 'react-bootstrap/Tab'
import ActivitiesList  from '@/components/ActivitiesList'
import PropertyNotes from './propertyNote';
import PropertyBilling from './components/PropertyBilling'
import PropertyBillingClient from './components/PropertyBillingClient'
import PropertyDocument from './property-documents'
import PencilIcon from '@/images/pencilIcon';
import StopIcon from '@/images/stopIcon';
import CalendarIcon from '@/images/calendarIcon';
import AngleArrowLeft from '@/images/angleArrowLeft';
import AccountSettingsIcon from 'mdi-react/AccountSettingsIcon'
import TitleBreadCrumbs from '@/components/TitleBreadCrumbs'
import './property.css'
import { successMsg, globalError, formError, successMsgCustom } from "@/utils/message_herlper"
import ApiServices from "@/utils/ApiServices";
import ModalConfirm from '@/components/ModalConfirm'
import ModalAoaAdress from '@/components/ModalAoaAdress'
import DateFnsUtils from '@date-io/date-fns';
import {
  DatePicker,
  TimePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';

import "react-datepicker/dist/react-datepicker.css";
import { USERLEVELS, COUNTY_SITES } from "@/utils/constant";
import PropertyDescription from "./propertyDescription"
import { toastLoadingUpload} from "@/utils/message_herlper"
import CloseIcon from "@/images/closeIcon";
import ModalEditNotes from '@/components/ModalEditNotes'
import ArbitrationForm from './components/ArbitrationForm';

const ClientDetails = () => {

    let { id, documents } = useParams()
    const location = useLocation()
    const history = useHistory()
    const userData = JSON.parse( localStorage.getItem('userData') )

    const [details, setDetails] = useState(null);
    const [dataConfirm, setDataConfirm] = useState(null);
    const [aoaShow, setAoaShow] = useState(false);
    const [aoaShow2, setAoaShow2] = useState(false);
    const [show, setShow] = useState(false);
    const [show2, setShow2] = useState(false);
    const [deleteProperty, setDeleteProperty] = useState(false);
    const [submitDocument, setSubmitDocument] = useState(false);
    const [active, setActive] = useState(false);
    const [submit, setSubmit] = useState(false);
    const [generalDocoments, setgeneralDocoments] = useState([]);
    const [resultDocoments, setResultDocoments] = useState([]);
    const [aoaAdressList, setAoaAdressList] = useState([]);
    const [docoment, setDocument] = useState(false);
    const [submitGeneral, setSubmitGeneral] = useState(false);
    const [submitResult, setSubmitResult] = useState(false);
    const [firstData, setFirstDAta] = useState(null);
    // const [selectedDate, handleDateChange] = useState(new Date());
    // const [selectedDate2, handleDateChange2] = useState(new Date());
    const [selectedDate, handleDateChange] = useState(null);
    const [selectedDate2, handleDateChange2] = useState(null);    
    const [tooltipOpen, ] = useState(false);
    const [countBilling,setCountBilling] = useState(0)
    const [showHearingDate,setShowHearingDate] = useState(false)

    const toggle = () => setTooltipOpen(!tooltipOpen);
    const [notes, setNotes] = useState([]);
    const [isNotes, setIsNotes] = useState(false);
    const [resetNotes, setResetNotes] = useState(false);

    const [activeTab, setActiveTab] = useState(userData.userlevel !== USERLEVELS.CLIENT ? 'propertyBilling'  :'document')
    const [tempHearingStatus, setTempHearingStatus] = useState('');
    const [hearingStatus, setHearingStatus] = useState(" Thank you for registering with Republic Property Tax. We will provide updates as the season progresses. If you have any questions or concerns please contact us at info@republicpropertytax.com. ")
    const [noteCount, setNoteCount] = useState(0);
   
    const [aoaReady, setAoaReady] = useState(false);
    const [user, setUser] = useState(0);
   
    const [showHearing, setShowHearing] = useState(false);
    const [activitiesCount, setActivitiesCount] = useState(0);
    const [propDetailsOpen, setPropDetailsOpen] = useState(false)
    const [tooltipOpen2, settooltipOpen2] = useState(false)
    const [activities, setActivities] = useState([]);
    const [showEdit, setShowEdit] = useState(false);
    const [editNotesDetails,setEditNotesDetails] = useState(null)
    const [isSubmitNotes, setSubmitNotes] = useState(false);
    const [multipleUploads, setMultipleUploads] = useState([]);
    const [arbitration, setArbitration] = useState(null);
    
    const handlePropDetails = () => {
        setPropDetailsOpen(!propDetailsOpen)
    }

    const handleSetActivitiesCount = ( count ) => {
        setActivitiesCount( count )
    }

    useEffect(() => { 
        propertyDetails();
        setActive();
        propertyDocuments(1);
        propertyDocuments(2);
        fetchNotes();
        fetchActivities();
        multipleUplaods();
        if(documents){
            setActiveTab('document')
        }
        arbitrationDetails();
    }, [id]);

    

    const arbitrationDetails = () => {
        ApiServices.arbitrationDetails(id).then((result) => {  
            setArbitration( result.data || null)
        })
        .catch((error) => {
            globalError()
        })
    }

    const fetchActivities = () => {
        ApiServices.clientActivities(id).then((result) => { 
           var tempAct = result.data;
            ApiServices.viewPropPending(id).then((result) => {
                var viewPendingTemp = result.data

                //&& viewPendingTemp.status === 0
                if(viewPendingTemp.length !== 0 ){    
                    //var data = tempAct.concat(viewPendingTemp);
                    //setActivities(data)
                    setActivities(tempAct)
                }else{
                    setActivities(tempAct)
                }
            })
            .catch((error) => {
                globalError()
            })
        })
        .catch((error) => {
            globalError()
        })
    }

    const multipleUplaods = () => {
        ApiServices.getMultiUploadsProperty(id).then((result) => {  
            setMultipleUploads(result.data)
        })
        .catch((error) => {
            globalError()
        })
    }
    
    const fetchNotes = () => {
        ApiServices.fetchNotesProperty(id).then((result) => {  
            setNotes(result.data)
        })
        .catch((error) => {
            globalError()
        })
    }

    const propertyDetails = () => { 
        ApiServices.propertyDetails(id).then((result) => {   
            let property_details  = result?.data
            let aoaAdressListArray = []
            //if(property_details?.aoa_address) aoaAdressListArray.push(property_details?.aoa_address)
            aoaAdressListArray.push(property_details?.homeaddress)
            aoaAdressListArray.push(property_details?.property_address)
           // aoaAdressListArray.push(`${property_details.homecity} ${property_details.homestate} ${property_details.homezip}`)
            aoaAdressListArray.push("New Mailing Address")
            if(property_details?.hearing_date){
                setHearingStatus("Your hearing date is scheduled for " + (property_details?.hearing_date ? moment(property_details?.hearing_date).format('MMM DD, YYYY') : property_details?.hearing_date) + ". If you have any questions, email us at info@republicpropertytax.com");
            }
            if(property_details?.aoa_sent_date){
                //setHearingStatus("Your hearing is complete and the results are below. If you have any questions, email us at info@republicpropertytax.com ");
            }
            if(property_details?.hearing_date){
                handleDateChange(property_details?.hearing_date)
                var a = moment(property_details?.hearing_date);
                var b =  moment(new Date());
                let diff_days = a.diff(b, 'days');
                if(diff_days < 0){
                    setShowHearingDate(true)
                }
            }
            if(property_details?.aoa_sent_date){
                handleDateChange2(property_details?.aoa_sent_date)
            }
            setAoaAdressList(aoaAdressListArray)
            setDetails(result?.data);
            setAoaReady(result?.data?.aofaready === 'Y' ? true : false);
            setActive(result?.data?.pstatus === 'A' ? true : false);
        })
        .catch((error) => {
            globalError();
        })
    }

    const propertyDocuments = (type) => { 
        ApiServices.propertyDocuments({id: id, type: type}).then((result) => {    
            if(type === 1) {
                setgeneralDocoments(result.data)
            }

            if(type === 2) {
                setResultDocoments(result.data)
            }
             
        })
        .catch((error) => {
            globalError();
        })
    }

    const updateStatus  = () => {
        setSubmit(true); 
        let params = {
            id: parseInt(id),
            user_id: details.id,
            pstatus: dataConfirm,
            address: details?.property_address,
        }
        ApiServices.updateStatusProperties(params).then((result) => {  
            fetchActivities();  
            setTimeout(() =>{
                setSubmit(false); 
                setShow(false);  
                setShow2(false);  
                successMsg('PROPERTY_UPDATE_SUCCESS')
                setActive(dataConfirm === 'A' ? true : false);
                setDataConfirm(null)
            },1000)
        })
        .catch((error) => {
            setSubmit(false); 
            globalError()
        })
    }

    const readyAoa  = () => {
        setSubmit(true); 
        let params = {
            id: id,
            aofaready: dataConfirm,
        }
        ApiServices.updateAofaready(params).then((result) => {    
            fetchActivities(); 
            setTimeout(() =>{
                setSubmit(false); 
                setAoaShow(false);  
                setAoaShow2(false);  
                successMsg('PROPERTY_UPDATE_SUCCESS')
                setAoaReady(dataConfirm === 'Y' ? true : false);
                setDataConfirm(null)
            },1000)
        })
        .catch((error) => {
            setSubmit(false);
            globalError()
        })
    }

    const readyAoaSubmit  = (value) => { 
      setSubmit(true); 
        let params = {
            id: id,
            aofaready: dataConfirm,
            aoa_address: value.new_address,
            aoaAdress: value.aoaAdress,
            address: value.aoaAdressValue,
            city: value.city,
            state: value.state,
            zip: value.zip,
        }
        ApiServices.updateAofareadyAddress(params).then((result) => {    
            propertyDetails()
            fetchActivities(); 
            setTimeout(() =>{
                setSubmit(false); 
                setAoaShow(false);  
                setAoaShow2(false);  
                successMsg('PROPERTY_UPDATE_SUCCESS')
                setAoaReady(dataConfirm === 'Y' ? true : false);
                setDataConfirm(null)
            },1000)
        })
        .catch((error) => {
            setSubmit(false);
            globalError()
        })
    }
    
    const onSubmitDocument = (file) => {  
        //toast.loading('Waiting for the action to complete...');
        //const toastId = toast.loading('Loading...');
        const toastId = toast.loading('Uploading files...', {
            duration: 4000000,
            position: 'top-center',
            // Styling
            style: {},
            className: '',
            // Custom Icon
           // icon: '👏',
            // Change colors of success/error/loading icon
            iconTheme: {
              primary: '#000',
              secondary: '#fff',
            },
            // Aria
            ariaProps: {
              role: 'status',
              'aria-live': 'polite',
            },
          });
        setSubmitDocument(true) 
        const data = new FormData();
        data.append('id', id);
        data.append('file', file.file);
        data.append('type', file.type);
        if(file.type === 1) {
            setSubmitGeneral(true)
        }

        if(file.type === 2) {
            setSubmitResult(true)
        }

        ApiServices.uploadDocument(data).then((result) => {  
            setSubmitGeneral(false)
            setSubmitResult(false)
            if(file.type === 1) {
                propertyDocuments(1);
            }
    
            if(file.type === 2) {
                propertyDocuments(2);
            }
           setTimeout(() =>{
            successMsg('PROPERTY_DOCUMENT_SUCCESS')
            setSubmitDocument(false) 
           },100)
           toast.remove(toastId);
        })
        .catch((error) => {
            toast.remove(toastId);
            setSubmitGeneral(false)
            setSubmitResult(false)
            setSubmitDocument(false) 
            globalError();
        })
    }

    const deleteDocument = () => {     
        setSubmit(true); 
        ApiServices.deleteDocument(dataConfirm.id).then((result) => { 
            setTimeout(() =>{
                successMsg('PROPERTY_DELETE_SUCCESS')
                setSubmit(false)
                setDocument(false)
                if(dataConfirm.type === 1){
                    const newData = [...generalDocoments];
                    const prevIndex = generalDocoments.findIndex(item => item.id === dataConfirm.id);
                    newData.splice(prevIndex, 1);
                    setgeneralDocoments(newData);
                }
                if(dataConfirm.type === 2){
                    const newData = [...resultDocoments];
                    const prevIndex = resultDocoments.findIndex(item => item.id === dataConfirm.id);
                    newData.splice(prevIndex, 1);
                    setResultDocoments(newData);
                }
            },1000)
        })
        .catch((error) => {
            setSubmit(false); 
            globalError();
        })
    }

    const onSubmitNotes = (form) =>{ 
        setIsNotes(true)
        setResetNotes(false)
        form.id = id
        form.type = 2
        form.user_id=  details?.id
        form.description = details?.property_address
        ApiServices.saveNotesProperty(form).then((result) => { 
            setTimeout(() => {
                const newData = [...notes];
                newData.unshift(result?.data?.notes[0]);
                setNotes(newData);
                setIsNotes(false)
                setResetNotes(true)
                successMsg('NOTES_SUCCESS');
            },1000)
        })
        .catch((error) => {
            setIsNotes(false)
            formError()
        })
    }

    const onStatus  = (data) => {
        ApiServices.updateStatusDocument({ id: data.id, status: data.status  }).then((result) => {   
            if(data.type === 1){
                const newData = [...generalDocoments]
                const prevIndex = generalDocoments.findIndex(item => item.id === data.id)
                newData[prevIndex].status = data.status;
                setgeneralDocoments(newData)
            }

           if(data.type === 2){
            const newData = [...resultDocoments]
            const prevIndex = resultDocoments.findIndex(item => item.id === data.id)
            newData[prevIndex].status = data.status;
            setResultDocoments(newData)
       }
        })
        .catch((error) => {
            globalError();
        })
       
    }

    const handleChangeStatus = () => {
        setHearingStatus(tempHearingStatus)
        setTempHearingStatus('');
        setShowHearing(false)
    }

    const handleOnChangeStatusField = (e) => {
        setTempHearingStatus(e.target.value);
    }

    const hideAOAModal = () => {
        setAoaShow(false);
    }

    const hidePropertyModal = () => {
        setShow(false);
    }

    const handleAOAReady = () => {
        setAoaReady(true);
    }

    const handleDeleteProperty = (value) => {
        setDataConfirm(value);
        if(value === 'A'){
            setShow(true);
        }else{
            setShow2(true);
        }
    }

    const readyAoaConfirm = (value) => { 
        setDataConfirm(value);
        if(value === 'Y'){ 
            setAoaShow(true);
        }else{
            setAoaShow2(true);
        }
    }

    const handleEditProperties = () => {
        history.push({pathname: `/property/edit/${id}`})
    }

    const onDelete = (id) => {
        setDataConfirm(id);
        setDocument(true)
    }

    const countyDetails  = (county) => {
        let county_format = county?.replace(/\s+/g, '')?.toLowerCase()
        let obj = COUNTY_SITES.find(o => o.county?.replace(/\s+/g, '').toLowerCase() ===  county_format);
        if(obj){
            window.open(obj.site)
        }
    }

    const deleteProperties = () => { 
        ApiServices.deleteProperties({ id: id, owner_name: details?.owner_name, property_address: details?.property_address }).then((result) => {   
            setDeleteProperty(false)
            successMsgCustom("Request successfully Submitted!")
        })
        .catch((error) => {
            globalError();
        })
    }


    const handleDateChangeTrigger = (value) => {
       
        handleDateChange(value)

        ApiServices.updateHearingDateProperty({ id: id, hearing_date: moment(value).format('yyyy-MM-DD') }).then((result) => {
            successMsgCustom("Hearing Date successfully updated!")

            propertyDetails()
            setHearingStatus("Your hearing date is scheduled for " + result.message + ". If you have any questions, email us at info@republicpropertytax.com");
        })
        .catch((error) => {
            globalError();
        })
    }

    const clear_hearing_date = () => {
        handleDateChange(null);

        ApiServices.updateHearingDateProperty({ id: id, hearing_date: null}).then((result) => {
            successMsgCustom("Hearing Date successfully updated!")

            propertyDetails()
            setHearingStatus("");
        })
        .catch((error) => {
            globalError();
        })        
    }

    const handleDateChangeHearing = (date) => {
        handleDateChange(date)
        ApiServices.updateHearingDate({ id: id, date: moment(date).format('yyyy-MM-DD') }).then((result) => {   
           //console.log(result.data)
        })
        .catch((error) => {
            globalError();
        })
    }

    const handleDateChangeAOA = (date) => {
        handleDateChange2(date)
        
        ApiServices.updateAOASentDate({ id: id, date: moment(date).format('yyyy-MM-DD') }).then((result) => {   
            //setHearingStatus("Your hearing is complete and the results are below. If you have any questions, email us at info@republicpropertytax.com ");
        })
        .catch((error) => {
            globalError();
        })
        
    }

    const delete_date = () =>{
       // setSubmit(true); 
        let params = {
            id: parseInt(id),
        }
        ApiServices.removeAOADate(params).then((result) => {  
            handleDateChange2(null);
            //setHearingStatus("Your hearing date is scheduled for (date that is selected). If you have any questions, email us at info@republicpropertytax.com");
        })
        .catch((error) => {
            setSubmit(false); 
            globalError()
        })
    }

    const deleteNotes  = (id) => {
        const newData = [...notes]
        const prevIndex = notes.findIndex(item => item.id === id)
        if(prevIndex !==-1){
            newData.splice(prevIndex, 1);
        }
        setNotes(newData);
        ApiServices.deleteClientNotes({id: id}).then((result) => {  
            setTimeout(() =>{
               //successMsgCustom('Notes successfully deleted');
               
           },1000)
        })
        .catch((error) => {
            setSubmit(false)
            globalError()
        })
    }

    const editNotes = (details) => {
        setEditNotesDetails(details)
        setShowEdit(true);
    }

    const submitEditedNotes = (details) => {
        setSubmitNotes(true);
        
        ApiServices.editClientNotes({id: editNotesDetails?.id, notes: details?.notes}).then((result) => {  
            setTimeout(() =>{
                setSubmitNotes(false)
               successMsgCustom('Notes successfully updated');
               setShowEdit(false);
               setEditNotesDetails(null);
               const newData = [...notes]
               const prevIndex = notes.findIndex(item => item.id === editNotesDetails?.id)
               if(prevIndex !==-1){
                   newData[prevIndex].notes = details?.notes
               }
               setNotes(newData);
           },1000)
          
        })
        .catch((error) => {
            setSubmitNotes(false)
            globalError()
        })
    }

  
    return (
        <> 
        <ModalAoaAdress 
            submit={submit}
            aoaAdressList={aoaAdressList}
            title="Make Property AOA Ready" 
            description="Are you sure you want to make this property ready for AOA Ready?"
            btnText="Make AOA Ready"
            open={aoaShow}
            Onsubmit={(value)=> readyAoaSubmit(value)}
            close={() => setAoaShow(false)}
        />

        <ModalConfirm 
           submit={submit}
            title="Set Property's AOA Ready to False?" 
            description="Are you sure you want to set this property's AOA Ready to false?"
            btnText="AOA Ready to False"
            open={aoaShow2}
            Onsubmit={()=> readyAoa()}
            close={() => setAoaShow2(false)}
        />

       <ModalConfirm 
            submit={submit}
            title="Activate Property" 
            description="Are you sure you want to activate this Property?"
            btnText="Activate Property"
            open={show}
            Onsubmit={()=> updateStatus()}
            close={() => setShow(false)}
        />

        <ModalConfirm 
            submit={submit}
            title="Deactivate Property" 
            description="Are you sure you want to deactivate this Property?"
            btnText="Deactivate Property"
            open={show2}
            Onsubmit={()=> updateStatus()}
            close={() => setShow2(false)}
        />

        <ModalConfirm 
            submit={submit}
            title="Delete Property" 
            description="Are you sure you want to delete this Property?"
            btnText="Delete Property"
            open={deleteProperty}
            Onsubmit={()=> deleteProperties()}
            close={() => setDeleteProperty(false)}
        />
        

        <ModalConfirm 
            submit={submit}
            title="Are you sure?" 
            description="After unhold, it will be automatically deleted after the expiration date"
            btnText="Delete File"
            open={docoment}
            Onsubmit={()=> deleteDocument()}
            close={() => setDocument(false)}
        />

        <ModalEditNotes open={showEdit} isSubmit={isSubmitNotes} values={editNotesDetails}  onSubmit={submitEditedNotes}  close={()=> {setShowEdit(false); setEditNotesDetails(null);}}/>

        <Container>
            <TitleBreadCrumbs title="Property Details" >
                <div className="page-breadcrumbs">
                    <Link to="/dashboard">Dashboard</Link>
                { userData.userlevel === USERLEVELS.ADMIN &&
                    <Link to={`/properties`}>Properties</Link>
                }
                    <span className="current_breadcrumbs">Property details</span>
                </div>
                { userData.userlevel === USERLEVELS.ADMIN && 
                <div className="mb-4">
                    <Link className="backto-link" to={`/properties`}><AngleArrowLeft color="#FFA647" />Back to all properties</Link>
                </div>
                }
            </TitleBreadCrumbs>
            <CardBody>
                <Row>
                   <Col md={7}>
                        <span className={`${active ? 'is-active' : 'not-active'}`}>
                            { active ? 'Active' : 'Inactive' }
                        </span>
                        <h3 className="detail-page-heading"><b>{details?.property_address}, {details?.property_city}, {details?.property_state} {details?.property_zip}</b></h3>
                        { userData.userlevel !== USERLEVELS.CLIENT && (
                        <span className="property-county-detail">County: <i> <span onClick={()=> countyDetails(details?.county_name)} className="cursor-pointer">{details?.county_name}</span></i></span>
                        )}
                         { userData.userlevel === USERLEVELS.CLIENT && (
                        <span className="property-county-detail">County: <i> <span>{details?.county_name}</span></i></span>
                        )}
                   </Col>
                   <Col md={5}>
                       <div className="client-details-right text-right">
                        { userData.userlevel === USERLEVELS.ADMIN && <>
                            {aoaReady && 
                            <Button className="btn-success" onClick={() => readyAoaConfirm('N')}><AccountSettingsIcon /> Disable AOA</Button>
                            }
                            {!aoaReady && 
                            <Button className="btn-info" onClick={() => readyAoaConfirm('Y')}><AccountSettingsIcon /> Enable AOA</Button>
                            }
                        </> }   
                        {/* { userData.userlevel === USERLEVELS.CLIENT && <>  
                            <Button onClick={() => setDeleteProperty(true) }><StopIcon /> Delete Property</Button>
                        </> }   */}
                         { userData.userlevel === USERLEVELS.ADMIN && (
                            <Button onClick={() => handleEditProperties() }><PencilIcon color="#FFA647" /> Edit</Button>
                         )}

                        { userData.userlevel === USERLEVELS.ADMIN && <>  
                            { active ?
                                  <Button onClick={() => handleDeleteProperty('I') }><StopIcon color="#FFA647" /> Deactivate</Button>
                                :
                                <Button onClick={() => handleDeleteProperty('A') }><AccountSettingsIcon /> Activate</Button>
                            }
                        </> }  
                        </div>
                   </Col>
                </Row>
                <Row>
                    {userData.userlevel === USERLEVELS.ADMIN  &&
                    <Col md={4}>
                        <Card className="pb-0">
                            <CardBody className="client-details-body">
                                <div className="card-header">
                                    <p>Client Info</p>
                                </div>
                                <div className="card-content">
                                    <p className="card-detail-label">Full Name:</p>
                                    <p>{details?.client_first_name} {details?.client_last_name}</p>
                                    <p className="card-detail-label">Address:</p>
                                    <p>{details?.homeaddress}, {details?.city}, {details?.homecity} {details?.homestate} {details?.homezip}</p>
                                    <p className="card-detail-label">Email:</p>
                                    <p>{details?.email}</p>
                                    <p className="card-detail-label">Phone(1):</p>
                                    <p>{details?.homephone1}</p>
                                    <p className="card-detail-label">Phone(2):</p>
                                    <p>Phone(2): {details?.homephone2}</p>
                                    <br />
                                    <Link to={`/client/details/${details?.id}`} className="btn btn-secondary mb-0">Client Details</Link>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                    }
                    <Col md={`${ userData.userlevel !== USERLEVELS.ADMIN  ? 6 : 4 }`}>
                        <Card className="pb-0">
                            <CardBody className="client-details-body">
                                <div className="card-header">
                                    <p>Property Info</p>
                                </div>
                                <div className="card-content">
                                    <p className="card-detail-label">Owner name:</p>
                                    <p>{details?.owner_name}</p>
                                    <p className="card-detail-label">Account No.:</p>
                                    <p>{details?.account_number ? details?.account_number : 'N/A'}</p>
                                    <p className="card-detail-label">Address:</p>
                                    <p>{details?.property_address}</p>
                                    <p className="card-detail-label">City, State ZIP:</p>
                                    <p>{details?.property_city}, {details?.property_state} {details?.property_zip}</p>
                                    <p className="card-detail-label">Property Type:</p>
                                    <p>{details?.property_type} ({details?.property_value})</p>
                                    <p className="card-detail-label">Exemption Type:</p>
                                    <p>{details?.exemption_title || 'N/A'} </p>
                                  
                                    <p className="card-detail-label">Property Added:</p>
                                    <p>{moment(details?.prop_created_date).format('MMM DD, YYYY hh:mm A')}</p>

                                    {
                                        (details?.pstatus == 'I' && details?.deleted_date) &&
                                        <div>
                                            <p className="card-detail-label">Property Deactivated:</p>
                                            <p>{moment(details?.deleted_date).format('MMM DD, YYYY hh:mm A')}</p>    
                                        </div>                                     
                                    }

                                    <br />
                                    {/* <Link to={`/property/description/${id}`} className="btn btn-secondary mb-0">Property Description</Link> */}
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col md={`${ userData.userlevel !== USERLEVELS.ADMIN  ? 6 : 4 }`}>
                        <Card className="pb-0">
                            <CardBody className="client-details-body">
                                <div className="card-header">
                                    <p>Client Property Notes</p>
                                </div>
                                <div className="card-content">
                                   
                                   <PropertyDescription id={id} />
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col md={6} className="mt-3">
                        <div className="property-status-wrapper">
                            <span>Property status:</span>
                            <div className="">
                                <p>{hearingStatus}</p>
                            </div>
                        </div>
                    </Col>
                    <Col md={6} className="mt-3 hearing-aoa-dates">
                        <div className="property-hearingwrapper">
                            <span>Hearing Date </span>
                            <div class="hearing-date-out-cont">
                                { userData.userlevel === USERLEVELS.ADMIN &&
                                    <i class="fa fa-times" aria-hidden="true" onClick={() => clear_hearing_date()}></i>
                                }
                                <div className="hearing-status-datetimepicker">
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <div className={`custom-datepicker ${ userData.userlevel === USERLEVELS.CLIENT ? 'readonlydatepicker' : '' }`}>
                                            <DatePicker format="MM/dd/yyyy" value={selectedDate} onChange={(value) => {handleDateChangeTrigger(value); }} readOnly={ userData.userlevel !== USERLEVELS.CLIENT ? false : true }/>
                                            <span><CalendarIcon /></span>
                                        </div>
                                    </MuiPickersUtilsProvider>
                                </div>
                            </div>
                        </div>

                        {userData.userlevel !== USERLEVELS.CLIENT && (
                            <div className="property-hearingwrapper">
                                
                                <span>AOA Sent Date </span>
                                <div className="hearing-status-datetimepicker">
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <div className={`custom-datepicker ${ userData.userlevel === USERLEVELS.CLIENT ? 'readonlydatepicker' : '' }`}>
                                            <DatePicker format="yyyy-MM-dd" value={selectedDate2} onChange={handleDateChangeAOA} readOnly={ userData.userlevel !== USERLEVELS.CLIENT ? false : true } />
                                            <span><CalendarIcon /></span>
                                        </div>
                                    </MuiPickersUtilsProvider>
                                </div>
                                {selectedDate2 && (
                                    <Button  title="Delete Date" onClick={() => delete_date()} className="delete-aoa">
                                        <CloseIcon color="#FFA647" />
                                    </Button> 
                                )}
                                
                            </div>
                        )}
                    </Col>
                </Row>
            </CardBody>
            <CardBody className="cardbody-controlled mb-3 mt-4">
                <Tabs activeKey={activeTab} onSelect={(k) => setActiveTab(k)}>
                    { userData.userlevel !== USERLEVELS.CLIENT && (
                    <Tab eventKey="propertyBilling" title={<>Property Billing {countBilling !== 0 && <span className={`tab-counter tab-length-${countBilling?.toString().length}`}>{`${countBilling}`}</span> }</>}>
                        <PropertyBilling id={id} isTotal={(value)=> setCountBilling(value)} firstData={(data) =>  setFirstDAta(data)}   />
                    </Tab> 
                    )}
                    { userData.userlevel == USERLEVELS.CLIENT && (
                    <Tab eventKey="propertyBilling" title={<>Property Billing {countBilling !== 0 && <span className={`tab-counter tab-length-${countBilling?.toString().length}`}>{`${countBilling}`}</span> }</>}>
                        <PropertyBillingClient id={id} isTotal={(value)=> setCountBilling(value)} />
                    </Tab> 
                    )}
                     { userData.userlevel !== USERLEVELS.CLIENT && (
                    <Tab eventKey="notes" title={<>Notes {notes?.length !== 0 && <span className={`tab-counter tab-length-${notes?.length?.toString().length}`}>{`${notes?.length}`}</span> }</>}>
                        <PropertyNotes isSubmit={isNotes} resetNotes={resetNotes} id={id} notes={notes} onSubmit={(form)=> onSubmitNotes(form)} deleteNotes={(id)=> deleteNotes(id)} editNotes={(id)=> editNotes(id)}  />
                    </Tab>
                     )}
                      { userData.userlevel !== USERLEVELS.CLIENT && (
                    <Tab eventKey="activities" title={<>Activities {activities?.length !== 0 && <span className={`tab-counter tab-length-${activities?.length?.toString().length}`}>{activities?.length} </span> }</>}>
                        <ActivitiesList activityData={activities} clientData={details} />
                    </Tab>
                     )}
                    { userData.userlevel !== USERLEVELS.CLIENT && (
                    <Tab eventKey="arbitration" title={<>Arbitration</>}>
                        <ArbitrationForm id={id} firstData={firstData} arbitration={arbitration} />
                    </Tab>
                     )}
                    <Tab eventKey="document" title="Documents">
                        <PropertyDocument aoaReady={aoaReady} submitDocument={submitDocument} id={id} onSubmitDocument={(file) => onSubmitDocument(file)} generalDocoments={generalDocoments} resultDocoments={resultDocoments}   onDelete={(id)=> onDelete(id)}   onStatus={(data)=> onStatus(data)}  submit1={submitGeneral} submit2={submitResult} multipleUploads={multipleUploads} />
                    </Tab>
                </Tabs>
            </CardBody>
            
        </Container>
        </>
    );
}

export default ClientDetails;