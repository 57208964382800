/* eslint-disable */
import React, { useState } from 'react';
import SendEmail from '../Contact/send-email';
import ApiServices from "@/utils/ApiServices";
import { errorMsgCustom, successMsgCustom } from '../../utils/message_herlper';

const BatchEmail = () => {
  const [loading, setLoading] = useState(false);
  const [type, setType] = useState('live');
  const onSubmit = (data) => {
    setLoading(true);
    data.type = type;
    ApiServices.sendBatchEmail(data)
      .then(response => {
        console.log(response.data);
        successMsgCustom("Email Sent.")
      })
      .catch(err => {
        console.log(err);
        errorMsgCustom(err.response?.data?.message || err.message || "Something went wrong");
      })
      .finally(() => {
        setLoading(false);
      });
  };

    return (
        <SendEmail onSubmit={onSubmit} loading={loading} setType={setType}/>
    )
}
export default BatchEmail;