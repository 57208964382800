/* eslint-disable */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect, Provider } from 'react-redux';
import { Toaster } from "react-hot-toast";
import { BrowserRouter } from 'react-router-dom';
import { I18nextProvider } from 'react-i18next';
import { CookiesProvider } from 'react-cookie';
import i18n from 'i18next';
import 'bootstrap/dist/css/bootstrap.css';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import '../../scss/app.scss';
import '../../scss/global.scss';
import Router from './Router';
import store from './store';
import ScrollToTop from './ScrollToTop';
import { config as i18nextConfig } from '../../translations';

i18n.init(i18nextConfig);

const ThemeComponent = ({ children, themeName }) => {
  const theme = createTheme({
    palette: {
      type: themeName === 'theme-dark' ? 'dark' : 'light',
    },
  });

  return (
    <ThemeProvider theme={theme}>
      {children}
    </ThemeProvider>
  );
};

ThemeComponent.propTypes = {
  children: PropTypes.shape().isRequired,
  themeName: PropTypes.string.isRequired,
};

const ConnectedThemeComponent = connect(state => ({ themeName: state.theme.className }))(ThemeComponent);

const App = () => {
  return (//duration: 5000, 3000
    <Provider store={store}>
      <Toaster position = "top-center"
            reverseOrder = {
                false
            }
            gutter = {
                8
            }
            containerClassName = ""
            containerStyle = {
                {}
            }
            toastOptions = {
                {
                    className: "",
                    duration: 5000,
                }
            } 
        />
      <BrowserRouter>
        {/* <I18nextProvider i18n={i18n}> */}
            <ScrollToTop>
              <ConnectedThemeComponent>
                <CookiesProvider>
                   <Router />
                </CookiesProvider>
              </ConnectedThemeComponent>
            </ScrollToTop>
        {/* </I18nextProvider> */}
      </BrowserRouter>
    </Provider>
  );
};

export default App;
