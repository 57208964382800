/* eslint-disable */
import React, { useState } from 'react';
import { 
    Card, CardBody,  Container,
  } from 'reactstrap';
import { Link } from 'react-router-dom';
import ExemptionTable from './components/ExemptionTable'
import ChangePassword from '@/components/ChangePassword';
import ApiServices from "@/utils/ApiServices";
import { successMsg, globalError } from "../../utils/message_herlper"
import TitleBreadCrumbs from '@/components/TitleBreadCrumbs'
import './county-style.css';

const ExemptionList = () => {

    const [isSubmit, setSubmit] = useState(false);
    const [id, setID] = useState('');
    const [changePass, setChangePass] = useState(false);

    const openChangePass  = (id) => {
        setID(id)
        setChangePass(true)
    }

    const onChangePassword  = (form) => {
        setSubmit(true)
        form.id = id
        ApiServices.updatePassword(form).then((result) => {  
           setTimeout(() =>{
               setChangePass(false)
               setSubmit(false)
               successMsg('PASSWORD_SUCCESS');
           },1000)
        })
        .catch((error) => {
            setSubmit(false)
            globalError()
        })
    }

    return (
        <>
        <ChangePassword isSubmit={isSubmit}   open={changePass}  onSubmit={(form) => onChangePassword(form)}  close={() => setChangePass(false)}  />
        <Container>
            <TitleBreadCrumbs title="List of  Exemption Type"  link="/exemption-form/create" btn="Create Exemption" >
                <div className="page-breadcrumbs">
                    <Link to="/">Dashboard</Link>
                    <Link to="/settings">Settings</Link>
                    <span className="current_breadcrumbs">
                        Exemption Type List
                    </span>
                </div>
            </TitleBreadCrumbs>
            <Card class="card-controlled">
                <CardBody class="cardbody-controlled">
                    <div className="">
                        <ExemptionTable  />
                    </div>
                </CardBody>
            </Card>
        </Container>
        </>
    );
}

export default ExemptionList;