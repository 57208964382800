/* eslint-disable */
import React, { useMemo, useState, useEffect } from 'react';
import { Col, Row, Table, Button, Spinner } from 'reactstrap';
import { successMsg, globalError, formError, successMsgCustom } from "@/utils/message_herlper"
import ApiServices from "@/utils/ApiServices"
import _ from 'lodash';

const ProfileRequestChanges = ({ clientData, requestChangesData }) => {
    const [isSubmit, setIsSubmit] = useState(false)
    const [approved, setApproved] = useState(false)
    const [rejected, setRejected] = useState(false)
    const [clientDatas, setClientData] = useState([])

    const AcceptChanges = () => {
        setIsSubmit(true)

        ApiServices.approveProfileChanges(requestChangesData)
        .then((result) => {
            setIsSubmit(false)
            successMsg('PROFILE_SUCCESS');
            setApproved(true)
        })
        .catch((error) => {
            setIsSubmit(false)
            globalError()
        });
    }
    const rejectChanges = () =>{
        ApiServices.rejectProfileChanges({id: requestChangesData?.id})
        .then((result) => { 
            successMsgCustom("Request successfully rejected!")
            setIsSubmit(false)
            setRejected(true)
        })
        .catch((error) => {
            setIsSubmit(false)
            globalError()
        });
    }
    const clientDetails = (id) => {  
        ApiServices.clientsDetails(id).then((result) => {    
            console.log(result?.data);
            setClientData(result?.data);
        })
        .catch((error) => {
            formError();
        })
    }

    useEffect(() => {
        if( requestChangesData.status === 1 ){
            setApproved(true)
        }
        if( requestChangesData.status === 2 ){
            setRejected(true)
        }

        if( !_.isObject(clientData) ){
           clientDetails(clientData)
        }else{
            setClientData(clientData)
        }

    }, [clientData])

    return (
        <div className="activity-changes-wrapper mt-3">
        {/* <p>Request the following <span className="highlight">profile</span> changes:</p> */}
        <p>Client {clientDatas.client_first_name} {clientDatas.client_last_name}  <span className="highlight">requested to change profile</span >:</p>
        { clientDatas.client_first_name !== requestChangesData.first_name &&
        <p><span>First Name </span> from <span>{clientDatas.client_first_name}</span> to <span>{requestChangesData.first_name}</span></p>
        }
        { clientDatas.client_last_name !== requestChangesData.last_name &&
        <p><span>Last Name</span> from <span>{clientDatas.client_last_name}</span> to <span>{requestChangesData.last_name}</span></p>
        }
        { clientDatas.company_name !== requestChangesData.company_name &&
        <p><span>Company Name</span> from <span>{clientDatas.company_name}</span> to <span>{requestChangesData.company_name}</span></p>
        }
        {/* { clientDatas.email !== requestChangesData.email &&
        <p><span>Email</span> from <span>{clientDatas.email}</span> to <span>{requestChangesData.email}</span></p>
        } */}
        { clientDatas.username !== requestChangesData.username &&
        <p><span>Username</span> from <span>{clientDatas.username}</span> to <span>{requestChangesData.username}</span></p>
        }
        { clientDatas.homeaddress !== requestChangesData.homeaddress &&
        <p><span>Home Address</span> from <span>{clientDatas.homeaddress}</span> to <span>{requestChangesData.homeaddress}</span></p>
        }
        { clientDatas.homecity !== requestChangesData.homecity &&
        <p><span>Home City</span> from <span>{clientDatas.homecity}</span> to <span>{requestChangesData.homecity}</span></p>
        }
        { clientDatas.homestate !== requestChangesData.homestate &&
        <p><span>Home State</span> from <span>{clientDatas.homestate}</span> to <span>{requestChangesData.homestate}</span></p>
        }
        { clientDatas.homezip !== requestChangesData.homezip &&
        <p><span>Home Zip</span> from <span>{clientDatas.homezip}</span> to <span>{requestChangesData.homezip}</span></p>
        }
        { clientDatas.homephone1 !== requestChangesData.homephone1 &&
        <p><span>Home Phone 1</span> from <span>{clientDatas.homephone1}</span> to <span>{requestChangesData.homephone1}</span></p>
        }
        { clientDatas.homephone2 !== requestChangesData.homephone2 &&
        <p><span>Home Phone 2</span> from <span>{clientDatas.homephone2}</span> to <span>{requestChangesData.homephone2}</span></p>
        }
        { clientDatas.homecounty !== requestChangesData.county_id &&
        <p><span>County</span> from <span>{clientDatas.homecounty}</span> to <span>{requestChangesData.county_id}</span></p>
        }
        <Row>
            <Col md={12} className="pt-3 text-left action-btn-wrapper ">
                {!approved && !rejected ? <>
                <Button color="primary" onClick={AcceptChanges} disabled={isSubmit}>{isSubmit ? <Spinner size="sm" color="secondary" className="mr-2" /> : ''} Approve</Button>
                <Button onClick={rejectChanges} color="secondary" className="ml-1">Reject</Button>
                </> :''}
                { approved && 
                <Button color="primary" disabled >Approved</Button>
                }
                { rejected && 
                <Button color="primary" disabled >Rejected</Button>
                }
                
            </Col>
        </Row>
        </div>
    )
}

export default ProfileRequestChanges;