/* eslint-disable */
import React, { useState } from 'react';
import { useFormik } from 'formik';
import { 
    Card, CardBody, Col, Container, Row, Progress, ListGroup, ListGroupItem,
  } from 'reactstrap';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import './profile.css';

const Profile = () => {
  const [editFields, setEditFields] = useState(false);

  const CountyList = ["Harris", "Fort bend", "Brazoria", "Galveston", "Montgomery", "Travis", "Waller", "Austin", "Hays", "Williamson", "Guadalupe", "Fayette", "Calhoun", "Bexar", "Rockwall", "Chambers", "Polk", "Walker", "Liberty", "Matagorda", "Grimes", "Erath", "Wharton", "Tarrant", "Brazos", "Comal", "Ector", "Collin", "Victoria county", "Hidalgo", "Wise", "Burleson", "Bell", "Dallas", "San jacinto", "Aransas", "Nueces", "Jackson", "Washington"];
  const StateList = ["Alabama", "Alaska", "Arizona", "Arkansas", "California", "Colorado", "Connecticut", "Delaware", "District of Columbia", "Florida", "Georgia", "Hawaii", "Idaho", "Illinois", "Indiana", "Iowa", "Kansas", "Kentucky", "Louisiana", "Maine", "Maryland", "Massachusetts", "Michigan", "Minnesota", "Mississippi", "Missouri", "Montana", "Nebraska", "Nevada", "New Hampshire", "New Jersey", "New Mexico", "New York", "North Carolina", "North Dakota", "Ohio", "Oklahoma", "Oregon", "Pennsylvania", "Rhode Island", "South Carolina", "South Dakota", "Tennessee", "Texas", "Utah", "Vermont", "Virginia", "Washington", "West Virginia", "Wisconsin", "Wyoming"];

  const formik = useFormik({
    initialValues: {
      email: '',
      firstname: '',
      lastname: '',
      phone: '',
      phone2: '',
      address: '',
      county: '',
      state: '',
      city: '',
      zip: '',
    },
    onSubmit: async (values, { setErrors }) => {
      if( !alert('Success') ){
        setEditFields(false);
      }
    }
  })
 
  return (
    <Container className="profile-container">
      <Row>
        <Col md={12}>
          <h2>Account Information</h2>
          {(
            !editFields 
            && (
            <div className="static-fields">
              <Row>
                <Col md={6}>
                  <div>Your Name: <b>John Doe</b></div>
                  <div>Home Phone (Main): <b>(508) 723-2547</b></div>
                  <div>Secondary Phone: <b /></div>
                  <div>Email: <b>johndoe@gmail.com</b></div>
                </Col>
                <Col md={6}>
                  <div>Home Address: <b>55 Sw 9th St, Apt 2906</b></div>
                  <div>Country: <b>Harris</b></div>
                  <div>Zip: <b>33130</b></div>
                  <div>City: <b>Miami</b></div>
                  <div>State: <b>FL</b></div>
                </Col>
                <Col md={12}>
                  <Button onClick={() => setEditFields(true)}>Edit</Button>
                </Col>
              </Row> 
            </div>
            )
          )}
          {(
            editFields 
            && (
            <form onSubmit={formik.handleSubmit} className="editable-fields">
              <Row>
                <Col md={6}>
                  <Row>
                    <Col md={6}>
                      <Form.Label>First Name</Form.Label>
                      <Form.Control type="text" />
                    </Col>
                    <Col md={6}>
                      <Form.Label>Last Name</Form.Label>
                      <Form.Control type="text" />
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6}>
                      <Form.Label>Company Name</Form.Label>
                      <Form.Control type="text" />
                    </Col>
                    <Col md={6}>
                      <Form.Label>Email Address</Form.Label>
                      <Form.Control type="email" />
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6}>
                      <Form.Label>Phone</Form.Label>
                      <Form.Control type="number" />
                    </Col>
                    <Col md={6}>
                      <Form.Label>Phone2</Form.Label>
                      <Form.Control type="number" />
                    </Col>
                  </Row>
                </Col>
                <Col md={6}>
                  <Row>
                    <Col md={6}>
                      <Form.Label>Address</Form.Label>
                      <Form.Control type="text" />
                    </Col>
                    <Col md={6}>
                      <Form.Label>City</Form.Label>
                      <Form.Control type="text" />
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6}>
                      <Form.Label>County</Form.Label>
                      <select name="county" className="form-control">
                        {CountyList.map(County => <option value={County} index={County}>{County}</option> )}
                      </select>
                    </Col>
                    <Col md={6}>
                      <Form.Label>State</Form.Label>
                      <select name="county" className="form-control">
                        {StateList.map(State => <option value={State} index={State}>{State}</option> )}
                      </select>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6}>
                      <Form.Label>Zip</Form.Label>
                      <Form.Control type="number" />
                    </Col>
                    <Col />
                  </Row>
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                  <div className="account-form-btns">
                    <Button onClick={() => setEditFields(false)}>Cancel</Button>
                    <Button type="submit">Save</Button>
                  </div>
                </Col>
              </Row>
            </form>
            )
          )}
        </Col>
      </Row>
    </Container>
  );
}

export default Profile;
